@import "../../index.scss";

.statement_card_container {
    .statement_card_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        .statement_card_title_wrapper {
            .statement_card_title {
                font-family: "Merriweather";
                font-weight: 400;
            }
            .statement_card_line_wrapper {
                margin-top: 0.5em;
                height: 2px;
                width: 50%;
                background: $primaryColor;
            }
        }
        .statement_card_content_wrapper{
            padding-top: 1em;
            .statement_card_heading{
                width: 500px;
            }
            .statement_card_description{
                padding-top: 1em;
                line-height: 22px;
                font-family: 'Open Sans', sans-serif;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }
            .statement_card_pdf_file_wrapper{
                padding-top: 2em;
                .statement_card_pdf_file{
                    background: $secondaryLightColor;
                }
            }
        }
    }
}
@media  screen and (max-width: 600px) {
    .statement_card_container .statement_card_wrapper .statement_card_title_wrapper .statement_card_title {
        font-family: "Merriweather";
        font-weight: 400;
        font-size: 15px;
    }
    .statement_card_container .statement_card_wrapper .statement_card_content_wrapper .statement_card_heading {
        width: auto; 
        font-size: 13px;
    }
}
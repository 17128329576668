@import "../../../index.scss";
.checklist_container {
    display: flex;
    flex-direction: column;

    .checklist_list_wrapper {
        display: flex;
        flex-direction: row;
        // justify-content: center;
        align-items: center;
        cursor: pointer;
        padding: 1em;

        .radiobutton_unchecked_wrapper {
            display: flex;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: $secondaryLightColor;
        }

        .radiobutton_checked_wrapper{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: $primaryColor;

            .checked_icon{
                width: 10px;
                height: 10px;
            }
        }

        .list_details{
            display: flex;
            font-weight: 500;
            justify-content: flex-start;
            align-items: center;
            margin-left: 1.25em;
            color: $primaryTextColor;
        }
    }
}

@import '../../index.scss';

.lectionary_screen {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $backgroundColor;
  overflow: auto;
  .lectionary_select_box_wrapper{
    display: flex;
    justify-content: flex-end;
    padding-bottom: 2em;
    .lectionary_select_box{
      width: 300px;
      .select_box{
        width: 250px;
        .css-z7fgrc-control{
          background-color: $backgroundColor !important;
        }
        .css-1smg336-control{
          background-color: $backgroundColor !important;
        }
      }
    }
  }
  .error_text{
    text-align: center;
    width: 100%;
  }
  .header_wrapper {
    width: 100%;
  }
  .footer_wrapper {
    width: 100%;
  }
  .lectionary_screen_container {
    width: 1170px;
    .lectionary_header_wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 2em 0;
      .lectionary_heading {
        font-family: 'Merriweather';
        font-weight: 400;
      }
      .lectionary_header_line {
        height: 3px;
        width: 170px;
        margin-top: 1em;
        background-color: $primaryColor;
      }
    }
    .lectionary_screen_title_wrapper {
      margin-top: 3em;
      margin-bottom: 3em;
      .lectionary_screen_title {
        display: flex;
        justify-content: center;
        .title_links_wrapper {
          display: flex;
          padding: 1rem;
          justify-content: center;
          position: relative;
          &:after {
            content: '';
            width: 90%;
            position: absolute;
            bottom: 0;
            left: 15px;
            height: 2px;
            border-bottom: 3px solid $secondaryLightColor;
          }
          .link_1 {
            padding-right: 2em;
            position: relative;
            font-weight: 400;
            .title_link {
              text-decoration: none;
              color: $subscribeButtonColor;
            }
          }
          .link {
            position: relative;
            font-weight: 400;
            padding-right: 2em;
            .title_link {
              text-decoration: none;
              color: $subscribeButtonColor;
            }
          }
        }
      }
    }
    .lectionary_body_wrapper{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start ;
        .lectionary_card_wrapper{
            width: 33%;
            padding: 2em 2em 0 2em;
        }
    }
  }
}
@media only screen and (max-width: 1024px){
  .screen .lectionary_screen_container {
    width: 100%;
}
.lectionary_screen .lectionary_screen_container .lectionary_body_wrapper .lectionary_card_wrapper {
  width: 50%;
  padding: 1em;
}
}
@media only screen and (max-width: 600px) {
  .lectionary_screen {
    .h3 {
      font-size: 9px;
      font-weight: 700;
  }
    .lectionary_select_box_wrapper{
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 2em;
      .lectionary_select_box{
        width: 50%;
        .select_box{
          width: 100%;
        }
      }
    }
    .header_wrapper {
      width: 100%;
    }
    .footer_wrapper {
      width: 100%;
    }
    .lectionary_screen_container {
      width: 87vw;
      .lectionary_header_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 2em 0;
        .lectionary_heading {
          font-family: 'Merriweather';
          font-weight: 400;
        }
        .lectionary_header_line {
          height: 3px;
          width: 170px;
          margin-top: 1em;
          background-color: $primaryColor;
        }
      }
      .lectionary_screen_title_wrapper {
        margin-top: 3em;
        margin-bottom: 3em;
        .lectionary_screen_title {
          display: flex;
          justify-content: center;
          .title_links_wrapper {
            display: flex;
            padding: 0rem;
            height: 30px;
            justify-content: center;
            position: relative;
            &:after {
              content: '';
              width: 95%;
              position: absolute;
              bottom: 0;
              left: 0px;
              height: 2px;
              border-bottom: 3px solid $secondaryLightColor;
            }
            .link_1 {
              padding-right: 2em;
              position: relative;
              font-weight: 400;
              font-size: 11px;
              .title_link {
                text-decoration: none;
                color: $subscribeButtonColor;
              }
            }
            .link {
              position: relative;
              font-weight: 400;
              padding-right: 2em;
              font-size: 11px;
              .title_link {
                text-decoration: none;
                color: $subscribeButtonColor;
              }
            }
          }
        }
      }
      .lectionary_body_wrapper{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-evenly;
          .lectionary_card_wrapper{
              width: 100%;
              padding-bottom: 2em;
          }
      }
    }
  }
}
@import '../../index.scss';

.advertisement_home_screen {
  height: 100%;
  overflow: auto;
  .advertisement_home_container {
    .advertisement_home_wrapper {
      .advertisement_home_body_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 5em;
        .advertisement_home_body {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          .advertisement_home_title_wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-top: 4em;
            .advertisement_home_title {
              font-weight: 400;
              font-family: 'Merriweather';
            }
            .advertisement_home_line_wrapper {
              margin-top: 0.5em;
              width: 170px;
              height: 2px;
              background: $primaryColor;
            }
          }
          .advertisement_home_content_title_wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            width: 95%;
            font-weight: 400;
            font-family: 'MerriWeather';
          }
          .advertisement_home_content_title_line_wrapper {
            width: 100px;
            height: 2px;
            background: $primaryColor;
          }
          .advertisement_home_content_title_gold_wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            width: 95%;
            margin-top: 1em;
            font-weight: 400;
            font-family: 'MerriWeather';
          }
          .advertisement_home_content_title_gold_line_wrapper {
            width: 50px;
            height: 2px;
            background: $primaryColor;
          }
          .advertisement_home_content_title_silver_wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            width: 95%;
            margin-top: 1em;
            font-weight: 400;
            font-family: 'MerriWeather';
          }
          .advertisement_home_content_title_silver_line_wrapper {
            width: 70px;
            height: 2px;
            background: $primaryColor;
          }
          .advertisement_home_content_wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            padding-top: 3em;
            .advertisement_home_content {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: 33%;
              padding-bottom: 5em;
              .advertisement_home_thumbnail_wrapper {
                .advertisement_home_thumbnail {
                  width: 379px;
                  height: 499px;
                }
              }
              .advertisement_home_thumbnail_title {
                font-weight: 500;
                margin-top: 1em;
              }
            }
          }
          .advertisement_home_content_gold_wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            padding-top: 3em;
            .advertisement_home_content_gold {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: 33%;
              padding-bottom: 5em;
              .advertisement_home_thumbnail_wrapper {
                .advertisement_home_thumbnail {
                  width: 379px;
                  height: 499px;
                }
              }
              .advertisement_home_thumbnail_title {
                font-weight: 500;
                margin-top: 1em;
              }
            }
          }
          .advertisement_home_content_silver_wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            padding-top: 3em;
            .advertisement_home_content_silver {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: 33%;
              padding-bottom: 5em;
              .advertisement_home_thumbnail_wrapper {
                .advertisement_home_thumbnail {
                  width: 379px;
                  height: 499px;
                }
              }
              .advertisement_home_thumbnail_title {
                font-weight: 500;
                margin-top: 1em;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1500px) {
    .advertisement_home_container {
      .advertisement_home_wrapper {
        .advertisement_home_body_wrapper {
          .advertisement_home_body {
            .advertisement_home_content_wrapper {
              .advertisement_home_content {
                .advertisement_home_image {
                  width: 300px;
                  height: 100px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .advertisement_home_screen
    .advertisement_home_container
    .advertisement_home_wrapper
    .advertisement_home_body_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 2em;
  }
  .advertisement_home_screen
    .advertisement_home_container
    .advertisement_home_wrapper
    .advertisement_home_body_wrapper
    .advertisement_home_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1em;
  }
  .advertisement_home_screen
    .advertisement_home_container
    .advertisement_home_wrapper
    .advertisement_home_body_wrapper
    .advertisement_home_body
    .advertisement_home_content_wrapper
    .advertisement_home_content
    .advertisement_home_thumbnail_wrapper
    .advertisement_home_thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .advertisement_home_screen
    .advertisement_home_container
    .advertisement_home_wrapper
    .advertisement_home_body_wrapper
    .advertisement_home_body
    .advertisement_home_content_wrapper
    .advertisement_home_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 33%;
    padding: 2em;
  }
  .advertisement_home_screen
    .advertisement_home_container
    .advertisement_home_wrapper
    .advertisement_home_body_wrapper
    .advertisement_home_body
    .advertisement_home_content_gold_wrapper
    .advertisement_home_content_gold {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 33%;
    padding: 2em;
  }
  .advertisement_home_screen
    .advertisement_home_container
    .advertisement_home_wrapper
    .advertisement_home_body_wrapper
    .advertisement_home_body
    .advertisement_home_content_gold_wrapper
    .advertisement_home_content_gold
    .advertisement_home_thumbnail_wrapper
    .advertisement_home_thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .advertisement_home_screen
    .advertisement_home_container
    .advertisement_home_wrapper
    .advertisement_home_body_wrapper
    .advertisement_home_body
    .advertisement_home_content_silver_wrapper
    .advertisement_home_content_silver {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 33%;
    padding: 2em;
  }
  .advertisement_home_screen
    .advertisement_home_container
    .advertisement_home_wrapper
    .advertisement_home_body_wrapper
    .advertisement_home_body
    .advertisement_home_content_silver_wrapper
    .advertisement_home_content_silver
    .advertisement_home_thumbnail_wrapper
    .advertisement_home_thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .advertisement_home_screen
    .advertisement_home_container
    .advertisement_home_wrapper
    .advertisement_home_body_wrapper
    .advertisement_home_body
    .advertisement_home_content_silver_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding-top: 1em;
  }
  .advertisement_home_screen
    .advertisement_home_container
    .advertisement_home_wrapper
    .advertisement_home_body_wrapper
    .advertisement_home_body
    .advertisement_home_content_gold_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding-top: 1em;
  }
  .advertisement_home_screen
    .advertisement_home_container
    .advertisement_home_wrapper
    .advertisement_home_body_wrapper
    .advertisement_home_body
    .advertisement_home_content_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding-top: 1em;
  }
}
@media screen and (max-width: 600px) {
  .advertisement_home_screen
    .advertisement_home_container
    .advertisement_home_wrapper
    .advertisement_home_body_wrapper
    .advertisement_home_body
    .advertisement_home_title_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2em;
  }
  .advertisement_home_screen
    .advertisement_home_container
    .advertisement_home_wrapper
    .advertisement_home_body_wrapper
    .advertisement_home_body
    .advertisement_home_content_wrapper
    .advertisement_home_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 1em;
  }
  .advertisement_home_screen
    .advertisement_home_container
    .advertisement_home_wrapper
    .advertisement_home_body_wrapper
    .advertisement_home_body
    .advertisement_home_content_gold_wrapper
    .advertisement_home_content_gold {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 1em;
  }
  .advertisement_home_screen
    .advertisement_home_container
    .advertisement_home_wrapper
    .advertisement_home_body_wrapper
    .advertisement_home_body
    .advertisement_home_content_silver_wrapper
    .advertisement_home_content_silver {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 1em;
  }
  .ad_modal_image_wrapper {
    width: 350px !important;
    justify-content: center;
    display: flex;
    height: 100%;
    width: 100% !important;
    position: relative;
    margin: 0 !important;
  }
  .ad_modal_image {
    display: flex;
    object-fit: cover;
    height: 426px !important;
    width: 100%;
    justify-content: center;
    width: 100%;
    background: #fff;
  }
}
.ad_modal_image {
  margin: 0 auto;
  display: flex;
  object-fit: cover;
  height: 500px;
  width: 500px;
  background: white;
}
.react-responsive-modal-overlay {
  background: rgba(0, 0, 0, 0.3) !important;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.swiperModal {
  background: rgba(0, 0, 0, 0);
  box-shadow: none;
  overflow-y: auto;
  max-width: 100%;
  width: 100%;
  height: 70%;
  margin: 0;
  padding: 0;
  z-index: 10000000000000000000;
}
.swiper_modal_close_icon {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 50px;
  height: 50px;
  z-index: 1;
  cursor: pointer;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(118deg)
    brightness(100%) contrast(105%);
}
.ad_modal_image_wrapper {
  width: 600px;
  height: 100%;
  position: relative;
  margin: 0 auto;
}

.close_button {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 25px;
  color: white;
  &:hover{
    cursor: pointer;
  }
}
.swiperModalRoot {
  width: 100%;
  z-index: 100000000000;
  .swiperModal {
    background: rgba(0, 0, 0, 0);
    box-shadow: none;
    overflow-y: auto;
    width: 100%;
    width: 100%;
    height: 70%;
    margin: 0;
    padding: 0;
    z-index: 10000000000000000000;

    .closeButton {
      display: none;
      position: absolute;
      right: 5px;
      z-index: 10000000000000000;
    }

    .swiper_modal_gallery_container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      position: relative;
      .swiper_modal_left_button {
        display: flex;
        align-items: center;
        position: absolute;
        left: 10px;
        cursor: pointer;
        z-index: 1;
        height: 10%;
        width: 30px;
        .left_button {
          width: 23px;
          height: 23px;
          transform: rotate(90deg);
          filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(118deg)
            brightness(100%) contrast(105%);
        }
      }
      .swiper_modal_right_button {
        display: flex;
        align-items: center;
        position: absolute;
        right: 10px;
        cursor: pointer;
        height: 10%;
        width: 30px;
        padding-left: 0.7em;
        .right_button {
          width: 23px;
          height: 23px;
          transform: rotate(-90deg);
          filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(118deg)
            brightness(100%) contrast(105%);
        }
      }
      .swiper_modal_image_wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 50%;
        height: 90%;
        padding-top: 1em;
        padding-bottom: 2em;

        .swiper_modal_information {
          display: flex;
          justify-content: flex-end;
          position: absolute;
          bottom: 0px;
          right: 0px;
          align-items: center;
          color: $background;
          z-index: 10000000000;
        }

        .swiper_banner {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .advertisement_button_wrapper {
        position: absolute;
        bottom: 20px;
      }
    }
  }
}
.close_button_wrapper {
  width: 50px;
  height: 50px;
  color: white;
  position: absolute;
}
.swiperModal::-webkit-scrollbar {
  display: none;
}

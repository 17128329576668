@import '../../index.scss';

.worship_screen {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  .header_wrapper {
    width: 100%;
  }
  .footer_wrapper {
    width: 100%;
  }
  .worship_screen_container {
    max-width: 1170px;
    .worship_header_wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 2em 0;
      .worship_heading {
        font-family: 'Merriweather';
        font-weight: 400;
      }
      .worship_header_line {
        height: 3px;
        width: 170px;
        margin-top: 1em;
        background-color: $primaryColor;
      }
    }
    .worship_screen_title_wrapper {
      margin-top: 3em;
      margin-bottom: 3em;
      .worship_screen_title {
        display: flex;
        justify-content: center;
        .title_links_wrapper {
          display: flex;
          padding: 1rem;
          justify-content: center;
          position: relative;
          &:after {
            content: '';
            width: 90%;
            position: absolute;
            bottom: 0;
            left: 15px;
            height: 2px;
            border-bottom: 3px solid $secondaryLightColor;
          }
          .link_1 {
            padding-right: 2em;
            position: relative;
            font-weight: 400;
            .title_link {
              text-decoration: none;
              color: $subscribeButtonColor;
            }
          }
          .link {
            position: relative;
            font-weight: 400;
            padding-right: 2em;
            .title_link {
              text-decoration: none;
              color: $subscribeButtonColor;
            }
          }
        }
      }
    }
  }
  .eventimage {
    width: 100px;
  }
  .calendarHeader {
    border-bottom: 1px solid #e9e9e9;
    margin-bottom: 0.5em;
  }
}
.currentDay {
  border-radius: 30% !important;
  background-color: $primaryColor !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 50px;
}
.dayText {
  height: 30px !important;
  width: 30px !important;
}
.modeButtonActive {
  color: $primaryColor !important;
}
.calendarHeaderButtons {
  color: $primaryColor !important;
}
.dayCellEvent {
  height: 25px !important;
  font-size: x-large;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
}
.dayDate {
  display: flex !important;
  justify-content: flex-end;
}
@media only screen and (max-width: 1024px) {
  // .worship_screen {
  //   .h3 {
  //     font-size: 9px;
  //     font-weight: 700;
  //   }
  // }
}

@media only screen and (max-width: 600px) {
  .worship_screen
    .worship_screen_container
    .worship_screen_title_wrapper
    .worship_screen_title
    .title_links_wrapper {
    display: flex;
    height: 30px;
    padding: 0rem;
    justify-content: center;
    position: relative;
  }
  .worship_screen
    .worship_screen_container
    .worship_screen_title_wrapper
    .worship_screen_title
    .title_links_wrapper
    .link
     {
    position: relative;
    font-weight: 400;
    padding-right: 2em;
    font-size: 10px;
  }
  .worship_screen .worship_screen_container .worship_screen_title_wrapper .worship_screen_title .title_links_wrapper:after {
    content: '';
    width: 90%;
    position: absolute;
    bottom: 2px;
    left: 15px;
    height: 2px;
    border-bottom: 3px solid #FFF3Fa;
}
}

.dailyEventWrapper{
  height: fit-content!important;
}
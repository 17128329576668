@import "../../index.scss";

.personnels_screen {
  background-color: $background;
  width: 100%;
  .personnels_container {
    width: 100%;
    .personnels_wrapper {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      .personnels_header_wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding-top: 2em;
        .personnels_navigate_icon_wrapper {
          .personnels_navigate_icon {
            .navigate_icon {
              transform: rotate(90deg);
            }
          }
        }
        .personnels_header_name_container {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          .personnels_header_name {
            padding-left: 1em;
            font-weight: 700;
          }
          .personnels_header_button_wrapper{
            padding-right: 2.5em;
          }
        }
      }
      .personnels_navbar_wrapper {
        width: 100%;
      }
      .personnels_table_wrapper{
        width: 100%;
      }
    }
  }
}

.personnels_modal {
  background: $background;
  width: 300px;
  max-height: 90vh;
  border-radius: 16px;
  box-shadow: 0 10 28px 0px $primaryColor;
  padding: 0;

  .close_button{
    outline: none;
  }
  .personnels_modal_header_wrapper{
    background: $background;
    position: sticky;
    top: 0;
    z-index: 1;
    .personnels_modal_title_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1em;
      padding-right: 15px;
  
      .personnels_modal_title {
        font-weight: bold;
        margin-left: 0.75em;
        color: $primaryTextColor;
      }
      .personnels_modal_icon_wrapper{
        .personnels_modal_icon{
          cursor: pointer;
          width: 30px;
          height: 30px;
        }
      }
    }
    .personnels_modal_line_wrapper {
      width: 100%;
      height: 1px;
      background-color: $primaryColor;
    }
  }
  .personnels_modal_input_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow-x: auto;
    .personnels_modal_input {
      // display: flex;
      // flex-direction: column;
      // justify-content: center;
      // align-items: flex-start;
      // padding-left: 2em;
      padding-top: 1.5em;
      width: 75%;
      .personnels_thumbnail_wrapper {
        padding-top: 1.5em;
      }
      .department-events_modal_uploadbox{
        padding-top: 1em;
        width: 100%;
      }
    }
  }

  .personnels_modal_input_wrapper::-webkit-scrollbar{
    display: none;
  }

  .personnels_button_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;

    .personnels_button{
      width: 120px;
    }
  }
}

.personnels_modal::-webkit-scrollbar {
  display: none;
}

@import "../../index.scss";

.newsletter_all_screen{
  .newsletter_all_container{
    .newsletter_all_wrapper{
      .newsletter_all_header_wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .newsletter_all_action_button_wrapper{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-right: 2em;

          .newsletter_all_filter_button_wrapper{
            padding-left: 1em;
          }

          .newsletter_all_action_button_wrapper{
            padding-left: 1em;
          }
        }
      }
    }
  }
}

.newsletter_all_filter_modal {
  background: $background;
  width: 500px;
  height: 70vh;
  border-radius: 16px;
  overflow-x: auto;
  box-shadow: 0 10 28px 0px $primaryColor;
  padding: 0;
  .newsletter_all_filter_modal_container {
    .newsletter_all_filter_modal_wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      .newsletter_all_filter_modal_header_wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        .newsletter_all_filter_modal_header {
          padding-left: 1em;
          padding-top: 1em;
          font-weight: 700;
        }
        .newsletter_all_filter_modal_clear {
          padding-right: 1em;
          padding-top: 1em;
          font-weight: 300;
          cursor: pointer;
        }
        .newsletter_all_filter_modal_department_heading {
          padding-left: 1em;
          padding-top: 2em;
          font-weight: 700;
        }
        .newsletter_all_filter_modal_department_clear {
          padding-right: 1em;
          padding-top: 2em;
          font-weight: 300;
          cursor: pointer;
        }
      }
      .newsletter_all_filter_button_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 1em;
        padding-top: 1em;
        .button_inactive {
          padding-left: 1em;
        }
      }
      .newsletter_all_filter_apply_filter_button_wrapper {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        padding: 1em;
      }
      .newsletter_all_filter_modal_checklist {
        width: 100%;
        height: 300px;
        overflow-x: auto;
        padding-right: 2em;
        padding-top: 1em;
      }
      .newsletter_all_filter_modal_checklist::-webkit-scrollbar {
        display: none;
      }
      .newsletter_all_filter_modal_date_wrapper{
        display: flex;
        width: 100%;
        justify-content: flex-start;
        align-items: center;

        .newsletter_all_filter_modal_name {
          padding-left: 1em;
          font-weight: 600;
          color: $secondaryTextColor;
        }
        .newsletter_all_filter_modal_input_wrapper{
          padding-left: 1em;
          width: 150px;
        }
      }
    }
  }
}

.newsletter_all_filter_modal::-webkit-scrollbar{
  display: none;
}

.newsletter_all_news_modal{
  background: $background;
  width: 400px;
  height: 120px;
  border-radius: 16px;
  overflow-x: auto;
  box-shadow: 0 10 28px 0px $primaryColor;
  padding: 0;

  .newsletter_all_notification_wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .newsletter_all_message{
      padding-top: 1em;
      font-weight: 400;
    }

    .newsletter_all_button_wrapper{
      padding-top: 2em;
      width: 70px;
    }
  }
}
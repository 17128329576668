@import "../../../index.scss";

.button_container {
  background-color: transparent;
  width: 100%;
  .button_wrapper {
    color: $secondaryLightColor;
    background-color: $primaryColor;
    border-radius: 50px;
    display: flex;
    justify-content: space-around;
    padding: 0.6em;
    cursor: pointer;

    .button_icon_container {
      display: flex;
      align-items: flex-start;
      .button_icon {
        width: 14px;
        padding: 0.3em 0 0 0.2em;
      }
    }
    .button_name {
      padding: 0.1em 0 0 0.2em;
    }
  }

  .btn_secoundary {
    color: $primaryColor;
    background-color: $secondaryLightColor;
    border-radius: 50px;
    border: 0.5px solid $primaryColor;
    display: flex;
    justify-content: space-around;
    padding: 0.6em;
    cursor: pointer;
  }
}

@import '../../index.scss';

// .csi_user_screen {
//   background-color: $background;
//   border-radius: 15px;
//   box-shadow: 0px 0px 12px $secondaryLightColor;
// }

.customModal {
  background: $background;
  width: 300px;
  height: 480px;
  border-radius: 16px;
  box-shadow: 0 10 28px 0px $primaryColor;
  padding: 0;

  .customModal::-webkit-scrollbar {
    display: none;
  }

  .modal_title_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1em;

    .modal_title {
      font-weight: bold;
      margin-left: 0.75em;
      color: $primaryTextColor;
    }
  }
  .modal_line_wrapper {
    width: 100%;
    height: 1px;
    background-color: $primaryColor;
  }
  .modal_input_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 350px;
    .modal_input {
      width: 75%;
    }
  }
  .csi_user_button_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
  }
}

@import "../../index.scss";

.add_csita_officers_screen {
  .add_csita_officers_container {
    .add_csita_officers_wrapper {
      .add_csita_officers_header_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 1.5em;
        border-bottom: 1px solid $secondaryDarkColor;
        .add_csita_officers_header_name_wrapper {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .add_csita_officers_icon_wrapper {
            .add_csita_officers_nav_icon {
              transform: rotate(90deg);
            }
          }
          .add_csita_officers_header_name {
            font-weight: 600;
            color: $primaryTextColor;
            padding-left: 1em;
          }
        }
        .add_csita_officers_header_button_wrapper{
          width: 80px;
        }
      }
      .add_csita_officers_body_wrapper {
        display: flex;
        justify-content: center;

        .add_csita_officers_input_wrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 100%;
          padding-top: 3em;
          padding-left: 4em;

          .add_csita_officers_input {
            width: 80%;
            padding-top: 1em;
            .add_csita_officers_text_area_wrapper{
              height: 100px;
            }
            .upload_box_wrapper {
              width: 100%;
              padding-top: 1em;
              .upload_box {
                padding-top: 1em;
              }
            }
          }
        }
      }
    }
  }
}
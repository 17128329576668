@import "../../../index.scss";

.text_area_container {

  .text_area_wrapper {
    height: 100px;
    input:focus ~ .label,
    textarea:focus ~ .label {
      color: $primaryColor;
      top: -1.5rem;
      -webkit-transition: all 0.125s ease;
      transition: all 0.125s ease;
    }

    input:valid ~ .label,
    textarea:valid ~ .label {
      top: -1.5rem;;
      -webkit-transition: all 0.125s ease;
      transition: all 0.125s ease;
    }

    .styled-input {
      width: 100%;
      float: left;
      margin: 2rem 0 1rem;
      position: relative;
    }

    .styled-input .label {
      color: $secondaryTextColor;
      // padding: 1rem;
      position: absolute;
      top: 0;
      left: 2px;
      -webkit-transition: all 0.25s ease;
      transition: all 0.25s ease;
      pointer-events: none;
    }

    .styled-input.wide {
      width: 100%;
    }

    input,
    textarea {
      padding: 0 0 0.5rem 0;
      border: 0;
      width: 100%;
    }

    input ~ span,
    textarea ~ span {
      display: block;
      width: 100%;
      height: 1px;
      background: $secondaryTextColor;
      position: absolute;
      bottom: 0;
      left: 0;
      -webkit-transition: all 0.125s ease;
      transition: all 0.125s ease;
    }

    input:focus,
    textarea:focus {
      outline: 0;
    }

    input:focus ~ span,
    textarea:focus ~ span {
      width: 100%;
      background: $primaryColor;
      -webkit-transition: all 0.075s ease;
      transition: all 0.075s ease;
    }

    textarea {
      width: 100%;
      // height: 80px;
      // min-height: 15em;
      resize: none;
      overflow-x: hidden;
      font-family: "Raleway";
      font-weight: 500;
    }
    textarea::-webkit-scrollbar{
      display: none;
    }
  }
}

@import "../../index.scss";

.confirmModal{
  background: $background;
  width: 27%;
  min-height: 150px;
  border-radius: 16px;
  box-shadow: 0 10 28px 0px $primaryColor;
  padding: 0;

  .confirmation_modal_wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 2.5em;

    .confirmation_modal_description_wrapper{
      font-weight: 500;
    }

    .confirmation_modal_button_wrapper{
      display: flex;
      width: 55%;
      justify-content: space-evenly;
      align-items: center;

      .confirmation_modal_button{
        width: 150px;
        padding: 1em;
      }
    }
    
  }
}
@import "../../index.scss";

.banner_image_screen {
  background-color: $background;
  width: 100%;
  .banner_image_container {
    width: 100%;
    .banner_image_wrapper {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      .banner_image_header_wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding-top: 2em;
        .banner_image_navigate_icon_wrapper {
          .banner_image_navigate_icon {
            .navigate_icon {
              transform: rotate(90deg);
            }
          }
        }
        .banner_image_header_name_container {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          .banner_image_header_name {
            padding-left: 1em;
            font-weight: 700;
          }
          .banner_image_header_button_wrapper{
            padding-right: 2.5em;
          }
        }
      }
      .banner_image_navbar_wrapper {
        width: 100%;
      }
      .banner_image_table_wrapper{
        width: 100%;
      }
    }
  }
}
.banner_no_image_modal{
  height: 40vh;
  background: $background;
  width: 22%;
  border-radius: 16px;
  box-shadow: 0 10 28px 0px $primaryColor;
  padding: 0;
}

.banner_image_modal {
  background: $background;
  width: 22%;
  height: 55vh;
  border-radius: 16px;
  box-shadow: 0 10 28px 0px $primaryColor;
  padding: 0;
  .banner_image_modal::-webkit-scrollbar {
    display: none;
  }

  .banner_image_modal_title_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1em;
    padding-right: 15px;

    .banner_image_modal_title {
      font-weight: bold;
      margin-left: 0.75em;
      color: $primaryTextColor;
    }
  }
  .banner_image_modal_line_wrapper {
    width: 100%;
    height: 1px;
    background-color: $primaryColor;
  }
  .banner_image_modal_input_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5em;
    .banner_image_modal_input {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding-left: 2em;
      .banner_image_thumbnail_wrapper {
        padding-top: 1.5em;
      }
      .department-events_modal_uploadbox{
        padding-top: 1em;
        width: 100%;
      }
    }
  }
  .banner_image_button_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
  }
}
.uploaded_banner_image{
  display: flex;
  justify-content: center;
  align-items: center;
}
.banner_image{
  width: 300px;
height: 300px;
margin-top: 1em;
}
.upload_box{
margin-top: 1em;
}
.upload_box_wrapper{
  width: 100%;
}
.banner_button_wrapper{
  width: 90px;
}.banner_image_modal::-webkit-scrollbar {
  display: none;
}
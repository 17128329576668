@import "../../index.scss";

.csi_videos_screen {
  .csi_videos_container {
    .csi_videos_wrapper {
      .csi_videos_header_wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 3em;

        .csi_videos_search_box_wrapper {
          width: 200px;
          padding-right: 1em;
        }

        .csi_videos_select_box_wrapper {
          padding-left: 1em;
          padding-right: 1em;
          width: 130px;
        }
        .csi_videos_button_wrapper {
          padding-left: 1em;
        }
      }
    }
  }
}
.video_file_upload_wrapper {
  display: flex;
  margin-top: 1.5em;
  justify-content: space-between;
  width: 90%;
  height: 100%;
  border-radius: 2%;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  background-color: $secondaryLightColor;

  .file_upload_file_name {
    display: flex;
    padding-left: 0.5em;
    width: 85%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .file_upload_inactive_icon-wrapper {
    display: flex;
    justify-items: center;
    align-items: center;

    .file_upload_delete_icon {
      width: 20px;
      height: 20px;
    }
  }
}
.csi_videos_customModal {
  background: $background;
  width: 350px;
  max-height: 90vh;
  // min-height: 500px;
  border-radius: 16px;
  box-shadow: 0 10 28px 0px $primaryColor;
  padding: 0;
  position: relative;

  .close_button {
    outline: none;
  }

  .csi_videos_modal_header_wrapper {
    position: sticky;
    top: 0;
    z-index: 1;
    background: $background;
  }

  .csi_videos_modal_header_wrapper {
    background-color: $background;
    position: sticky;
    top: 0;
    z-index: 1;
    .csi_videos_modal_title_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1em;

      .csi_videos_modal_title {
        font-weight: bold;
        margin-left: 0.75em;
        color: $primaryTextColor;
        position: sticky;
        top: 0;
      }
    }
    .csi_videos_modal_icon_wrapper{
      .csi_videos_modal_icon{
        width: 30px;
        height: 30px;
      }
    }
  }
  .csi_videos_modal_line_wrapper {
    width: 100%;
    height: 1px;
    background-color: $primaryColor;
  }
  .csi_videos_modal_input_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    .csi_videos_modal_input {
      padding-top: 1em;
      width: 80%;

      .csi_videos_modal_label_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
      }
      .upload_box_wrapper {
        width: 100%;
        padding-top: 1em;
        .upload_box {
          padding-top: 1em;
        }
      }
    }

    .csi_videos_file_upload_wrapper {
      display: flex;
      margin-top: 1.5em;
      justify-content: space-between;
      width: 90%;
      height: 100%;
      border-radius: 2%;
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      background-color: $secondaryLightColor;

      .file_upload_file_name {
        display: flex;
        padding-left: 0.5em;
        width: 85%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .file_upload_inactive_icon-wrapper {
        display: flex;
        justify-items: center;
        align-items: center;

        .file_upload_delete_icon {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .csi_videos_modal_button_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .csi_videos_modal_button {
      width: 150px;
      padding: 2em 0;
    }
  }
}

.csi_videos_customModal::-webkit-scrollbar {
  display: none;
}
.csi_video_wrapper{
  display: flex;
}
.csi_videos_header_wrapper{
  display: flex!important;
  justify-content: space-between!important;
}
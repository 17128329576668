@import "../../index.scss";

.advertisement_screen {
  .advertisment_container {
    .advertisment_wrapper {
      .advertisment_header_wrapper {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding-top: 2em;
        padding-bottom: 2em;

        .advertisment_select_box_wrapper {
          width: 250px;
          padding-left: 2em;
        }

        .advertisement_button_wrapper {
          width: 160px;
          padding-right: 2em;
          padding-left: 2em;
        }
      }
    }
  }
  .advertisement_title_wrapper{
    padding-left: 1em;
  }
  .advertisement_action_wrapper{
    display: flex;
  }
  @media only screen and (min-width: 1500px) {
    .advertisment_container {
      .advertisment_wrapper {
        .advertisement_customModal {
          background: $background;
          width: 25%;
          // max-height: 700px;
          height: 50vh;
          border-radius: 16px;
          box-shadow: 0 10 28px 0px $primaryColor;
          padding: 0;
          position: relative;
        }
      }
    }
  }
}

.advertisement_customModal {
  background: $background;
  width: 25%;
  // max-height: 700px;
  max-height: 90vh;
  border-radius: 16px;
  box-shadow: 0 10 28px 0px $primaryColor;
  padding: 0;
  overflow-y: auto;
  position: relative;

  .close_button {
    outline: none;
  }

  .advertisement_modal_header_wrapper {
    background-color: $background;
    position: sticky;
    top: 0;
    z-index: 1;
    .advertisement_modal_title_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1em;

      .advertisement_modal_title {
        font-weight: bold;
        margin-left: 0.75em;
        color: $primaryTextColor;
        position: sticky;
        top: 0;
      }
    }
    .advertisement_modal_icon_wrapper{
      .advertisement_modal_icon{
        width: 30px;
        height: 30px;
      }
    }
  }
  .advertisement_modal_line_wrapper {
    width: 100%;
    height: 1px;
    background-color: $primaryColor;
  }
  .advertisement_modal_input_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    // height: 390px;
    // padding-top: 7em;
    // padding-bottom: 1em;
    .advertisement_modal_input {
      padding-top: 1em;
      width: 80%;
      .upload_box_wrapper {
        width: 100%;
        padding-top: 1em;
      }
      .advertisement_modal_select_box{
        padding-top: 1em;
        padding-bottom: 1em;
        height: 100%;
      }
    }
  }

  .advertisement_modal_input_wrapper::-webkit-scrollbar {
    display: none;
  }
  .advertisement_button_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .advertisement_button {
      padding-top: 2em;
      padding-bottom: 1em;
      width: 120px;
    }
  }
}

.advertisement_customModal::-webkit-scrollbar {
  display: none;
}

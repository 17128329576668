@import "../../index.scss";
.department_news_screen {
  background-color: $background;
  border-radius: 15px;
  box-shadow: 0px 0px 12px $secondaryLightColor;
  .department_news_container {
    .department_news_info_card_wrapper {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      margin-left: 0.06em;
      padding: 1em;
      width: 90%;

      .department_news_info_card {
        width: 24%;
        padding-left: 1em;
      }

      .department_news_action_button_wrapper {
        display: flex;
        width: 25%;
        height: 100%;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        padding-left: 2em;

        .department_news_action_button {
          width: 90%;
          padding-top: 0.5em;
        }
      }
    }
  }
}
.department_news_modal_header_wrap {
  width: 100%;
}
.department_news_modal {
  background: $background;
  width: 350px;
  // height: 450px;
  max-height: 90vh;
  border-radius: 16px;
  box-shadow: 0 10 28px 0px $primaryColor;
  padding: 0;
  .department_news_modal_container {
    .department_news_modal_wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      .department_news_modal_header_wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        .department_news_modal_header {
          padding-left: 1em;
          padding-top: 1em;
          font-weight: 700;
        }
        .department_news_modal_clear {
          padding-right: 1em;
          padding-top: 1em;
          font-weight: 300;
          cursor: pointer;
        }
        .department_news_modal_department_heading {
          padding-left: 1em;
          padding-top: 2em;
          font-weight: 700;
        }
        .department_news_modal_department_clear {
          padding-right: 1em;
          padding-top: 2em;
          font-weight: 300;
          cursor: pointer;
        }
      }
      .department_news_button_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 1em;
        padding-top: 1em;
        .button_inactive {
          padding-left: 1em;
        }
      }
      .department_news_apply_filter_button_wrapper {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        padding: 1em;
      }
      .department_news_modal_checklist {
        width: 100%;
        height: 250px;
        overflow-x: auto;
        padding-right: 2em;
      }
      .department_news_modal_checklist::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

@import '../../index.scss';

.schemes_screen {
  width: 100%;
  height: 100%;
  overflow: auto;
  .schemes_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: $backgroundColor;
    .schemes_wrapper {
      width: 100%;
      .schemes_header_wrapper {
        width: 100%;
      }
      .schemes_navbar_wrapper {
        width: 100%;
      }
      .schemes_title_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 2em;

        .schemes_title {
          font-weight: 400;
          font-family: 'Merriweather';
        }
        .schemes_line_wrapper {
          width: 300px;
          margin-top: 1em;
          height: 2px;
          background: $primaryColor;
        }
      }
      .schemes_content_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 2em;
        width: 100%;
        .schemes_content {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: flex-start;
          max-width: 1170px;
          .schemes_content_event_card {
            width: 50%;
            padding: 4em;
          }
        }
        .schemes_load_more_button_wrapper {
          padding-top: 3em;
          .schemes_load_more_button {
            width: 150px;
            height: 50px;
          }
        }
      }
      .schemes_footer_wrapper {
        padding-top: 5em;
      }
    }
  }
}
@media only screen and (max-width: 1500px) {
  .schemes_screen .schemes_container .schemes_wrapper .schemes_content_wrapper {
    .schemes_content {
      width: 80vw;
      .schemes_content_event_card {
        padding: 2em;
      }
    }
  }
}
@media screen and(max-width: 1024px) {
  .schemes_screen .schemes_container .schemes_wrapper .schemes_title_wrapper {
    padding-top: 3em;
  }
  .schemes_screen .schemes_container .schemes_wrapper .schemes_content_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    width: 100%;
  }
  .schemes_screen
    .schemes_container
    .schemes_wrapper
    .schemes_content_wrapper
    .schemes_content
    .schemes_content_event_card {
    width: 85%;
    padding: 2em;
  }
  .schemes_screen
    .schemes_container
    .schemes_wrapper
    .schemes_content_wrapper
    .schemes_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 75vw;
  }
}
@media screen and (max-width: 600px) {
  .schemes_screen
    .schemes_container
    .schemes_wrapper
    .schemes_title_wrapper
    .schemes_title {
    font-weight: 400;
    font-family: 'Merriweather';
    font-size: 16px;
  }
  .schemes_screen
    .schemes_container
    .schemes_wrapper
    .schemes_content_wrapper
    .schemes_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .schemes_screen
    .schemes_container
    .schemes_wrapper
    .schemes_title_wrapper
    .schemes_line_wrapper {
    width: 180px;
    margin-top: 1em;
    height: 2px;
    background: #b5316a;
  }
  .schemes_screen
    .schemes_container
    .schemes_wrapper
    .schemes_content_wrapper
    .schemes_content
    .schemes_content_event_card {
    width: 100%;
    padding: 1em;
  }
}

@import "../../index.scss";

.officers_screen {
  .officers_container {
    .officers_wrapper {
      .officers_header_wrapper {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding-top: 2em;
        padding-bottom: 1em;

        .officers_name_wrapper{
          color: $primaryTextColor;
          font-weight: 600;
          padding-left: 2em;
        }

        .officers_action_wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50%;

          .officers_search_box_wrapper {
            width: 50%;
          }

          .officers_button_wrapper {
            width: 180px;
            padding-right: 2em;
            padding-left: 2em;
          }
        }
      }
    }
  }
}
.officer_navbar_button{
  width: 130px;
}
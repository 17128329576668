@import '../../index.scss';

.table_component {
  width: 100%;
  .table_container {
    position: relative;
    .table_wrapper {
      padding-bottom: 4em;
      // overflow: hidden;
      // .table_content {overflow: scroll;}
      .table_content thead th {
        position: sticky;
        top: 0;
      }

      /* Just common table stuff. Really. */
      table {
        border-collapse: collapse;
        width: 100%;
      }
      td {
        padding: 0.5em 0.8em;
        width: 110px;
      }
      th {
        background: $secondaryLightColor;
        padding: 1em;
      }

      /* Borders (if you need them) */
      td {
        text-align: left;
        border-bottom: 1px solid $borderWithOpacity;
        max-width: 205px!important;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .table_content th {
        text-align: left;
      }

      .table_head_check {
        width: 20px!important;
        .thead_checked {
          background: $primaryColor;
          width: 20px;
          height: 20px;
          text-align: center;
          align-self: center;
          border-radius: 50%;
          .table_check_icon {
            padding: 0.2em;
          }
        }
      }

      .table_body_check {
        width: 20px!important;
        padding-left: 1em;
        .tbody_checked {
          background: $primaryColor;
          width: 20px;
          height: 20px;
          text-align: center;
          align-self: center;
          border-radius: 50%;
          .table_check_icon {
            padding: 0.2em;
          }
        }
      }

      .thead_unchecked {
        background: $background;
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }

      .tbody_unchecked {
        background: $secondaryLightColor;
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }

      .table_img {
        width: 100px;
        height: 100px;
        object-fit: contain;
      }
      .table_content::-webkit-scrollbar {
        display: none; /* width of the entire scrollbar */
        scrollbar-width: none;
      }
      .table_action_container {
        min-width: 150px;
        width: 60%;
        display: flex;
        flex-direction: column;
        text-align: left;
        .table_action_btn_container {
          padding: 0.3em;
          text-align: center;
          // width: 140px;
        }
      }
    }
    .download_btn {
      text-decoration: none;
      cursor: pointer;
      color: black;
    }
    .empty_record {
      color: $primaryTextColor;
      align-self: center;
      padding: 1rem;
    }
    .pagination_container {
      position: absolute;
      bottom: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      padding-top: 2em;
      padding-bottom: 1em;
      .pagination_btn {
        width: 15%;
      }
    }
  }
}

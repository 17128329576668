@import '../../index.scss';

.text_area_component {
  width: 100%;
  height: 100%;
  .text_area_container {
    height: 100%;
    .text_area_wrapper {
      height: 100%;
      .text_area {
        height: 100%;
        border: none;
      }
    }
  }
}
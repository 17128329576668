@import '../../index.scss';
// .whatsapp_icon{
//   width:25px
// }
.header_component {
  transition: all 0.3s ease-in;
  a{
    text-decoration: none;
    color: $primaryColor;
  }
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    position: relative;
    background-color: $secondaryLightColor;
  .header_container {
    display: flex;
    justify-content: space-around;
    padding: 1em;
    background-color: $secondaryLightColor;
    width: 96%;
    .header_wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      .icon_container {
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        .header_hamber_icon{
          width: 50px;
          height: 30px;
        }
        .header_social_icon{
          cursor: pointer;
        }.header_social_icon_instagram{
          cursor: pointer;
          // width: 100%;
          // width: 70%;
          // height: 70%;
        }
      }

      .header_action_icons {
        width: 40px;
        height: 30px;
      }

      .csi_logo_container {
        text-align: center;

        .csi_title {
          color: $primaryColor;
          padding-top: 0.5em;
        }
      }
    }
  }
  .search_input_wrapper{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    top: 188px;
    width: 100%;
    height: 680px;
    z-index: 1000000000;
    background: $secondaryLightColor;
    padding-bottom: 1em;

    .search_input_content{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 55%;
      height: 25%;
      .input_search_icon_wrapper{
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: -10px;
        .input_search_icon{
          margin-top: 17px;
        }
        .input_search_button{
          width: 120px;
          height: 40px;
        }
      }
      .search_input_field{
        outline: 0;
        border-width: 0 0 1px;
        width: 100%;
        padding-left: 2em;
        padding-right: 8em;
        background: transparent;
        font-size: 20px;
        margin-top: -15px;
        padding-bottom: 1em;
      }
    }
    .search_input_button_wrapper{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 70%;
      position: absolute;
      bottom: -20px;
      .search_button{
        width: 100px;
        height: 40px;
        fill: $background
      }
    }
  }
  .header_menu_wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: -187px;
    width: 100%;
    height: 100%;
    z-index: 1000000000;
    // padding-bottom: 3em;
    background: $secondaryLightColor;
    .header_menu_content{
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 55%;
      .header_menu_content_name{
        color: $primaryColor
      }
    }
    .header_menu_button_wrapper{
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: -10px;
      background: $primaryColor;
      .header_menu_close_icon{
        width: 25px;
        height: 25px;
        cursor: pointer;
        filter: brightness(0) invert(1);
      }
    }
  }
  .header_action_icons:hover{
    background: $videoBottom;
    cursor: pointer;
  }
  .header_hamber_icon:hover{
    background-color: $videoBottom;
    cursor: pointer;
  }
}
@media screen and (max-width: 1500px) {
  .header_component .header_menu_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: -173px;
    width: 100%;
    height: 100%;
    z-index: 1000000000;
    background: #FFF3Fa;
  }
  .header_component .search_input_wrapper{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    top: 184px;
    width: 100%;
    height: 560px;
    z-index: 1000000000;
    background: $secondaryLightColor;
    transition: all 0.3s ease-in-out;
    padding-bottom: 1em;
  }
}
@media screen and (max-width:1024px) {
  .header_component .search_input_wrapper {
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    position: absolute;
    top: 170px;
    width: 100%;
    height: 680px;
    z-index: 1000000000;
    background: #fff3fa;
    transition: all .3s ease-in-out;
    padding-bottom: 1em;
}
.header_component .header_container {
  display: flex;
  justify-content: space-around;
  padding: 1em;
  background-color: #FFF3Fa;
  width: 93%;
}
.header_component .header_menu_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -173px;
  width: 100%;
  height: 100%;
  z-index: 1000000000;
  background: #FFF3Fa;
}
}
  @media only screen and (max-width: 600px){
    .header_component{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      .header_container{
        display: flex;
        justify-content: center;
        padding: 1em 0;
        align-items: center;
        width: 100%;
        .header_wrapper{
          .icon_container{
            display: flex;
            width: 0px;
            opacity: 0;
          }
          .csi_logo_container{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 300px;
            .csi_logo{
              .csi_logo_home{
                width: 57px;
                height: 57px;
              }
            }
            .csi_title{
              width: 100%;
              font-size: 15px;
              padding-left: 1em;
              padding-top: 0;
            }
          }
        }
      }
    }
   .global_search_button {
      // width: 50%!important;
    }
  }
  .global_news_wrapper{
    padding: 1em;
    overflow-y: scroll;
    width: 55%;
    height: 100%;
  }
  .global_news_card{
    padding: 1em 0;
  }
  .header_close_icon{
    width: 40px;
    height: 40px;
  }
  ::-webkit-scrollbar {
    display: none;
}
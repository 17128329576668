@import "../../index.scss";

.add_news_screen {
  .add_news_content {
    .add_news_head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1em;
      border-bottom: 1px solid $primaryColor;
      top: 0;

      .add_news_head_text {
        width: 60%;
      }
      
      .add_news_btn_container {
        width: 40%;
        display: flex;
        justify-content: flex-end;
        align-items:center;

        .add_news_select {
          width: 60%;
        }

        .add_news_btn {
          width: 100px;
          padding: 0 0 0 2em;
        }
      }
    }
    .news_content{
      display: flex;
      flex-direction: column;

      .news_content_title_wrapper{
        width: 72%;
        padding-left: 1em;
      }
      .news_content_heading_wrapper{
        width: 72%;
        padding-left: 1em;
      }
    }
  }
}
.department_view_navigate_icon_wrapper {
  .department_view_navigate_icon {
    .navigate_icon {
      transform: rotate(90deg);
      margin-right: 1em;
    }
  }
}
.news_content_date_wrapper{
  width: 150px;
  padding-left: 1em;
  padding-top: 1em;
}
.ql-snow .ql-tooltip {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 5px #ddd;
  color: #444;
  padding: 5px 12px;
  white-space: nowrap;
  left: 0!important;
}
.ql-snow .ql-editor img {
  max-width: 500px;
  display: flex;
  max-height: 500px;
}
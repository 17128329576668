@import '../../index.scss';

.landing_page_wrapper {
  width: 100vw;
  height: 100%;
  background: url(../../assets/images/Landing.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .landing_page_overlay {
    background: linear-gradient(
      180deg,
      rgba(207, 14, 98, 0.71) 0%,
      rgba(172, 20, 86, 0.89) 55%,
      rgb(181, 49, 106) 100%
    );
    position: absolute;
    top: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  .landing_page_header_container {
    display: flex;
    justify-content: space-between;
    padding: 3em;
    z-index: 9999;

    .landing_page_header_wrapper {
      display: flex;
      width: 30%;
      justify-content: flex-start;
      align-items: center;
      padding: 2em;
      z-index: 9999;
      .landing_page_icon_container {
        width: 50px;
        height: 30px;
        z-index: 9999;
      }
      .landing_page_header_action_icons {
        width: 40px;
        height: 30px;
      }
    }
  }
  .landing_page_text_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 9999;
    color: $background;
    .landing_page_logo {
      padding: 2em;
    }
    .landing_page_heading_text {
      padding: 1em;
      font-family: 'Merriweather';
      font-weight: 400;
      font-size: 55px;
    }
    .landing_page_description {
      width: 30%;
      text-align: center;
      font-weight: 500;
    }
    .landing_page_button_wrapper {
      padding-top: 2em;
      .landing_page_button {
        width: 170px;
      }
    }
  }
  .landing_page_footer_wrapper {
    display: flex;
    justify-content: space-between;
    padding: 4em;
    .landing_page_footer_links {
      z-index: 9999;
      .landing_page_footer_button {
        background: transparent;
        color: $background;
        z-index: 9999;
        img {
          transform: rotate(90deg);
          height: 30px;
          width: 20px;
        }
      }
    }
  }
  .hamber_wrapper {
    justify-content: flex-end !important;
  }
  .landing_page_header_social_icon {
    width: 25px;
    filter: brightness(0) invert(1);
  }
}

@media only screen and (max-width: 1500px) {
}
.landing_page_search_menu_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $secondaryLightColor;
  width: 100%;
  position: absolute;
  z-index: 10000000;
  height: 30%;
  .landing_page_header_wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 90%;
    // background: chartreuse;
    .landing_page_icon_wrapper {
      display: flex;
      justify-content: center;
      width: 100%;
      .facebook_icon {
        cursor: pointer;
      }
      .twitter_icon {
        cursor: pointer;
        margin-left: 1em;
        width: 25px;
      }
      .youtube_icon {
        cursor: pointer;
        margin-left: 1em;
      }
    }
    .landing_page_main_logo_wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      .landing_page_main_logo_title {
        color: $primaryColor;
        padding-top: 1em;
        font-weight: 700;
      }
    }
    .landing_page_search_menu_content {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      .search_icon {
        margin-right: 1.5em;
      }
      .search_icon:hover {
        background: $videoBottom;
        cursor: pointer;
      }
      .hamber_icon:hover {
        background: $videoBottom;
        cursor: pointer;
      }
    }
    .landing_search_input_wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      position: absolute;
      top: 254px;
      width: 100%;
      height: 658px;
      z-index: 1000000000;
      background: $secondaryLightColor;

      .landing_search_input_content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 55%;
        height: 25%;
        margin-top: 1em;
        .landing_input_search_icon_wrapper {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-bottom: -10px;
          .landing_input_search_button {
            width: 120px;
            height: 40px;
          }
        }
        .landing_search_input_field {
          outline: 0;
          border-width: 0 0 1px;
          width: 100%;
          padding-left: 2em;
          padding-right: 8em;
          background: transparent;
          font-size: 20px;
          margin-top: -15px;
          padding-bottom: 1em;
        }
      }
      .landing_search_input_button_wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 70%;
        position: absolute;
        bottom: -20px;
        .landing_search_button {
          width: 100px;
          height: 40px;
          fill: $background;
        }
      }
    }
    .landing_header_menu_wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0px;
      width: 100%;
      height: 100%;
      z-index: 1000000000;
      // padding-bottom: 3em;
      background: $secondaryLightColor;
      .landing_header_menu_content {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 55%;
        .landing_header_menu_content_name {
          color: $primaryColor;
          cursor: pointer;
        }
      }
      .landing_header_menu_button_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: -10px;
        background: $primaryColor;
        .landing_header_menu_close_icon {
          width: 25px;
          height: 25px;
          cursor: pointer;
          filter: invert(100%) sepia(0%) saturate(7494%) hue-rotate(345deg)
            brightness(99%) contrast(105%);
        }
      }
    }
  }
  .global_search_button_wrapper {
    padding-bottom: 1em;
  }
}
@media screen and (min-width: 2500px) {
  .landing_page_search_menu_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff3fa;
    width: 100%;
    position: absolute;
    z-index: 10000000;
    height: 15%;
  }
  .landing_page_search_menu_wrapper .landing_page_header_wrapper .landing_search_input_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    top: 238px;
    width: 100%;
    height: 658px;
    z-index: 1000000000;
    background: #FFF3Fa;
}
}
@media only screen and (max-width: 1500px) {
  .landing_page_text_wrapper {
    .landing_page_description {
      width: 40%;
    }
  }
  .landing_page_footer_wrapper {
    padding: 2em;
  }
  .landing_page_search_menu_wrapper {
    .landing_page_header_wrapper {
      .landing_search_input_wrapper {
        top: 194px;
      }
    }
  }
  .landing_page_search_menu_wrapper
    .landing_page_header_wrapper
    .landing_search_input_wrapper
    .landing_search_input_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 55%;
    height: 17%;
    margin-top: 1em;
  }
  .landing_page_search_menu_wrapper
    .landing_page_header_wrapper
    .landing_search_input_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    top: 194px;
    width: 100%;
    height: 506px;
    z-index: 1000000000;
    background: #fff3fa;
  }
  .global_search_button_wrapper {
    padding: 0.5em 0;
  }
}
@media screen and (max-width: 1024px) {
  .landing_page_search_menu_wrapper
    .landing_page_header_wrapper
    .landing_search_input_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    top: 234px;
    width: 100%;
    height: 506px;
    z-index: 1000000000;
    background: #fff3fa;
  }
  .landing_page_wrapper .landing_page_text_wrapper .landing_page_heading_text {
    text-align: center;
  }
  .landing_page_wrapper .landing_page_text_wrapper .landing_page_description {
    width: 70%;
    font-size: 28px;
  }
  .landing_page_wrapper
    .landing_page_text_wrapper
    .landing_page_button_wrapper {
    padding-top: 4em;
  }
  .landing_page_wrapper
    .landing_page_footer_wrapper
    .landing_page_footer_links {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 600px) {
  .landing_page_wrapper .landing_page_footer_wrapper {
    padding: 1em;
  }
  .landing_page_search_menu_wrapper
    .landing_page_header_wrapper
    .landing_page_main_logo_wrapper
    .landing_page_main_logo_title {
    color: #b5316a;
    padding-top: 1em;
    font-weight: 700;
    font-size: 10px;
  }
  .landing_page_search_menu_wrapper
    .landing_page_header_wrapper
    .landing_search_input_wrapper
    .landing_search_input_content
    .landing_search_input_field {
    outline: 0;
    border-width: 0 0 1px;
    width: 100%;
    padding-left: 2em;
    padding-right: 0em;
    background: transparent;
    font-size: 20px;
    margin-top: -15px;
    padding-bottom: 1em;
  }
  .search_icon {
    width: 68px;
  }
  .landing_page_wrapper
    .landing_page_text_wrapper
    .landing_page_button_wrapper {
    padding-top: 2em;
  }
  .landing_page_wrapper .landing_page_text_wrapper .landing_page_description {
    width: 80%;
    font-size: 13px;
    padding-top: 1em;
  }
  .landing_page_wrapper .landing_page_text_wrapper .landing_page_heading_text {
    padding: 0em;
    font-family: 'Merriweather';
    font-weight: 400;
    font-size: 23px;
  }
  .landing_page_wrapper
    .landing_page_text_wrapper
    .landing_page_button_wrapper
    .landing_page_button {
    width: 146px;
    height: 43px;
  }
  .landing_page_wrapper
    .landing_page_header_container
    .landing_page_header_wrapper {
    display: flex;
    width: 30%;
    justify-content: center;
    align-items: center;
    padding: 1em;
    z-index: 9999;
  }
  .landing_page_wrapper
    .landing_page_header_container
    .landing_page_header_wrapper
    .landing_page_icon_container {
    width: 50px;
    height: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    z-index: 9999;
    padding-right: 0.5em;
  }
  .landing_page_wrapper .landing_page_header_container {
    display: flex;
    justify-content: space-between;
    padding: 0em;
    z-index: 9999;
  }
  .landing_page_wrapper
    .landing_page_header_container
    .landing_page_header_wrapper
    .landing_page_icon_container
    img {
    width: 17px;
  }
  .landing_page_wrapper
    .landing_page_search_menu_wrapper
    .landing_page_header_wrapper
    .landing_search_input_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    top: 125px;
    width: 100%;
    height: 600px;
    z-index: 1000000000;
    background: #fff3fa;
  }
  .landing_page_wrapper
    .landing_page_search_menu_wrapper
    .landing_page_header_wrapper
    .landing_search_input_wrapper
    .landing_search_input_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 75%;
    height: 25%;
    margin-top: 1em;
  }
  .landing_page_wrapper .landing_page_search_menu_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff3fa;
    width: 100%;
    position: absolute;
    z-index: 10000000;
    height: 17%;
  }
  .landing_page_wrapper
    .landing_page_search_menu_wrapper
    .landing_page_header_wrapper
    .landing_header_menu_wrapper
    .landing_header_menu_content {
    width: 85%;
  }
  .global_search_button_wrapper {
    padding: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.launch_text{
  padding-top: 25px;
  font-weight: 700!important;
}
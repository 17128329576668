@import "../../index.scss";

.add_news_screen {
  .add_news_content {
    .add_news_head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1em;
      border-bottom: 1px solid $primaryColor;
      top: 0;

      .add_news_head_text {
        width: 60%;
      }
      
      .add_news_btn_container {
        width: 40%;
        display: flex;
        justify-content: flex-end;
        align-items:center;

        .add_news_select {
          width: 60%;
        }

        .add_news_btn {
          width: 100px;
          padding: 0 0 0 2em;
        }
      }
    }
  }
}
.diocese_view_header_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-top: 2em;
  .diocese_view_navigate_icon_wrapper {
    .diocese_view_navigate_icon {
      .navigate_icon {
        transform: rotate(90deg);
      }
    }
  }
}
.news_content {
  display: flex;
  flex-direction: column;
}
.input_wrapper {
  width: 99%;
  padding-left: 1em;
}
.add_news_component .add_news_container .add_news_wrapper {
  padding: 1em;
  width: 100%;
  height: auto!important;
}
@import "../../index.scss";

.csita_officer_screen{
  .csita_officer_container{
    .csita_officer_wrapper{
      .csita_officer_header_wrapper {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding-top: 2em;
        padding-bottom: 1em;

        .csita_officer_name_wrapper{
          display: flex;
          justify-content: center;
          align-items: center;
          color: $primaryTextColor;
          font-weight: 600;
          
          .csita_officer_nav_icon_wrapper{
            padding-right: 1em;
            padding-left: 0.5em;
            .csita_officer_nav_icon{
              transform: rotate(90deg);
            }
          }
        }

        .csita_officer_action_wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50%;

          .csita_officer_search_box_wrapper {
            width: 50%;
          }

          .csita_officer_button_wrapper {
            width: 180px;
            padding-right: 2em;
            padding-left: 2em;
          }
        }
      }
    }
  }
}
.csita_officer_button{
  width: 120px;
}
.csita_officer_customModal {
  background: $background;
  width: 50%;
  height: 400px;
  min-height: 475px;
  border-radius: 16px;
  overflow-x: auto;
  box-shadow: 0 10 28px 0px $primaryColor;
  padding: 0;

  .close_button{
    outline: none;
  }

  .csita_officer_modal_title_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1em;

    .csita_officer_modal_title {
      font-weight: bold;
      margin-left: 0.75em;
      color: $primaryTextColor;
    }
  }
  .csita_officer_modal_line_wrapper {
    width: 100%;
    height: 1px;
    background-color: $primaryColor;
  }
  .csita_officer_modal_text_area_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 2em;
    height: 300px;
  }

  .csita_officer_modal_input_wrapper::-webkit-scrollbar {
    display: none;
  }
  .csita_officer_button_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5em;
    cursor: pointer;

    .csita_officer_button{
      padding-top: 4em;
      width: 80px;
    }
  }
}

.csita_officer_customModal::-webkit-scrollbar {
  display: none;
}
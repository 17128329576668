@import '../../index.scss';

.magazine_main_container {
  width: 100%;
  // height: 500px;
  .magazine_background_image_container {
    background-image: url(../../assets/images/CSI_life.jpg);
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 2.5em;
    .magazine_main_wrapper {
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .heading_main_container {
        display: flex;
        justify-content: center;
        align-items: center;
        .magazine_heading_container {
          width: 65%;
          height: 10%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          .magazine_heading_wrapper {
            width: 60%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            margin-left: 1.06em;
            .magazine_heading {
              font-family: 'Merriweather';
              font-size: 25px;
              font-weight: 400;
              color: $subscribeButtonColor;
            }
            .underline_wrapper {
              width: 8%;
              height: 3px;
              background-color: $primaryColor;
            }
          }
        }
      }
      .magazine_container {
        width: 71%;
        display: flex;
        justify-content: center;
        align-items: center;
        .magazine_wrapper {
          width: 70%;
          height: 100%;
          display: flex;
          margin: 5em 1em 2em 1em;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          padding-left: 0.5em;
          .magazine_card {
            width: 200px;
            height: 300px;
            display: flex;
            outline: none;
          }
        }
      }

      @media only screen and (min-width: 1500px) {
        .heading_main_container {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 75%;
          .magazine_heading_container {
            width: 47%;
            height: 10%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .magazine_heading_wrapper {
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              margin-left: 0em;
              .magazine_heading {
                font-family: 'Merriweather';
                font-size: 32px;
                font-weight: 400;
                color: $subscribeButtonColor;
              }
              .underline_wrapper {
                width: 25%;
                height: 3px;
                background-color: $primaryColor;
              }
            }
            .magazine_viewarchives_wrapper {
              width: 83%;
              display: flex;
              justify-content: center;
              align-items: center;
              // margin-right: 0.75em;
              .viewarchives {
                color: $primaryColor;
                text-align: center;
                cursor: pointer;
                // margin-bottom: 0.63em;
                .arrow_image {
                  width: 18px;
                  height: 18px;
                  margin-left: 0.63em;
                  padding-top: 0.63em;
                }
              }
            }
          }
        }
        .magazine_container {
          width: 71%;
          display: flex;
          justify-content: center;
          align-items: center;
          .magazine_wrapper {
            width: 70%;
            height: 100%;
            display: flex;
            margin: 5em 1em 2em 1em;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            padding-left: 0.5em;
            .magazine_card {
              width: 283px;
              height: 373px;
              padding: 1em;
            }
          }
        }
      }
    }
    .magazine_main_container .magazine_viewarchives_wrapper {
      // width: 19%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      // margin: 0 322px;
      // padding-bottom: 2em;
    }
  }
  .magazine_viewarchives_wrapper {
    // width: 11%;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin: 0 557px;
    // padding-bottom: 2em;
    .viewarchives {
      color: $primaryColor;
      text-align: center;
      margin-bottom: 0.63em;
      .arrow_image {
        width: 20px;
        height: 20px;
        margin-left: 0.5em;
        padding-top: 0.63em;
      }
    }
  }
}
@media screen and (max-width: 1500px) {
  .magazine_main_container .magazine_viewarchives_wrapper {
    // width: 11%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // margin: 0 322px;
    // padding-bottom: 2em;
  }
  .magazine_main_container
    .magazine_background_image_container
    .magazine_main_wrapper
    .heading_main_container
    .magazine_heading_container
    .magazine_heading_wrapper
    .underline_wrapper {
    width: 25%;
    height: 3px;
    background-color: #b5316a;
  }
  .magazine_main_container
    .magazine_background_image_container
    .magazine_main_wrapper
    .heading_main_container
    .magazine_heading_container
    .magazine_viewarchives_wrapper {
    // width: 83%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // margin-left: 3.6em;
  }
  .magazine_main_container
    .magazine_background_image_container
    .magazine_main_wrapper
    .magazine_container {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .magazine_main_container
    .magazine_background_image_container
    .magazine_main_wrapper
    .magazine_container
    .magazine_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    margin: 5em 1em 2em 1em;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0.5em;
  }
  .magazine_main_container .magazine_background_image_container .magazine_main_wrapper .heading_main_container .magazine_heading_container {
    width: 90%;
    height: 10%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
}
// .arrow_container {
//   display: flex;
//   height: 30px;
//   align-self: center;
//   // padding: 10px;
//   .arrow_left {
//     transform: rotate(180deg);
//     width: 45px;
//     margin-right: 1.5em;
//   }
//   .arrow_right {
//     width: 45px;
//   }

// }
.magazine_viewarchives_wrapper {
  width: 85%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // margin-left: 3.6em;
  .viewarchives {
    color: $primaryColor;
    text-align: center;
    margin-bottom: 0.63em;
    cursor: pointer;
    margin-left: 0.88em;
    .arrow_image {
      width: 18px;
      height: 18px;
      margin-left: 0.63em;
      padding-top: 0.64em;
    }
  }
}
@media only screen and (max-width: 1050px) {
  //   .arrow_container {
  //     display: none;
  // }
  .heading_main_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    .magazine_heading_container {
      width: 100%;
      height: 10%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .magazine_heading_wrapper {
        width: 55%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-left: 1.06em;
        .magazine_heading {
          font-family: 'Chapaza';
          font-weight: 400;
          color: $subscribeButtonColor;
        }
        .underline_wrapper {
          width: 20%;
          height: 3px;
          background-color: $primaryColor;
        }
      }
    }
  }
  .magazine_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    .magazine_wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      margin-top: 1.88em;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      .cukhLH {
        overflow: hidden;
        position: relative;
        width: 100%;
        margin: 0 0;
      }
      .magazine_card {
        width: 240px;
        height: 360px;
        // margin-left: 2.5em;
        margin-top: 0.63em;
      }
      .arrow_container {
        .arrow_left {
          width: 20px;
        }
        .arrow_right {
          width: 20px;
        }
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .magazine_main_container
    .magazine_background_image_container
    .magazine_main_wrapper
    .magazine_container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .magazine_main_container
    .magazine_background_image_container
    .magazine_main_wrapper
    .heading_main_container
    .magazine_heading_container
    .magazine_heading_wrapper {
    width: 34%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 1.06em;
  }
  .magazine_main_container .magazine_viewarchives_wrapper {
    width: 20%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // margin: 0 312px;
    // padding-bottom: 2em;
  }
  .magazine_main_container
    .magazine_background_image_container
    .magazine_main_wrapper
    .heading_main_container
    .magazine_heading_container {
    width: 90%;
    height: 10%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .magazine_main_container
    .magazine_background_image_container
    .magazine_main_wrapper
    .heading_main_container
    .magazine_heading_container {
    width: 100%;
    height: 10%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media screen and(max-width: 600px) {
  .magazine_main_container .magazine_viewarchives_wrapper {
    width: 36%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // margin: 0px 126px;
    // padding-bottom: 2em;
  }
  .magazine_main_container
    .magazine_background_image_container
    .magazine_main_wrapper
    .heading_main_container
    .magazine_heading_container
    .magazine_heading_wrapper {
    width: 59%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 1.06em;
  }
}

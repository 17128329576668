@import '../../index.scss';
@keyframes slideInFromBottom {
  0% {
    transform: translateY(20%);
  }
  100% {
    transform: translate0(0);
  }
}
.no_events_title{
  padding-top: 0.8em;
}
.synod_video{
  font-size: 31px!important;
}
.home_screen {
  height: 100vh;
  overflow: auto;
  scroll-behavior: smooth;
  animation: 0.5s ease-out 0s 1 slideInFromBottom;
  background-color: $homeSiteBackground;
  .bcaJjD {
    overflow: hidden;
    position: relative;
    width: 100%;
    margin: 0px 10px;
}
  .home_container {
    .home_wrapper {
      width: 100%;
      .swiper_image {
        padding: 0.5em;
        width: 300px;
        height: 200px;
      }
      .synod_news_swiper {
        width: 300px;
        height: 250px;
        object-fit: cover;
      }
      .news_list_container {
        display: flex;
        padding-top: 1em;
        width: 100%;
        justify-content: space-between;
        .news_list_wrapper {
          width: 265px;
          height: 150px;
        }
      }

      .news_card_envent_list {
        display: flex;
        justify-content: center;
        .news_card_envent_list_container {
          max-width: 1170px;
          display: flex;
          .news_card_wrapper {
            width: 60%;
            .news_title {
              font-family: 'Merriweather';
              padding-bottom: 0.1em;
              display: inline;
              position: relative;
              border-width: 50px;
            }

            .news_title::after {
              content: '';
              display: block;
              position: absolute;
              bottom: 0;
              width: 50%;
              left: 0;
              border-bottom: 2px solid $primaryColor;
            }
            .news_content {
              padding-top: 2em;
              height: 412px !important;
              width: 100%;
            }
            .news_content_2 {
              .news_card_details {
                padding: 1.3em 0 0.5em 0;
                .news_card_image {
                  height: 500px;
                }
              }
            }
            .load_more_btn {
              padding: 2em;
              // margin-top: 47px;
              display: flex;
              width: 100%;
              justify-content: center;
              .btn_content {
                width: 30%;
              }
            }
          }
          .event_wrapper {
            width: 40%;
            padding: 0 0 0 1.5em;
            position: relative;
            .event_title {
              font-family: 'Merriweather';
              padding-bottom: 0.1em;
              display: inline;
              position: relative;
              border-width: 50px;
            }
            .event_title::after {
              content: '';
              display: block;
              position: absolute;
              bottom: 0;
              width: 50%;
              left: 0;
              border-bottom: 2px solid $primaryColor;
            }
            .event_content {
              padding-top: 1em;
              width: 100%;
              // height: 350px;
              .news_card_details {
                padding: 1em 0;
              }
            }
            .load_more_btn {
              // position: absolute;
              // bottom: 0;
              display: flex;
              width: 100%;
              padding: 1.5em;
              justify-content: center;
              .btn_content {
                width: 45%;
              }
            }
          }
        }
      }
      .home_csi_video {
        display: flex;
        max-width: 1170px;
        justify-content: center;
        padding: 3em 0;
        margin: auto;
        .video_content {
          display: flex;
          width: 100%;
          align-items: center;
          flex-direction: column;
          .home_csi_head_wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 88%;
            .home_csi_title {
              position: relative;
              padding-bottom: 0.3em;
              font-family: 'Merriweather';
              // font-size: 25px;
              font-weight: 400;
            }
            .home_csi_title::after {
              content: '';
              display: block;
              position: absolute;
              bottom: 0;
              width: 50%;
              left: 0;
              border-bottom: 2px solid $primaryColor;
            }
            .head_line {
              width: 58%;
              height: 2px;
              background-color: $border;
            }
            .csi_video_action {
              display: flex;
              width: 110px;
              justify-content: space-between;
              cursor: pointer;
              .arrow_icon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                padding-left: 7px;
              }
            }
          }
          .video_carousel {
            width: 80%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 4em 0 2em 0;
            .video_content_carousel {
              width: 100%;
            }
          }
          .publication_carousel {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 4em 0 2em 0;
            .publication_content_carousel {
              width: 100%;
              .publication_image {
                width: 180px;
                height: 250px;
              }
            }
          }
        }
      }
      .subscriber {
        display: flex;
        justify-content: center;
        max-width: 1170px;
        margin: 0 auto;
        .subscriber_home {
          display: flex;
          width: 100%;
          .subscriber_wrapper {
            width: 50%;
            padding: 1em;
          }
        }
      }
      .campaigns_and_ads {
        display: flex;
        justify-content: center;
        margin: 0 auto;
        max-width: 1170px;
        padding: 2em 0 2em 0;
        .content {
          // width: 70%;
          .campaigns_and_ads_head_wrapper {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding: 0 0 1em 0;
            .campaigns_home_wrapper {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 70%;
            }
            .campaigns_and_ads_title {
              position: relative;
              padding-bottom: 0.3em;
              font-size: 32px;
              font-weight: 400;
              font-family: 'Merriweather';
            }
            .campaigns_and_ads_title::after {
              content: '';
              display: block;
              position: absolute;
              bottom: 0;
              width: 7vw;
              left: 0;
              border-bottom: 2px solid $primaryColor;
            }
            .campaigns_and_ads_action {
              display: flex;
              width: 125px;
              justify-content: space-around;
              cursor: pointer;
              .arrow_icon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 24px;
              }
            }
            .campaigns_home_advertisement_wrapper {
              display: flex;
              justify-content: center;
              align-items: flex-end;
              width: 32%;
              height: 100%;
              .head_line {
                width: 65%;
                height: 2px;
                background-color: $primaryColor;
              }
              .advertisement_title {
                font-family: 'Merriweather';
                font-weight: 400;
              }
            }
          }
          .add_campaign_content {
            display: flex;
            width: 100%;
            padding-top: 2em;
            .home_campaigns_wrapper {
              width: 70%;
              .advertisement_button_wrapper {
                width: 18%;
                margin: auto;
                padding-top: 2.9em;
              }
              .news_card_2_campaign {
                padding: 0 0 1em 0;
              }
            }
            .add_wrapper {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center;
              width: 30%;
              height: 100%;
              margin: 0 0 1em 1em;
              .advertisement_platinum_swiper {
                width: 100%;
                height: 100%;
                .advertisement_platinum_swiper_image {
                  height: 430px;
                  object-fit: cover;
                  // padding-bottom: 3em;
                }
              }
              .advertisement_gold_swiper {
                width: 100%;
                height: 35%;
                .advertisement_gold_swiper_image {
                  height: 350px;
                  object-fit: cover;
                  padding-bottom: 3em;
                }
              }
              .advertisement_swiper_image {
                height: 450px;
                object-fit: cover;
                padding-bottom: 3em;
              }
              .advertisement_button_wrapper {
                padding-top: 1em;
                width: 157px;
              }
            }
          }
        }
      }
      .home_worship_container {
        padding: 1em 0 7em 0;
        width: 100%;
        height: 100%;
      }
      .home_footer {
        padding: 2em 0 0 0;
      }
    }
    .home_news_card_wrapper {
      width: 100%;
      height: 100%;
      padding-bottom: 0.3em;
      .csi_synod_news_swiper {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .filter_box {
      display: flex;
      justify-content: center;
      position: sticky;
      top: 50px;
      z-index: 110;
      padding: 0 0 2em 0;
    }
    .subscribeModal {
      background: $background;
      width: 100%;
      // height: 500px;
      // border-radius: 16px; 
      box-shadow: 0 10 28px 0px $primaryColor;
      // padding: 0;
      background: $primaryColor;
      position: relative;
      z-index: 0;
      margin: 0;
      padding: 1em;}
  }
  @media screen and (min-width: 1500px) {
    .home_container {
      .home_wrapper {
        .news_card_envent_list {
          .news_card_envent_list_container {
            .event_wrapper {
              .event_content {
                .news_card_details {
                }
              }
            }
            .news_card_wrapper {
              .news_content {
                height: 450px;
              }
            }
          }
        }
        .publication_carousel {
          .publication_content_carousel {
            .publication_image {
              width: 230px !important;
              height: 350px !important;
            }
          }
        }
        .campaigns_and_ads {
          .content {
            .add_campaign_content {
              .add_wrapper_wrapper {
                .advertisement_swiper_image {
                  width: 100%;
                  height: 465px;
                  object-fit: cover;
                }
              }
            }
          }
        }
      }
    }
  }

}
@media only screen and (max-width: 1500px) {
  .home_screen .home_container .home_wrapper .news_card_envent_list .news_card_envent_list_container .event_wrapper .load_more_btn {
    display: flex;
    width: 100%;
    padding: 1.5em;
    margin-top: 59px;
    justify-content: center;
}
  .home_container {
    .home_wrapper {
      .news_card_envent_list {
        .news_card_envent_list_container {
          .event_wrapper {
            .event_content {
              .news_card_details {
                height: 325px;
              }
            }
            .news_content {
              height: 300px;
            }
            .news_content_2 {
              .news_card_details {
                height: 420px;
              }
            }
          }
        }
      }
      .publication_carousel {
        .publication_content_carousel {
          .publication_image {
            width: 230px !important;
            height: 350px !important;
          }
        }
      }
      .campaigns_and_ads {
        .content {
          .add_campaign_content {
            .add_wrapper_wrapper {
              .advertisement_swiper_image {
                width: 100%;
                height: 345px;
                object-fit: cover;
              }
            }
          }
        }
      }
    }
  }
  .home_screen .home_container .home_wrapper .news_card_envent_list .news_card_envent_list_container .news_card_wrapper .load_more_btn {
    padding: 3em;
    margin-top: -10px;
    display: flex;
    width: 100%;
    justify-content: center;
}
  .home_screen
    .home_container
    .home_wrapper
    .campaigns_and_ads
    .content
    .add_campaign_content
    .add_wrapper
    .advertisement_button_wrapper {
    padding-top: 1em;
    width: 137px;
  }
  .home_screen .home_container .filter_box {
    display: flex;
    justify-content: center;
    position: sticky;
    top: 40px;
    z-index: 110;
    padding: 0 0 2em 0;
}
}

.video_link {
  text-decoration: none;
}
@media screen and (max-width: 1024px) {
  .home_screen .home_container .home_wrapper .news_card_envent_list .news_card_envent_list_container .event_wrapper .load_more_btn {
    display: flex;
    width: 100%;
    padding: 1.5em;
    margin-top: 59px;
    justify-content: center;
}
  .home_screen
    .home_container
    .home_wrapper
    .news_card_envent_list
    .news_card_envent_list_container {
    width: 90%;
    display: flex;
  }
  .home_screen .home_container .home_wrapper .news_card_envent_list .news_card_envent_list_container .event_wrapper .load_more_btn {
    display: flex;
    width: 100%;
    padding: 0em;
    justify-content: center;
}
.home_screen .home_container .home_wrapper .news_card_envent_list .news_card_envent_list_container .news_card_wrapper .load_more_btn {
  padding: 3em;
  // margin-top: 47px;
  display: flex;
  width: 100%;
  justify-content: center;
}
  .home_screen
    .home_container
    .home_wrapper
    .news_card_envent_list
    .news_card_envent_list_container
    .news_card_wrapper
    .news_content {
    padding-top: 2em;
    height: 317px !important;
  }
  .home_screen .home_container .home_wrapper .home_csi_video .video_content {
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;
  }
  .home_screen
    .home_container
    .home_wrapper
    .home_csi_video
    .video_content
    .home_csi_head_wrapper
    .home_csi_title {
    position: relative;
    padding-bottom: 0.3em;
    font-family: 'Merriweather';
    font-size: 20px!important;
  }
}
@media only screen and (max-width: 600px) {
  .home_screen::-webkit-scrollbar {
    display: none;
  }

  .subscribeModal {
    background: $background;
    width: 100%;
    // height: 500px;
    // border-radius: 16px;
    box-shadow: 0 10 28px 0px $primaryColor;
    // padding: 0;
    background: $primaryColor;
    position: relative;
    z-index: 0;
    margin: 0;
    padding: 1em;
    .subscribeModal::-webkit-scrollbar {
      display: none;
    }

    .subscribe_close_button {
      background: $subscribeButtonColor;
      position: absolute;
      top: 0px;
      right: 0px;
      border: none;
      padding: 0;
      cursor: pointer;
      display: flex;
      fill: white;
    }

    .subscription_close_button_wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 0;
      top: -1px;
      background: $subscribeButtonColor;
      height: 30px;
      width: 30px;
      z-index: 1;
    }

    .subscription_modal_container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 2em;
      .subscription_title_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;

        .subscription_title {
          color: $background;
        }

        .subscription_line_wrapper {
          margin-top: 1em;
          width: 20%;
          height: 2px;
          background: $background;
        }
      }
      .subscription_description_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 1em;
        .subscription_description {
          padding-top: 1em;
          color: $background;
          width: 410px;
          text-align: center;
        }
      }
      .subscription_input_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding-top: 3em;

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        .subscription_input {
          width: 80%;
          height: 40px;
          margin-bottom: 2em;
          outline: none;
          padding-left: 0.5em;
        }

        .subscription_modal_input {
          width: 100%;
          padding-top: 1.5em;
        }
      }
      .subscription_button_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1em;
        .subscription_button {
          background: $subscribeButtonColor;
          width: 170px;
        }
      }
    }
  }

  .advertisementModal {
    background: $background;
    border-radius: 10px;
    box-shadow: 0 10 28px 0px $primaryColor;
    overflow-y: auto;
    padding: 0;

    .advertisementModal::-webkit-scrollbar {
      display: none;
    }

    .advertisement_modal_container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      // padding-top: 2em;
      .advertisement_title_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        border-bottom: 2px solid $primaryColor;

        .advertisement_line_wrapper {
          width: 100%;
          height: 2px;
        }
      }
      .advertisement_input_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 80%;

        .advertisement_modal_input {
          width: 100%;
          padding-top: 1.5em;
        }
      }
      .advertisement_button_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 0em;
      }
      .advertisement_modal_image_wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .advertisement_banner {
          width: 100%;
          height: 100%;
          padding: 0.3em;
          border-radius: 10px;
        }
      }
      .advertisement_button_wrapper {
        position: absolute;
        bottom: 20px;
      }
    }
  }
  @media screen and (width: 1440px){
    .home_screen .home_container .home_wrapper .news_card_envent_list .news_card_envent_list_container .event_wrapper .load_more_btn {
      display: flex;
      width: 100%;
      padding: 1.5em;
      margin-top: 69px;
      justify-content: center;
  }
  }
  @media screen and (max-width: 1500px) {
    .home_screen .home_container .filter_box {
      display: flex;
      justify-content: center;
      position: sticky;
      top: 46px;
      z-index: 110;
      padding: 0 0 2em 0;
    }
    .home_screen .home_container .home_wrapper .news_card_envent_list .news_card_envent_list_container .news_card_wrapper .load_more_btn {
      padding: 3em;
      margin-top: 0;
      display: flex;
      width: 100%;
      justify-content: center;
  }
  }
  // .campaign_button_wrapper{
  //   width: 120px;
  //   margin: auto;
  // }
  // .button_wrapper_campaigns{
  //   display: flex;
  //   justify-content: space-around;
  //   width: 70%;
  //   margin: auto;
  // }
  // .campaign_btn_wrapper{
  //   width: 150px;
  // }
  // .campaign_btn_wrapper1{
  // width: 150px;
  // }
  @media screen and (max-width: 1500px) {
    .home_screen
      .home_container
      .home_wrapper
      .campaigns_and_ads
      .content
      .add_campaign_content
      .add_wrapper
      .advertisement_platinum_swiper
      .advertisement_platinum_swiper_image {
      height: 381px;
      object-fit: cover;
      padding-bottom: 3em;
    }
    .home_campaigns_wrapper .advertisement_button_wrapper {
      width: 18%;
      margin: auto;
      padding-top: 0em !important;
    }
    .home_screen .home_container .filter_box {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
      justify-content: center;
      position: -webkit-sticky;
      position: sticky;
      top: 44px;
      z-index: 110;
      padding: 0 0 2em;
  }
  }
  @media screen and (max-width: 1024px) {
    .home_screen .home_container .home_wrapper .news_card_envent_list .news_card_envent_list_container .event_wrapper .load_more_btn {
      display: flex;
      width: 100%;
      padding: 1.5em;
      margin-top: 59px;
      justify-content: center;
  }
  }

  .home_screen {
    .worship_wrapper {
      height: 1040px;
    }
    .home_container {
      .home_wrapper {
        .news_card_envent_list {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          .news_card_envent_list_container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 95%;
            .news_card_wrapper {
              width: 100%;
              .news_content {
              }
              .news_title {
              }
              .news_content_2 {
                padding-top: 1em;
                .news_card_details {
                  padding: 0 0 0.1em 0;
                }
              }
            }
            .event_wrapper {
              width: 100%;
              padding: 0;
              .event_content {
                height: 100%;
                padding-bottom: 0em;
              }
            }
          }
        }
        .home_csi_video {
          .video_content {
            width: 95%;
            .home_csi_head_wrapper {
              .head_line {
                display: none;
              }
              .csi_video_action {
                width: 100px;
                .text {
                  width: 100px;
                }
                .arrow_icon {
                  width: 20px;
                  padding-left: 0em;
                }
              }
            }
            .video_carousel {
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 1em 0 0 0;
              .video_content_carousel {
                width: 100%;
              }
            }
          }
        }
        .subscriber {
          .subscriber_home {
            width: 100%;
            display: flex;
            flex-direction: column;
            .subscriber_wrapper {
              width: 100%;
              padding: 0;
            }
          }
        }
        .campaigns_and_ads {
          .content {
            width: 95%;
            .campaigns_and_ads_head_wrapper {
              .campaigns_home_wrapper {
                width: 100%;
              }
              .campaigns_home_advertisement_wrapper {
                display: none;
              }
            }
            .add_campaign_content {
              flex-direction: column;
              width: 100%;
              .home_campaigns_wrapper {
                width: 100%;
              }
              .add_wrapper {
                width: 100%;
                margin: 0;
              }
            }
          }
        }
      }
    }
    .video_link {
      text-decoration: none;
    }
  }
  .home_screen
    .home_container
    .home_wrapper
    .news_card_envent_list
    .news_card_envent_list_container
    .news_card_wrapper
    .load_more_btn
    .btn_content {
    width: 45%;
  }
  
  .home_screen
    .home_container
    .home_wrapper
    .campaigns_and_ads
    .content
    .add_campaign_content
    .add_wrapper
    .advertisement_button_wrapper {
    padding: 0em;
  }

  .worship_resources_main_container
    .worship_resources_main_content_1_wrapper
    .worship_resources_main_content_1_content
    .worship_resources_resource_card_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 2em;
  }
  .home_screen
    .home_container
    .home_wrapper
    .news_card_envent_list
    .news_card_envent_list_container
    .news_card_wrapper
    .news_content_2
    .news_card_details {
    padding: 0 0 1.1em 0;
  }
  .bcaJjD {
    overflow: hidden;
    position: relative;
    width: 100%;
    margin: 0px 0px;
  }
}
@media screen and(max-width: 1024px) {
  .home_screen .home_container .home_wrapper .news_card_envent_list .news_card_envent_list_container .event_wrapper .load_more_btn {
    display: flex;
    width: 100%;
    padding: 1.5em;
    margin-top: -7px;
    justify-content: center;
}
  .home_screen
    .home_container
    .home_wrapper
    .news_card_envent_list
    .news_card_envent_list_container
    .event_wrapper
    .load_more_btn
    .btn_content {
    width: 62%;
  }.home_container .home_wrapper .publication_carousel .publication_content_carousel .publication_image {
    width: 212px !important;
    height: 310px !important;
}
  .home_screen
    .home_container
    .home_wrapper
    .news_card_envent_list
    .news_card_envent_list_container
    .news_card_wrapper
    .load_more_btn
    .btn_content {
    width: 35%;
  }
  .home_screen .home_container .home_wrapper .subscriber .subscriber_home {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .home_screen
    .home_container
    .home_wrapper
    .home_csi_video
    .video_content
    .home_csi_head_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 84%;
  }
  .home_screen
    .home_container
    .home_wrapper
    .home_csi_video
    .video_content
    .home_csi_head_wrapper
    .head_line {
    width: 53%;
    height: 2px;
    background-color: #bbbbbf;
  }
  .home_screen .home_container .home_wrapper .subscriber .subscriber_home {
    display: flex;
    flex-direction: column;
    width: 90%;
  }
  .home_screen
    .home_container
    .home_wrapper
    .subscriber
    .subscriber_home
    .subscriber_wrapper {
    width: 100%;
    padding: 1em;
  }
  .home_screen .home_container .home_wrapper .campaigns_and_ads .content {
    width: 86%;
  }
  .home_screen
    .home_container
    .home_wrapper
    .campaigns_and_ads
    .content
    .add_campaign_content
    .home_campaigns_wrapper
    .advertisement_button_wrapper {
    width: 110px;
    margin: auto;
    padding-top: 1.9em !important;
  }
  .home_screen
    .home_container
    .home_wrapper
    .news_card_envent_list
    .news_card_envent_list_container
    .news_card_wrapper
    .load_more_btn
    .btn_content {
    width: 38%;
  }
  .home_screen
    .home_container
    .home_wrapper
    .news_card_envent_list
    .news_card_envent_list_container
    .event_wrapper
    .load_more_btn
    .btn_content {
    width: 60%;
  }
  .home_screen
    .home_container
    .home_wrapper
    .campaigns_and_ads
    .content
    .campaigns_and_ads_head_wrapper
    .campaigns_and_ads_title {
    position: relative;
    padding-bottom: 0.3em;
    font-size: 20px;
    font-weight: 400;
    font-family: 'Merriweather';
  }
  .home_screen .home_container .filter_box {
    display: flex;
    justify-content: center;
    position: sticky;
    top: 45px;
    z-index: 110;
    padding: 0 0 2em 0;
  }
}
@media screen and (max-width: 600px) {
  .home_screen .home_container .filter_box {
    display: flex;
    justify-content: center;
    position: sticky;
    top: 36px;
    z-index: 110;
    padding: 0 0 2em 0;
  }
  .filter_component .filter_container .filter_wrapper {
    padding: 1em 0.5em;
  }
  .bcaJjD {
    overflow: hidden;
    position: relative;
    width: 100%;
    margin: 0px!important
}
.home_screen .home_container .home_wrapper .news_card_envent_list .news_card_envent_list_container .news_card_wrapper .load_more_btn .btn_content {
  width: 41%;
}
.global_search_button {
  width: 50%;
}
}
.subscribeModal {
  background: $background;
  width: 100%;
  // height: 500px;
  // border-radius: 16px; 
  box-shadow: 0 10 28px 0px $primaryColor;
  // padding: 0;
  background: $primaryColor;
  position: relative;
  z-index: 0;
  margin: 0;
  padding: 1em;
}
.subscribeModal .subscription_modal_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 2em;
}
.subscribeModal .subscription_modal_container .subscription_input_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 3em;
}
.subscribeModal .subscription_modal_container .subscription_input_wrapper .subscription_input {
  width: 80%;
  height: 40px;
  margin-bottom: 2em;
  outline: none;
  padding-left: 0.5em;
}
.subscribeModal .subscription_modal_container .subscription_button_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
}
.subscribeModal .subscription_modal_container .subscription_button_wrapper .subscription_button {
  background: #000000;
  width: 170px;
}
.bcaJjD {
  overflow: hidden;
  position: relative;
  width: 100%;
  margin: 0px 0px;
}
@import "../../index.scss";

.subscribe_container {
    display: flex;
    flex-direction: column;
    background-color: $primaryColor;
    width: 100%;
    height: 100%;

    .subscribe_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 3.75em;
        padding-top: 3.13em;
        padding-bottom: 3.13em;
        padding-right: 3.75em;
        .subscribe_heading_wrapper {
            display: flex;
            justify-content: center;
            align-items: center;

            .subscribe_heading {
                color: $background;
                font-weight: 600;
                font-family: "Merriweather";
            }
        }
        .subscribe_line_wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 0.5em;

            .subscribe_line {
                width: 48px;
                height: 2px;
                background-color: $background;
            }
        }
        .subscribe_description_wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 0.94em;
            .subscribe_description {
                // font-weight: 300;
                color: $background;
                text-align: center;
                // width: 40%;
            }
        }
        .subscribe_input_wrapper {
            display: flex;
            justify-content: center;
            margin-top: 1.5em;
            .subscribe_button {
                background-color: $subscribeButtonColor;
                color: $background;
                padding: 0.5em 1.5em 0.5em 1.5em;
                text-align: center;
                outline: none;
                cursor: pointer;
            }
        }
    }
    ::placeholder {
        font-weight: 300;
        color: $placeHolder;
        padding: 1.56em;
    }

    @media only screen and (min-width: 1500px) {
        .subscribe_wrapper {
            .subscribe_description_wrapper {
                .subscribe_description {
                    width: 60%;
                }
            }
        }
    }

    @media only screen and (max-width: 600px) {
        .subscribe_wrapper {
            padding: 3.5em 1.5em 3.5em 1.5em;
            .subscribe_line_wrapper {
                margin-top: 0.5em;
                .subscribe_line {
                    width: 40px;
                }
            }
            .subscribe_description_wrapper {
                .subscribe_description {
                    width: 75%;
                }
            }
            .subscribe_input_wrapper {
                .input[type="text"] {
                    width: 100%;
                    height: 40px;
                    padding: 10px;
                }
                .subscribe_button {
                    width: 35%;
                    height: 40px;
                    font-weight: 300;
                    outline: none;
                }
            }
        }
        ::placeholder {
            font-weight: 300;
            color: $placeHolder;
            padding: 0.1em;
        }
    }
}

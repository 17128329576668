@import "../../index.scss";
.login_screen {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .login_container {
    width: 100%;
    height: 100%;
    display: flex;

    .login_wrapper {
      width: 65%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .login_detail_container {
        padding: 2em;
        width: 70%;
        .login_head_container {
          padding: 0 0 2em 0;
          .login_intro {
            padding: 0 0 0.5em 0;
          }
          .login_desc {
            color: $loginDescription;
          }
        }
        .login_input_container {
          width: 100%;
          padding: 0 0 1em 0;
          .login_input_box {
            display: flex;
            .input_icon {
              width: 20px;
              padding: 0.5em 0 0 0;
            }
            .input {
              width: 85%;
              padding: 0 0 0 0.5em;
            }
          }
        }
        .login_btn_container {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          .login_btn {
            width: 40%;
          }
        }
      }

      .intro_content {
        width: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .intro_logo_container {
          padding: 1em;
        }
        .intro_head_container {
          .intro_head {
            color: $secondaryLightColor;
            font-family: 'Merriweather';
            font-weight: 400;
            font-size: 35px;
            padding: 0 0 0.5em 0;
          }
        }
        .intro_description_text {
          width: 50%;
          color: $secondaryLightColor;
          font-style: italic;
          // display: flex;
          // justify-content: flex-end;
          text-align: end;
          padding: 0 0 1em 0;
        }
        .intro_note {
          width: 50%;
          text-align: end;
          color: $secondaryLightColor;
        }
      }
    }
    
    .login_detail {
      width: 35%;
      background-color: $loginBackground;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
    }
  }
}
@import '../../index.scss';

.event_screen {
  width: 100%;
  height: 100%;
  overflow: auto;
  .event_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: $backgroundColor;
    .event_wrapper {
      width: 100%;
      .event_header_wrapper {
        width: 100%;
        background-color: chartreuse;
      }
      .event_navbar_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .event_title_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 5em;

        .event_title {
          font-weight: 400;
          font-family: 'Merriweather';
        }
        .event_line_wrapper {
          width: 150px;
          margin-top: 1em;
          height: 2px;
          background: $primaryColor;
        }
      }
      .event_content_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 2em;
        width: 100%;
        .event_content {
          width: 60%;
          .event_content_event_card {
            padding-bottom: 2em;
          }
        }
      }
      .event_footer_wrapper {
        padding-top: 5em;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .event_screen
    .event_container
    .event_wrapper
    .event_content_wrapper
    .event_content {
    width: 90%;
    padding: 2em;
  }
  .event_screen .event_container .event_wrapper .event_title_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 3em;
  }
}

@media screen and (max-width: 400px) {
  .event_screen
    .event_container
    .event_wrapper
    .event_content_wrapper
    .event_content {
    width: 100%;
    padding: 0.5em;
  }
  .event_screen .event_container .event_wrapper .event_title_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 3em;
  }
}

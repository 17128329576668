@import "../../../index.scss";
.title_card_container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  background-color: $secondaryLightColor;
  border-radius: 10px;
  padding: 1.5em;
  &:hover{
    cursor: pointer;
  }
  .title_wrapper {
    display: flex;
    flex: 3;
    align-items: center;

    .title_name {
      margin-left: 0.31em;
      font-weight: 500;
    }
  }
  .action_wrapper {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .edit_action {
      display: flex;
      width: 25px;
      height: 25px;
      justify-content: center;
      align-items: center;
      background-color: $primaryColor;
      border-radius: 50%;

      .action_edit_icon {
        width: 15px;
        height: 15px;
      }
    }
    .delete_action {
      display: flex;
      width: 25px;
      height: 25px;
      justify-content: center;
      align-items: center;
      background-color: $primaryColor;
      border-radius: 50%;

      .action_delete_icon {
        width: 15px;
        height: 15px;
      }
    }
  }
}

@import "../../index.scss";

.department_information_screen{
  background-color: $background;
  border-radius: 15px;
  box-shadow: 0px 0px 12px $secondaryLightColor;
  .department_information_container{
    .department_information_title_card_wrapper{
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      .department_information_title_card{
        width: 33%;
        padding: 1em;
      }
    }
  }
}

.department_information_modal {
  background: $background;
  width: 23%;
  max-height: 50vh;
  border-radius: 16px;
  box-shadow: 0 10 28px 0px $primaryColor;
  padding: 0;

  .department_information_modal_title_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1em;

    .department_information_modal_title {
      font-weight: bold;
      margin-left: 1em;
      color: $primaryTextColor;
    }
  }
  .department_information_modal_line_wrapper{
    width: 100%;
    height: 1px;
    background-color: $primaryColor;
  }
  .department_information_modal_input_wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    .department_information_modal_input{
      padding-top: 2em;
      width: 80%;
    }
  }
  .department_information_button_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
    .department_information_button{
      padding-top: 1em;
      width: 100px;
    }
  }
}
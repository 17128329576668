$primaryColor: #B5316A;
$primaryGradient: #8E033F;
$secondaryDarkColor: #F8EAF0;
$secondaryLightColor: #FFF3Fa;
$background: #FFFFFF;
$placeHolder: #B2A8AC;
$secondaryTextColor: #585871;
$primaryTextColor: #2E2E48;
$borderWithOpacity: rgba(182,45,106,0.1);
$backgroundColor: #FFF8FB;
$selectBoxColor: #FFEFF6;
$subscribeButtonColor: #000000;
$loginBackground: #FFF7FA;
$loginDescription: #717171;
$homeSiteBackground: #FDF5FA;
$dioceseBackground: #590006;
$border: #BBBBBF;
$videoBottom: #F9E1ED;
$videoTextColor: #1C1C28;

@font-face {
  font-family: "Raleway";
  color: $primaryTextColor !important;
  font-weight: 900;
  src: url(assets/fonts/static/Raleway-Black.ttf);
}

@font-face {
  font-family: "Raleway";
  color: $primaryTextColor !important;
  font-weight: 700;
  src: url(assets/fonts/static/Raleway-Bold.ttf);
}

@font-face {
  font-family: "Raleway";
  color: $primaryTextColor !important;
  font-weight: 800;
  src: url(assets/fonts/static/Raleway-ExtraBold.ttf);
}

@font-face {
  font-family: "Raleway";
  color: $primaryTextColor !important;
  font-weight: 500;
  src: url(assets/fonts/static/Raleway-Medium.ttf);
}

@font-face {
  font-family: "Raleway";
  color: $primaryTextColor !important;
  font-weight: 400;
  src: url(assets/fonts/static/Raleway-Regular.ttf);
}

@font-face {
  font-family: "Raleway";
  color: white !important;
  font-weight: 600;
  src: url(assets/fonts/static/Raleway-SemiBold.ttf);
}

@font-face {
  font-family: "Raleway";
  color: $primaryTextColor !important;
  font-weight: 200;
  src: url(assets/fonts/static/Raleway-ExtraLight.ttf);
}

@font-face {
  font-family: "Raleway";
  color: $primaryTextColor !important;
  font-weight: 300;
  src: url(assets/fonts/static/Raleway-Light.ttf);
}

@font-face {
  font-family: "Raleway";
  color: $primaryTextColor !important;
  font-weight: 100;
  src: url(assets/fonts/static/Raleway-Thin.ttf);
}

@font-face {
  font-family: "dingbat";
  color: $primaryTextColor !important;
  src: url(assets/fonts/static/GuifxV2Transports-YMJo.ttf);
}

@font-face {
  font-family: "Merriweather";
  color: $primaryTextColor !important;
  font-weight: 900;
  src: url(assets/fonts/Merriweather/Merriweather-Black.ttf);
}

@font-face {
  font-family: "Merriweather";
  color: $primaryTextColor !important;
  font-weight: 700;
  src: url(assets/fonts/Merriweather/Merriweather-Bold.ttf);
}

@font-face {
  font-family: "Merriweather";
  color: $primaryTextColor !important;
  font-weight: 500;
  src: url(assets/fonts/Merriweather/Merriweather-BlackItalic.ttf);
}

@font-face {
  font-family: "Merriweather";
  color: $primaryTextColor !important;
  font-weight: 400;
  src: url(assets/fonts/Merriweather/Merriweather-Regular.ttf);
}

@font-face {
  font-family: "Merriweather";
  color: $primaryTextColor !important;
  font-weight: 200;
  src: url(assets/fonts/Merriweather/Merriweather-Light.ttf);
}

@font-face {
  font-family: "Merriweather";
  color: $primaryTextColor !important;
  font-weight: 300;
  src: url(assets/fonts/Merriweather/Merriweather-Light.ttf);
}

@font-face {
  font-family: "Merriweather";
  color: $primaryTextColor !important;
  font-weight: 100;
  src: url(assets/fonts/static/Raleway-Thin.ttf);
}

body {
  margin: 0;
  font-size: 14px;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'lnum' 1;
  position: fixed;
  overflow: hidden;
}

.h1 {
  font-size: 40px;
  font-weight: 800;
}

.h2 {
  font-size: 32px;
  font-weight: 700;
}

.h3 {
  font-size: 22px;
  font-weight: 700;
}

.h4 {
  font-size: 18px;
  font-weight: 600;
}

.h5 {
  font-size: 16px;
  font-weight: 600;
}

.h6 {
  font-size: 14px;
  font-weight: 600;
}

.p1 {
  font-size: 40px;
}

.p2 {
  font-size: 32px;
}

.p3 {
  font-size: 22px;
}

.p4 {
  font-size: 18px;
}

.p5 {
  font-size: 16px;
}

.p6 {
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  box-sizing: border-box;
}

@media only screen and (min-width: 600px) {
  body {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1050px) {
  .p1 {
    font-size: 25px;
  }
  .p2 {
    font-size: 20px;
  }
  .p3 {
    font-size: 15px;
  }
  .p4 {
    font-size: 12px;
  }
  .h1 {
    font-size: 25px;
    font-weight: 800;
  }
  
  .h2 {
    font-size: 20px;
    font-weight: 700;
  }
  
  .h3 {
    font-size: 15px;
    font-weight: 700;
  }
  
  .h4 {
    font-size: 12px;
    font-weight: 600;
  }
}

@media only screen and (min-width: 1400px) {
  body {
    font-size: 16px;
  }
}
@import '../../index.scss';
.officer_card{
  height: 700px;
  padding-bottom: 3em;
}
.officer_screen {
  width: 100%;
  height: 100%;
  overflow: auto;
  .officer_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: $backgroundColor;
    .officer_wrapper {
      width: 100%;
      .officer_header_wrapper {
        width: 100%;
      }
      .officer_navbar_wrapper {
        width: 100%;
      }
      .officer_title_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 2em;

        .officer_title {
          font-weight: 400;
          font-family: 'Merriweather';
        }
        .officer_line_wrapper {
          width: 100px;
          margin-top: 0.5em;
          height: 2px;
          background: $primaryColor;
        }
      }
      .officer_nav_link_wrapper {
        display: flex;
        justify-content: center;
        padding: 0.5em 0;
        position: sticky;
        top: 25px;
        background: $backgroundColor;
        z-index: 1;
        .title_links_wrapper {
          display: flex;
          padding: 1rem;
          justify-content: center;
          position: relative;
          &:after {
            content: '';
            width: 100%;
            position: absolute;
            bottom: 0px;
            height: 5px;
            border-bottom: 3px solid $secondaryLightColor;
          }
          .link_1 {
            position: relative;
            font-weight: 400;
            .title_link {
              text-decoration: none;
              color: $subscribeButtonColor;
            }
          }
          .link {
            position: relative;
            font-weight: 400;
            padding-left: 2em;
            .title_link {
              text-decoration: none;
              color: $subscribeButtonColor;
            }
          }
        }
      }
      .officer_content_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 5em;
        width: 100%;
        .officer_content {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: flex-start;
          width: 65%;
          .officer_card {
            width: 50%;
            padding-bottom: 5em;
            .officers_card {
              width: 280px;
              height: 400px;
            }
          }
        }
        .officer_load_more_button_wrapper {
          padding-top: 3em;
          .officer_load_more_button {
            width: 150px;
          }
        }
      }
      .officer_footer_wrapper {
        padding-top: 5em;
      }
    }
  }
  .selected {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      height: 2px;
      width: 100%;
      background-color: $primaryColor;
      bottom: -16px;
      z-index: 100;
      left: 0;
    }
  }
}
@media screen and (max-width: 1024px) {
  .officer_screen
    .officer_container
    .officer_wrapper
    .officer_nav_link_wrapper
    .title_links_wrapper
    .h3 {
    // font-size: 20px;
  }
  .officer_screen
    .officer_container
    .officer_wrapper
    .officer_content_wrapper
    .officer_content {
    width: 75%;
  }
  .officer_screen .officer_container .officer_wrapper .officer_nav_link_wrapper {
    display: flex;
    justify-content: center;
    padding-top: 1em;
    position: sticky;
    top: 34px;
    background: #FFF8FB;
    z-index: 1;
}
}
@media screen and (max-width: 600px) {
  .officer_screen .officer_container .officer_wrapper .officer_title_wrapper {
    padding-top: 2em;
  }
  .officer_screen
    .officer_container
    .officer_wrapper
    .officer_nav_link_wrapper {
    padding-top: 1em;
    top: 10px;
  }
  .officer_screen
    .officer_container
    .officer_wrapper
    .officer_nav_link_wrapper
    .title_links_wrapper
    .h3 {
    font-size: 8px;
  }
  .officer_screen
    .officer_container
    .officer_wrapper
    .officer_nav_link_wrapper
    .title_links_wrapper
     {
    width: 100%;
  }
  .officer_screen
    .officer_container
    .officer_wrapper
    .officer_nav_link_wrapper
    .title_links_wrapper
    .link
     {
    padding-left: 2em;
  }
  .officer_screen .officer_container .officer_wrapper .officer_content_wrapper {
    padding-top: 3em;
  }
  .officer_screen
    .officer_container
    .officer_wrapper
    .officer_content_wrapper
    .officer_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .officer_screen
    .officer_container
    .officer_wrapper
    .officer_content_wrapper
    .officer_content
    .officer_card
    .officers_card {
    height: 100%;
    object-fit: cover;
  }
  .officer_screen .officer_container .officer_wrapper .officer_nav_link_wrapper .title_links_wrapper:after {
    content: '';
    width: 70%;
    position: absolute;
    bottom: 0px;
    height: 5px;
    border-bottom: 3px solid #FFF3Fa;
}
}

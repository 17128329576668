@import "../../index.scss";

.diocese_personnels_screen {
  background-color: $background;
  width: 100%;
  .diocese_personnels_container {
    width: 100%;
    .diocese_personnels_wrapper {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      .diocese_personnels_header_wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding-top: 2em;
        .diocese_personnels_navigate_icon_wrapper {
          .diocese_personnels_navigate_icon {
            .navigate_icon {
              transform: rotate(90deg);
            }
          }
        }
        .diocese_personnels_header_name_container {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          .diocese_personnels_header_name {
            padding-left: 1em;
            font-weight: 700;
          }
          .diocese_personnels_header_button_wrapper{
            padding-right: 2.5em;
          }
        }
      }
      .diocese_personnels_navbar_wrapper {
        width: 100%;
      }
      .diocese_personnels_table_wrapper{
        width: 100%;
      }
    }
  }
}

.personnel_modal_container {
  background: $background;
  width: 22%;
  height: 350px;
  border-radius: 16px;
  box-shadow: 0 10 28px 0px $primaryColor;
  padding: 0;

  .diocese_personnels_modal::-webkit-scrollbar {
    display: none;
  }

  .diocese_personnels_modal_title_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1em;
    padding-right: 15px;

    .diocese_personnels_modal_title {
      font-weight: bold;
      margin-left: 0.75em;
      color: $primaryTextColor;
    }
  }
  .diocese_personnels_modal_line_wrapper {
    width: 100%;
    height: 1px;
    background-color: $primaryColor;
  }
  .diocese_personnels_modal_input_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5em;
    .diocese_personnels_modal_input {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding-left: 2em;
      .diocese_personnels_thumbnail_wrapper {
        padding-top: 1.5em;
      }
      .diocese_personnels_modal_uploadbox{
        padding-top: 1em;
        width: 100%;
      }
    }
  }
  .diocese_personnels_button_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
  }
}
.uploaded_diocese_personnels{
  display: flex;
  justify-content: center;
  align-items: center;
}
.diocese_personnels{
  width: 300px;
height: 300px;
margin-top: 1em;
}
.upload_box{
margin-top: 1em;
}
.personnel_upload_box_wrapper{
  width: 75%;
  margin-top: 1em;
}
.modal_container {
    background: $background;
    width: 300px;
    max-height:80vh ;
    border-radius: 16px;
    overflow-y: auto;
    box-shadow: 0 10 28px 0px $primaryColor;
    padding: 0;
  
    .modal_container::-webkit-scrollbar {
      display: none;
    }
  .header_wrapper{
    background: $background;
    position: sticky;
    top: 0;
    z-index: 1
  }
    .modal_title_wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 1em;
  
      .modal_title {
        font-weight: bold;
        margin-left: 0.75em;
        color: $primaryTextColor;
      }
    }
    .modal_line_wrapper {
      width: 100%;
      height: 1px;
      background-color: $primaryColor;
    }
    .modal_input_wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 1em;
      // height: 500px;
      .modal_input {
        width: 75%;
      }
    }
    .csi_user_button_wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1em;
    }
  }

  .modal_container::-webkit-scrollbar {
    display: none;
  }
  .csi_life_modal_icon_wrapper{
position: absolute;
right: 10px;
top: 10px;
  }
  .csi_life_modal_icon{
    width: 30px;
    height: 30px;
  }
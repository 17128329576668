@import '../../index.scss';

.swiper_news_card_container {
  position: relative;
  display: flex;
  justify-content: space-between;
  font-family: 'Merriweather';
  align-items: center;
  width: 100%;

  .cukhLH {
    margin: 0 !important;
  }
  .swiper_arrow_wrapper {
    display: none;
    justify-content: center;
    align-items: center;
    .previous_arrow {
      position: absolute;
      width: 30px;
      height: 92%;
      left: 0;
      margin-left: 1em;
      transform: rotate(180deg);
      z-index: 1;
      opacity: 0.6;
      // outline: 100px solid rgba(61, 60, 60, 0.5) !important;
      // outline-offset: -100px;
    }
    .next_arrow {
      position: absolute;
      height: 92%;
      width: 30px;
      right: 0;
      margin-right: 1em;
      z-index: 1;
      opacity: 0.6;
      // outline: 100px solid #3d3c3c80 !important;
      // outline-offset: -100px;
    }
  }
  .news_card_small_component {
    // padding: 0.5em;
    outline: none;
    width: 100%;
    height: 100%;
    z-index: 0;

    .news_card_small_padding {
      padding: 0 0.2em 0.2em 0.2em !important;
    }
    .news_card_small_container {
      .news_card_small_wrapper {
        width: 100%;
        height: 100%;
        position: relative;

        .news_card_small_image_container {
          outline: none;
          width: 100%;
          height: 100%;
          position: relative;
          .news_card_small_image {
            position: relative;
            width: 100%;
            height: 250px;
            vertical-align: middle;
          }
          .synod_news_card_small_image {
            position: relative;
            width: 100%;
            height: 700px;
            vertical-align: middle;
            object-fit: cover;
            cursor: pointer;
          }
        }

        .news_card_small_overlay {
          // background: linear-gradient(
          //   180deg,
          //   rgba(27, 6, 15, 0.22) 0%,
          //   rgba(77, 17, 43, 0.22) 51%,
          //   rgba(16, 0, 7, 0.49) 60%,
          //   #100007 100%
          // );
          background: linear-gradient(
            180deg,
            rgba(27, 6, 15, 0.22) 0%,
            rgba(77, 17, 43, 0.22) 20%,
            rgba(16, 0, 7, 0.69) 60%,
            #100007 100%
          );
          position: absolute;
          bottom: 0;
          z-index: 100;
          width: 100%;
          height: 100%;
          opacity: 0.7;
        }
        .synod_news_card_small_overlay {
          // background: linear-gradient(
          //   180deg,
          //   rgba(94, 24, 54, 0.71) 100%,
          //   rgba(111, 24, 62, 0.89) 0%,
          //   rgb(181, 49, 106) 70%
          // );
          background: linear-gradient(
            180deg,
            rgba(27, 6, 15, 0.22) 0%,
            rgba(77, 17, 43, 0.22) 20%,
            rgba(16, 0, 7, 0.69) 60%,
            #100007 100%
          );
          position: absolute;
          bottom: 0;
          z-index: 1;
          width: 100%;
          height: 100%;
          opacity: 0.7;
        }
        .text_block_container {
          position: absolute;
          bottom: 3em;
          left: 3em;
          color: $background;
          z-index: 100;
          .date_wrapper {
            // font-size: 10px;
          }
          .text_wrapper {
            margin-top: 0.5em;
            .text {
              width: 250px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }
          }
        }
        .synod_news_text_block_container {
          position: absolute;
          bottom: 88px;
          left: 12em;
          color: #ffffff;
          z-index: 100;

          .synod_news_text_block_wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            .synod_news_date_wrapper {
              padding-bottom: 0.5em;
              font-weight: 400;
            }

            .synod_news_text_block_wrapper .synod_news_title .h1 {
              font-weight: 400;
              font-size: 32px !important;
              width: 500px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              // height: 140px;
              font-family: 'Merriweather';
              margin-bottom: 5px;
            }
            .synod_news_title h2 {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              width: 30%;
              -webkit-box-orient: vertical;
            }
            .synod_news_button_wrapper {
              display: flex;
              justify-content: center;
              align-items: center;
              transition: all 0.3s ease-in-out;
              background: $primaryColor;
              width: 200px;
              height: 50px;
              margin-top: 1em;
              cursor: pointer;
              .synod_news_button_title {
                font-weight: 400;
              }
            }
            .synod_news_button_wrapper:hover {
              background: $backgroundColor;
              transition: all 0.3s ease-in-out;
              .synod_news_button_title {
                font-weight: 400;
                color: $primaryColor;
              }
            }
          }
        }
      }
    }
  }

  .heading_synod {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    width: 70%;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  @media screen and (max-width: 1500px) {
    .swiper_news_card_container
      .news_card_small_component
      .news_card_small_container
      .news_card_small_wrapper
      .synod_news_text_block_container {
      position: absolute;
      bottom: 107px;
      left: 15em;
      color: #ffffff;
      z-index: 100;
    }
  }
  @media screen and (max-width: 1024px) {
    .swiper_news_card_container
      .news_card_small_component
      .news_card_small_container
      .news_card_small_wrapper
      .synod_news_text_block_container {
      position: absolute;
      bottom: 88px;
      left: 6em;
      color: #ffffff;
      z-index: 100;
    }
  }
  @media only screen and (max-width: 600px) {
    .swiper_arrow_wrapper {
      .previous_arrow {
        display: none;
      }
      .next_arrow {
        display: none;
      }
    }
    .news_card_small_component {
      .news_card_small_container {
        .news_card_small_wrapper {
          .news_card_small_image_container {
            .synod_news_card_small_image {
              height: 500px;
              width: 100%;
            }
            .news_card_small_image {
              height: 100px;
              width: 100%;
            }
          }
          .synod_news_text_block_container {
            position: absolute;
            bottom: 30px;
            left: 0;
            width: 100%;
            color: $background;
            z-index: 100;
            .synod_news_text_block_wrapper {
              display: flex;
              justify-content: center;
              width: 100%;
              align-items: center;
              text-align: center;
              .synod_news_date_wrapper {
                display: flex;
                justify-content: center; 
                align-items: center;
                width: 100%;
              }
              .synod_news_title {
                width: 300px;
                font-weight: 400;
              }
              .synod_news_button_wrapper {
                width: 130px;
                height: 40px;
                margin-top: 0;
                .synod_news_button_title {
                  font-size: 10px;
                }
              }
            }
          }
          .text_block_container {
            bottom: 10px;
            left: 18px;
            .text_block_wrapper {
              .date_wrapper {
                font-size: 10px;
              }
              .text_wrapper {
                .text {
                  width: 110px;
                  font-size: 10px;
                }
              }
              .synod_news_title {
                font-size: 10px;
              }
            }
          }
        }
      }
    }
  }
}
.rec rec-slider-containe {
  margin: 0;
}
.synod_news_title h1 {
  font-size: 32px !important;
}
.dept_name {
  padding-top: 1em;
}
.dept_head {
}

.arr_size {
  width: 50px !important;
}
.small_overlay {
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(27, 6, 15, 0) 0%,
    rgba(77, 17, 43, 0) 0%,
    rgba(16, 0, 7, 0.69) 100%,
    #0ffffe 100%
  );
  height: 100%;
  position: absolute;
  top: 0;
}

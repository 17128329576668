@import "../../index.scss";

.resource_card_container {
  width: 100%;
  height: 100%;
  .resource_card_image_container {
    width: 100%;
    height: 100%;
    position: relative;
    .resource_card_image {
      // background: linear-gradient(
      //   180deg,
      //   rgba(172, 59, 108, 0.71) 0%,
      //   rgba(111, 24, 62, 0.89) 55%,
      //   rgb(181, 49, 106) 100%
      // );
      width: 100%;
      height: 100%;
      // background-size: contain;
      object-fit: cover;
      opacity: 1;
      top: 0;
      z-index: 100;
    }
    .resource_card_text_container {
      .resource_card_text_wrapper {
        position: absolute;
        bottom: 0;
        width: 60%;
        z-index: 100;
      }
      .resource_card_text {
        font-family: "Raleway";
        font-weight: bold;
        padding-bottom: 3em;
        color: $background;
        margin-left: 1.25em;
        opacity: 0.8;
      }
      .resources_card_text {
        font-family: "Raleway";
        font-weight: bold;
        padding-bottom: 2em;
        color: $background;
        margin-left: 1.25em;
        opacity: 0.8;
      }
    }
    .view_more_container {
      position: absolute;
      bottom: 0px;
      left: -8px;
      width: 100%;
      z-index: 100;
      margin-left: 0.2em;
      display: flex;
      flex-direction: row;
      .view_more_text {
        font-size: 18px;
        font-family: "Raleway";
        font-weight: 500;
        color: $background;
        margin-left: 1.8em;
        padding-bottom: 2em;
        opacity: 0.5;
      }
      .arrow_image {
        width: 20px;
        height: 21px;
        margin-left: 0.3em;
      }
    }
    .resources_overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: 0;
      // background: linear-gradient(182.15deg,rgba(0, 0, 0, 0.48),rgba(0, 0, 0, 0.83) 200.42%,#333333);
      background: linear-gradient(180deg, rgba(27, 6, 15, 0) 0%, rgba(77, 17, 43, 0) 0%, rgba(16, 0, 7, 0.69) 100%, #0FFFFE 100%);
    }
  }
}
.resource_button_wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: absolute;
  top: 40%;
}
.resource_text_wrapper{
  width: 50%;
  background-color: $primaryColor;
  padding: 1em;
  color: white;
  opacity: 0;
  text-align: center;
}
@media screen and (max-width: 1024px) {
  .resource_card_container .resource_card_image_container .view_more_container {
    position: absolute;
    bottom: -17px;
    left: -15px;
    width: 100%;
    z-index: 100;
    margin-left: 0.2em;
    display: flex;
    flex-direction: row;
  }
}
@import '../../index.scss';

// .department_user_screen{
//   background-color: $background;
//   border-radius: 15px;
//   box-shadow: 0px 0px 12px $secondaryLightColor;
// }
.department_user_modal {
  background: $background;
  width: 300px;
  height: 490px;
  border-radius: 16px;
  box-shadow: 0 10 28px 0px $primaryColor;
  padding: 0;

  .department_user_modal_title_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1em;

    .department_user_modal_title {
      font-weight: bold;
      margin-left: 0.75em;
      color: $primaryTextColor;
    }
  }
  .department_user_modal_line_wrapper {
    width: 100%;
    height: 1px;
    background-color: $primaryColor;
  }
  .department_user_modal_input_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 1.5em;
    .department_user_modal_select_box {
      width: 65%;
      padding-top: 1.5em;
    }
    .department_user_modal_input {
      width: 75%;
    }
  }
  .department_user_button_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
  }
}
.department_user_modal::-webkit-scrollbar {
  display: none;
}

@import "../../index.scss";

.working_committee_screen {
  .working_committee_container {
    .working_committee_wrapper {
      .working_committee_header_wrapper {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding-top: 2em;
        padding-bottom: 1em;

        .working_committee_name_wrapper{
          color: $primaryTextColor;
          font-weight: 600;
          padding-left: 2em;
        }

        .working_committee_action_wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50%;

          .working_committee_search_box_wrapper {
            width: 50%;
          }

          .working_committee_button_wrapper {
            width: 205px;
            padding-right: 2em;
            padding-left: 2em;
          }
        }
      }
    }
  }
}
.working_committee_button{
  width: 170px;
}
.working_committee_customModal {
  background: $background;
  width: 25%;
  max-height: 90vh;
  border-radius: 16px;
  box-shadow: 0 10 28px 0px $primaryColor;
  padding: 0;

  .working_committee_modal_header_wrapper{
    position: sticky;
    top: 0;
    z-index: 1;
    background: $background;
    .working_committee_modal_title_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1em;
  
      .working_committee_modal_title {
        font-weight: bold;
        margin-left: 0.75em;
        color: $primaryTextColor;
      }
      .working_committee_modal_icon_wrapper{
        .working_committee_modal_icon{
          width: 30px;
          height: 30px;
          cursor: pointer;
        }
      }
    }
    .working_committee_modal_line_wrapper {
      width: 100%;
      height: 1px;
      background-color: $primaryColor;
    }
  }
  .working_committee_modal_input_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    .working_committee_modal_input {
      padding-top: 1em;
      width: 80%;
      .upload_box_wrapper{
        width: 100%;
        padding-top: 1.5em;
        padding-bottom: 1em;
      }
    }
    .working_committee_modal_select_input{
      padding: 1.5em 0;
      width: 80%;
      height: 100%;
    }
  }

  .working_committee_modal_input_wrapper::-webkit-scrollbar {
    display: none;
  }
  .working_committee_button_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5em;

    .working_committee_button{
      padding: 1em 0em;
      width: 80px;
    }
  }
}

.working_committee_customModal::-webkit-scrollbar{
  display: none;
}

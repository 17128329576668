@import "../../index.scss";

.diocese_information_screen{
  background-color: $background;
  border-radius: 15px;
  // box-shadow: 0px 0px 12px $secondaryLightColor;
  .diocese_information_container{
    .diocese_information_title_card_wrapper{
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      .diocese_information_title_card{
        width: 33%;
        padding: 1em;
      }
    }
  }
}

.diocese_information_modal {
  background: $background;
  width: 23%;
  height: 50vh;
  border-radius: 16px;
  box-shadow: 0 10 28px 0px $primaryColor;
  padding: 0;

  .diocese_information_modal_title_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1em;

    .diocese_information_modal_title {
      font-weight: bold;
      margin-left: 1em;
      color: $primaryTextColor;
    }
  }
  .diocese_information_modal_line_wrapper{
    width: 100%;
    height: 1px;
    background-color: $primaryColor;
  }
  .diocese_information_modal_input_wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .diocese_information_modal_input{
      width: 80%;
      padding-top: 2em;
    }
    .diocese_events_thumbnail_wrapper{
      position: relative;
      left: 0px;
    }
  }
  .diocese_information_modal_select_box_wrapper{
    display: flex;
    justify-content: center;
    .select_box_component {
      width: 80%;
      padding-top: 2em;
  }
  }
  .diocese_information_button_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
  }
  .diocese_information_button{
    width: 130px;
  }
}
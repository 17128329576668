@import '../../index.scss';

.news_card_3_component {
  width: 100%;
  font-family: 'Merriweather';
  .news_card_3_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    .news_card_3_wrapper {
      width: 100%;
      .news_card_3_media {
        width: 100%;
        height: 100%;
        padding: 1em 0.6em 0.6em 0;
        .news_card_3_img {
          width: 100%;
          height: 450px;
          object-fit: cover;
        }
        .news_card_3_img_border_right {
          border-right: 3px solid $primaryColor;
        }
        .news_card_3_img_border_left {
          border: 0;
          border-left: 3px solid $primaryColor;
        }
      }
      .news_card_3_content {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .news_card_3_head {
          padding: 0.5em 0;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
        }
        .news_card_3_time_stamp {
          // padding-bottom: 0.6em;
          font-weight: 400;
          // overflow: hidden;
          // text-overflow: ellipsis;
          // display: -webkit-box;
          // -webkit-line-clamp: 3;
          // -webkit-box-orient: vertical;
          font-family: 'Open Sans', sans-serif;
        }
        .news_card_3_text {
          width: 100%;
          // overflow: hidden;
          // text-overflow: ellipsis;
          // display: -webkit-box;
          // -webkit-line-clamp: 3;
          // -webkit-box-orient: vertical;
        }
        .news_card_3_view {
          display: flex;
          width: 100%;
          padding-top: 0.6em;
          cursor: pointer;
          .news_card_3_view_text {
            color: $primaryColor;
            font-weight: 400;
          }
          .arrow_icon {
            width: 30px;
            padding-left: 0.5em;
            .arrow_right_fill {
              width: 18px;
            }
          }
        }
      }
    }
    .wrapper_small {
      width: 100%;
    }
  }
  .news_card_3_flex_reverse {
    flex-direction: row-reverse;
  }
  .video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }

  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
}
@media screen and (max-width: 1500px) {
  .news_card_3_component
    .news_card_3_container
    .news_card_3_wrapper
    .news_card_3_media
    .news_card_3_img {
    width: 100%;
    height: 350px !important;
    object-fit: cover;
  }
}
@media screen and (max-width: 1024px) {
  .news_card_3_component
    .news_card_3_container
    .news_card_3_wrapper
    .news_card_3_media
    .news_card_3_img {
    height: 250px !important;
  }
}

@import '../../index.scss';

.news_card_1_container {
  width: 100%;
  height: 100%;
  font-family: 'Merriweather';
  .news_card_1_wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    .news_card_1_image_container {
      width: 100%;
      height: 100%;
      .news_card_1_image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .overlay_news_card {
      background: linear-gradient(
        180deg,
        rgba(27, 6, 15, 0.22) 0%,
        rgba(77, 17, 43, 0.22) 31%,
        rgba(16, 0, 7, 0.49) 58%,
        rgb(16, 0, 7) 100%
      );
      position: absolute;
      top: 0;
      z-index: 100;
      width: 100%;
      height: 100%;
      opacity: 0.7;
    }

    .overlay_event {
      background: linear-gradient(
        180deg,
        rgba(27, 6, 15, 0.22) 0%,
        rgba(77, 17, 43, 0.22) 31%,
        rgba(16, 0, 7, 0.79) 68%,
        rgb(16, 0, 7) 100%
      );
      position: absolute;
      top: 0;
      z-index: 100;
      width: 100%;
      height: 100%;
      opacity: 0.7;
    }

    .text_block_container {
      position: absolute;
      bottom: 0;
      width: 100%;
      z-index: 100;
      .text_block {
        color: $secondaryLightColor;
        padding: 2em;

        .text_block_head {
          font-family: 'Merriweather';
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          padding-bottom: 0.2em;
          font-size: 32px;
        }

        .text_block_date {
          padding-bottom: 0.4em;
          overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;
        }

        .text_block_btn_container {
          width: 130px;
          .text_block_btn {
            width: 100%;
            text-align: center;
            background-color: $primaryColor;
            padding: 0.8em;
            border: 1px solid transparent;
            cursor: pointer;
          }
        }
        .text_block_btn_container:hover {
          .text_block_btn {
            background-color: $secondaryDarkColor;
            border: 1px solid $primaryColor;
            color: $primaryColor;
          }
        }
      }
      .text_block_event_btn_container {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 1em 1em 1em 3.5em;
        z-index: 100;
        color: $secondaryLightColor;
        background-color: $primaryColor;
        display: flex;
        cursor: pointer;

        .text_block_btn_icon {
          padding-left: 0.4em;
          .right_arrow_icon {
            width: 18px;
          }
        }
      }
      .text_block_event_btn_container:hover {
        .text_block_btn_icon {
          transition: 0.3s ease-in;
          transform: translateX(30%);
        }
      }
    }
  }
  @media screen and (max-width: 1050px) {
    .news_card_1_wrapper {
      .text_block_container {
        .text_block {
          width: 100%;
          padding: 2.5em 2.5em 2em 2.5em;

          .text_block_btn_container {
            width: 95px;
            .text_block_btn {
              padding: 0.5em;
              cursor: none;
            }
          }
        }
        .text_block_responsive {
          padding: 2.5em 2.5em 3em 2.5em;
        }
        .text_block_event_btn_container {
          padding: 0.8em 1em 0.8em 2.6em;
          cursor: none;
        }
        .text_block_event_btn_container:hover {
          .text_block_btn_icon {
            transition: 0.3s ease-in;
            transform: translateX(30%);
          }
        }
      }
    }
  }
}

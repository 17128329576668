@import "../../index.scss";

.synod_video_component {
  width: 100%;
  display: flex;
  .synod_video_container {
    width: 100%;
    .carousel_synod_video {
      outline: none;
      border: none;

      .rec-carousel-item {
        border: none;
        outline: none;
      }
      .synod_video_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        .synod_video_doc {
          width: 100%;
          .synod_video_content {
            width: 853px;
            height: 480px;
          }
          .video-js {
            width: 100%;
            // height: 300px;
          }
        }
        .synod_video_description {
          width: 100%;
          background-color: $videoBottom;
          padding: 1em 1em 1em 1.2em;
          margin-top: -5px;
          outline: none;
          border: 0;
          color: $videoTextColor;

          .synod_video_date {
            padding: 0.6em 0 0 0;
          }
        }
      }
    }
  }
  @media only screen and (min-width: 1600px) {
    .synod_video_container {
      .carousel_synod_video {
        .synod_video_wrapper {
          .synod_video_doc {
            .synod_video_content {
              width: 100%;
              height: 700px;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1600px) {
    .synod_video_container {
      .carousel_synod_video {
        .synod_video_wrapper {
          .synod_video_doc {
            .synod_video_content {
              width: 100%;
              height: 580px;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1050px) {
    .synod_video_container {
      .carousel_synod_video {
        position: relative;
        // height: 280px;
        // height: 100px;
        .cukhLH {
          overflow: hidden;
          position: relative;
          width: 100%;
          margin: 0 0;
        }
        .arrow_container {
          .arrow_left {
            width: 30px;
            position: absolute;
            top: 100px;
            left: -35px;
            margin-right: 0em;
            z-index: 100000;
          }
          .arrow_right {
            width: 30px;
            position: absolute;
            top: 100px;
            right: -35px;
            margin-right: 0em;
          }
        }
        .synod_video_wrapper {
          .synod_video_doc {
            .synod_video_content {
              width: 100%;
              height: 192px;
            }
          }
          .synod_video_description{
            .synod_video_head{
              width: 310px;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
}
.arrow_container {
  display: flex;
  height: 30px;
  align-self: center;
  // padding: 10px;
  .arrow_left {
    transform: rotate(180deg);
    width: 45px;
  }
  .arrow_right {
    width: 45px;
  }
}
@media screen and (max-width: 600px) {
  .synod_video_component .synod_video_container .carousel_synod_video .arrow_container .arrow_right {
    width: 20px;
    position: absolute;
    top: 165px;
    right: 0px;
    margin-right: 0em;
}
.synod_video_component .synod_video_container .carousel_synod_video .arrow_container .arrow_left {
  width: 20px;
  position: absolute;
  top: 165px;
  left: 0px;
  margin-right: 0em;
}
}
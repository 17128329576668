@import '../../index.scss';
.publication_details_screen {
  width: 100%;
  height: 100%;
  overflow: auto;
  scroll-behavior: smooth;
  .publication_details_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: $backgroundColor;
    .publication_details_wrapper {
      width: 100%;
      .publication_details_header_wrapper {
        width: 100%;
      }
      .publication_details_navbar_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
      .publication_details_title_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 5em;

        .publication_details_title {
          font-weight: 400;
          font-family: 'Merriweather';
        }
        .publication_details_line_wrapper {
          width: 100px;
          margin-top: 0.5em;
          height: 2px;
          background: $primaryColor;
        }
      }
      .publication_details_content_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 7em;
        .publication_details_content {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          width: 60%;
          .publication_details_image_content_wrapper {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            .publication_details_image_wrapper {
              padding-top: 2em;
              width: 100%;
              .publication_details_image {
                width: 70px;
                height: 100px;
                object-fit: cover;
              }
            }
            .publication_details_thumbnail_wrapper {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              padding-left: 3em;
              .publication_details_thumbnail {
                padding: 1em;
                width: 400px;
                height: 550px;
                object-fit: cover;
              }
            }
          }
          .publication_details_publication_information_wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            width: 100%;
            padding-left: 3em;

            .publication_details_book_name {
              font-weight: 700;
            }
            .publication_details_book_author_name {
              padding-top: 0.5em;
              font-weight: 500;
            }
            .publication_details_book_description {
              padding-top: 2em;
              font-weight: 300;
            }
            .publication_details_input {
              width: 100%;
              margin-top: 1em;
              padding-bottom: 1em;
            }
          }
        }
      }
      .publication_details_footer_wrapper {
        padding-top: 5em;
      }
      .publication_details_explore_more_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .publication_details_explore_title_wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-top: 5em;
          .publication_details_explore_title {
          }
          .publication_details_line_wrapper {
            width: 100px;
            margin-top: 0.5em;
            height: 2px;
            background: $primaryColor;
          }
        }
        .publication_details_explore_more {
          width: 75%;
          .publication_details_image {
            background-color: $background;
            padding: 1.5em;
            margin: 2em;
            width: 250px;
            height: 300px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .publication_details_screen
    .publication_details_container
    .publication_details_wrapper
    .publication_details_content_wrapper
    .publication_details_content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 96%;
    padding: 1em;
  }
  .publication_details_screen
    .publication_details_container
    .publication_details_wrapper
    .publication_details_explore_more_wrapper
    .publication_details_explore_more {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .publication_details_screen
    .publication_details_container
    .publication_details_wrapper
    .publication_details_title_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 2em;
  }
  .publication_details_screen
    .publication_details_container
    .publication_details_wrapper
    .publication_details_content_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 2em;
  }
  .publication_details_screen
    .publication_details_container
    .publication_details_wrapper
    .publication_details_content_wrapper
    .publication_details_content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 96%;
    padding: 1em;
  }
  .publication_details_screen
    .publication_details_container
    .publication_details_wrapper
    .publication_details_content_wrapper
    .publication_details_content
    .publication_details_image_content_wrapper
    .publication_details_thumbnail_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0;
  }
  .publication_details_screen
    .publication_details_container
    .publication_details_wrapper
    .publication_details_content_wrapper
    .publication_details_content
    .publication_details_image_content_wrapper
    .publication_details_thumbnail_wrapper
    .publication_details_thumbnail {
    padding: 1em;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .publication_details_screen
    .publication_details_container
    .publication_details_wrapper
    .publication_details_content_wrapper
    .publication_details_content
    .publication_details_publication_information_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding-left: 0em;
  }
  .publication_details_container
    .publication_details_wrapper
    .publication_details_explore_more_wrapper
    .publication_details_explore_more {
    width: 100% !important;
  }
}
.swiper_container .swiper_image_wrapper {
  align-items: center;
  position: relative;
  flex-direction: column;
  display: flex;
  justify-content: center;
  outline: none;
  cursor: pointer;
}
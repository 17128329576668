@import '../../index.scss';

.screen {
  height: 100vh;
  overflow: auto;
  background-color: $backgroundColor;
  display: flex;
  flex-direction: column;
  align-items: center;
  .header_wrapper {
    width: 100%;
  }
  .error_text{
    width: 100%;
    text-align: center;
  }
  .lib_archives_screen_container {
    width: 1170px;
    .select_box_wrapper {
      display: flex;
      justify-content: flex-end;
      .select_box {
        width: 150px;
        padding: 1em;
      }
      .css-z7fgrc-control{
        background-color: $backgroundColor !important;
      }
      .css-1smg336-control{
        background-color: $backgroundColor !important;
      }
    }
    .lib_archives_screen_header_wrapper {
      display: flex;
      justify-content: center;
      padding-top: 2em;
      .lib_archives_screen_header {
        font-family: 'Merriweather';
        font-weight: 400;
        .header_line {
          height: 3px;
          width: 152.5px;
          background-color: $primaryColor;
          margin-top: 1rem;
          margin-left: auto;
          margin-bottom: 0em;
          margin-right: auto;
        }
      }
    }
    .lib_archives_screen_body_wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      .card_wrapper {
        width: 33%;
        padding: 2em 2em 0 2em;
      }
    }
  }
  .footer_wrapper {
    width: 100%;
  }
}

.library_archives_pagination{
padding: 3em 0;
}

@media only screen and (max-width: 1024px){
  .screen .lib_archives_screen_container {
    width: 100%;
}
.screen .lib_archives_screen_container .lib_archives_screen_body_wrapper .card_wrapper {
  width: 45%;
  padding: 1em;
}
}
@media only screen and (max-width: 600px){
  .screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    .header_wrapper {
      width: 100%;
    }
    .select_box_wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      .select_box {
        width: 150px;
        padding: 1em;
      }
        .css-z7fgrc-control{
          background-color: $backgroundColor !important;
        }
        .css-1smg336-control{
          background-color: $backgroundColor !important;
        }
    }
    .lib_archives_screen_header_wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 2em;
    }
    .lib_archives_screen_container {
      width: 87vw;
      .lib_archives_screen_body_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        .card_wrapper {
          width: 100%!important;
          padding-bottom: 2em;
        }
      }
    }
    .footer_wrapper {
      width: 100%;
    }
  }
}
.link_active{
  color: white!important;
}
.pagination_wrapper .active {
  background-color: #B5316A;
}
@import '../../index.scss';

.navbar_component {
  width: 100%;
  position: relative;
  background-color: $primaryColor;
  .navbar_container {
    display: flex;
    justify-content: center;
    position: relative;
    .navbar_wrapper {
      color: $background;
      display: flex;
      justify-content: space-between;
      width: 50%;
      .tab_link_container {
        text-align: center;
        .nav_link {
          text-decoration: none;
          color: $secondaryLightColor;
          .tab_name {
            padding: 1em;
            font-weight: 500;
            border-bottom: 2px solid $primaryColor;
          }
        }
        .active {
          .tab_name {
            border-bottom: 2px solid $background;
          }
        }
        .nav_link:hover {
          color: $secondaryLightColor;
          cursor: pointer;
          .tab_name {
            border-bottom: 2px solid $background;
          }
        }
        .nav_link &:hover .active {
          .tab_name {
            border-bottom: 2px solid $primaryColor;
          }
        }
      }
      .tab_hover {
        text-align: center;
        .nav_link {
          text-decoration: none;
          color: $secondaryDarkColor;
          .tab_name {
            padding: 1em;
            font-weight: 500;
          }
        }
        .active {
          .tab_name {
            border-bottom: 2px solid $primaryColor;
          }
        }
      }
      .tab_link_container.nav_link.active {
        border-bottom: 5px solid $background;
      }
      .border_bottom {
        .nav_link {
          .tab_name {
            border-bottom: 2px solid $background;
          }
        }
      }
    }
    .onhover_pop {
      position: absolute;
      // bottom: -28.6em;
      transition: all 5s ease-out;
      top: 3.3em;
      left: 0;
      z-index: 200;
      width: 100%;
      background-color: #842959;
    }
  }
  .mobile_navbar_container {
    display: none;
  }
  @media only screen and (max-width: 600px) {
    .navbar_container {
      display: none;
    }
    .nav_container_color {
      background: $secondaryLightColor;
    }
    .mobile_navbar_container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      // height: 40px;
      padding: 0.6em 1em;
      position: relative;
      .mobile_navbar_wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        .mobile_navbar_icon_container {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          width: 95%;
          .mobile_hamber_fill_icon {
            // display: ;
            padding-right: 1em;
          }
        }
      }
    }
    .mobile_navbar_content_wrapper {
      position: absolute;
      width: 100%;
      display: flex;
      flex-direction: row-reverse;
      top: 1px;
      z-index: 10000000000000000000;
      background-color: $primaryColor;
      // height: 295px;
      .mobile_navbar_content_close_icon_wrapper {
        // display: flex;
        // justify-content: flex-end;
        // align-items: flex-end;
        width: 50%;
        position: relative;
        padding-top: 2em;
        .mobile_navbar_content_close_icon {
          width: 30px;
          height: 30px;
          position: absolute;
          top: 14px;
          right: 34px;
          filter: invert(100%) sepia(0) saturate(7494%) hue-rotate(345deg)
            brightness(99%) contrast(105%);
        }
      }
      .mobile_navbar_content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        // height: 485px;
        padding: 1em 2em;
        width: 50%;
        .mobile_navbar_content_title {
          line-height: 2;
          color: $background;
          font-weight: 450;
          font-size: 15px;
        }
      }
    }
    .mobile_navbar_csi_synod_wrapper {
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1000000000;
      background: $primaryColor;
      display: flex;
      // height: 670px;
      flex-direction: column;
      justify-content: flex-start;
      // padding-top: 1em;
      .mobile_navbar_csi_synod_header_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        width: 100%;
      }
      .mobile_navbar_csi_synod_nav_icon_wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding-left: 1em;
        .csi_synod_nav_icon {
          width: 20px;
          height: 20px;
          transform: rotate(90deg);
          filter: invert(99%) sepia(56%) saturate(0%) hue-rotate(211deg)
            brightness(107%) contrast(100%);
        }
      }
      .mobile_navbar_csi_synod_title_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        color: $background;
        font-weight: 600;
      }
      .mobile_navbar_csi_synod_close_button {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        .csi_synod_close_icon {
          width: 30px;
          height: 30px;
          margin-right: 1em;
          filter: invert(100%) sepia(0%) saturate(7494%) hue-rotate(345deg)
            brightness(99%) contrast(105%);
        }
      }
      .mobile_navbar_csi_synod_content_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        // height: 511px;
        background-color: #842959;
        overflow-y: scroll;
        // padding-top: 2em;
        padding-left: 1.5em;
        padding-bottom: 1.5em;
        .mobile_navbar_csi_synod_content {
          padding-left: 1em;
          padding-top: 1em;
          font-size: 15px;
          display: flex;
          transition: all 0.3s ease-in-out;
          color: $background;
          font-weight: 450;
        }
        .mobile_navbar_csi_synod_sub_content {
          font-weight: 350;
          padding-left: 3em;
          font-size: 12px!important;
          padding-top: 1em;
          transition: all 0.3s ease-in-out;
          color: $background;
        }
        .mobile_navbar_csi_synod_sub_content {
          padding-left: 3em;
          color: $background;
          font-size: 12px!important;
          transition: all 0.3s ease-in-out;
        }
        .mobile_navbar_csi_synod_department_content {
          padding-left: 1em;
          padding-top: 1em;
          font-size: 12px!important;
          font-weight: 450;
          transition: all 0.3s ease-in-out;
          color: $background;
          font-size: 18px;
        }
        .mobile_navbar_csi_synod_resources_content {
          padding-left: 1em;
          padding-top: 1em;
          font-size: 12px!important;
          transition: all 0.3s ease-in-out;
          font-weight: 450;
          color: $background;
          font-size: 18px;
        }
        .mobile_navbar_csi_synod_others_content {
          padding-left: 1em;
          padding-top: 1em;
          font-size: 12px!important;
          transition: all 0.3s ease-in-out;
          font-weight: 450;
          color: $background;
          font-size: 18px;
        }
        .mobile_navbar_csi_synod_about_sub_content {
          font-weight: 350;
          padding-left: 3em;
          padding-top: 1em;
          font-size: 12px!important;
          transition: all 0.3s ease-in-out;
          color: $background;
        }
      }
    }
  }
  .mobile_search_wrapper {
    height: 700px;
    background-color: $secondaryLightColor;
    width: 100%;
    position: absolute;
    top: 48px;
    transition: all 0.3 s ease-in-out;
    padding: 2em;
  }
  .mobile_search_input_wrapper {
    width: 100%;
    height: 100%;
  }
  input {
    width: 100%;
    background: none;
    border: none;
    height: 35px;
    border-bottom: 1px solid black;
    color: black;
  }
  input:focus {
    outline: none;
  }
  .mobile_search_text {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .search_text {
    color: black;
    font-weight: 300;
    padding-bottom: 2em;
  }
  .mobile_hamber_search_fill_close_icon {
    width: 25px;
    height: 35px;
    // filter: invert(100%) sepia(0) saturate(7494%) hue-rotate(345deg)
    //         brightness(99%) contrast(105%);
  }
  .mobile_search_content {
    height: 610px;
    overflow: scroll;
    z-index: 99999;
  }
  .news_card_2_component
    .news_card_2_container
    .news_card_2_wrapper
    .news_card_2_media
    .news_card_2_img {
    width: 100%;
    height: 190px;
    object-fit: cover;
  }
  .no_news_text {
    font-weight: 400;
  }
}
.border_bottom .nav_link .tab_name {
  border-bottom: 2px solid $background;
  z-index: 9999;
}
.nav_drop_expand{
  width: 100%;
}
.nav_dropdown{
  width: 20px;
  filter: brightness(0) invert(1);;
  padding-left: 0.5em;
  transition: transform 0.3s ease-in-out;
}
.nav_dropdown_active{
  width: 30px;
  transform: rotate(180deg);
  padding-right: 0.5em;
  transition: transform 0.3s ease-in-out;
}
.search_button{
  width: 160px;
}
@media screen and (max-width: 1024px) {
  .navbar_component .navbar_container .navbar_wrapper {
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    width: 80%;
  }
}

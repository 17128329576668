@import '../../index.scss';

.officer_card_container {
  .officer_card_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .officer_image_wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
    }
    .sec_staff_wrapper{
      text-align: center;
      align-items: center!important;
    }
    .officer_personal_information_wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 1em 1em 1em 0;
      width: 282px;
      height: 208px;
      .officer_name {
      }
      .officers_proffession {
        padding-top: 0.7em;
        font-family: 'Open Sans', sans-serif;
        width: 270px;
        font-weight: 400;
      }
      .officers_location {
        padding-top: 0.5em;
        font-family: 'Open Sans', sans-serif;
        width: 270px;
        line-height: 20px;
        font-weight: 400;
      }
      .officers_mobile {
        font-family: 'Open Sans', sans-serif;
        padding-top: 0.5em;
        font-weight: 400;
      }
      .officers_fax {
        font-family: 'Open Sans', sans-serif;
        padding-top: 0.5em;
        width: 270px;
        font-weight: 400;
      }
      .officers_email {
        padding-top: 0.5em;
        font-weight: 400;
        font-family: 'Open Sans', sans-serif;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .officer_name.h4 {
    font-size: 17px;
    font-weight: 600;
  }
  .officer_card_container
    .officer_card_wrapper
    .officer_personal_information_wrapper
    .officers_proffession {
    font-size: 12px;
  }
  .officer_card_container
    .officer_card_wrapper
    .officer_personal_information_wrapper
    .officers_location {
    font-size: 12px;
  }
  .officer_card_container
    .officer_card_wrapper
    .officer_personal_information_wrapper
    .officers_mobile {
    font-size: 12px;
  }
  .officer_screen .officer_container .officer_wrapper .officer_footer_wrapper {
    padding-top: 1em;
}
}


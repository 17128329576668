@import '../../index.scss';

.aim_screen {
  height: 100vh;
  width: 100%;
  overflow: auto;
  background-color: $backgroundColor;
  display: flex;
  flex-direction: column;
  align-items: center;
  .header_wrapper {
    width: 100%;
  }
  .footer_wrapper {
    width: 100%;
  }
  .aim_screen_container {
    max-width: 1170px;
    .aim_screen_header_wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 2em;
      .aim_screen_header {
        height: 44px;
        font-family: 'Merriweather';
        font-weight: 400;
      }
      .header_line {
        height: 3px;
        width: 170px;
        background-color: $primaryColor;
        margin-top: 1em;
      }
    }
    .aim_screen_title_wrapper {
      margin-top: 1em;
      .aim_links_wrapper {
        display: flex;
        padding: 1rem;
        justify-content: center;
        position: relative;
        &:after {
          content: '';
          width: 100%;
          position: absolute;
          bottom: -2px;
          height: 2px;
          border-bottom: 3px solid $secondaryLightColor;
        }
        .aim_link_1 {
          padding-right: 2em;
          position: relative;
          font-weight: 400;
          .title_link {
            text-decoration: none;
            color: $subscribeButtonColor;
          }
        }
        .aim_link {
          position: relative;
          font-weight: 400;
          .title_link {
            text-decoration: none;
            color: $subscribeButtonColor;
          }
        }
      }
    }
    .aim_body_wrapper {
      width: 100%;
      font-family: 'Open Sans', sans-serif;
      margin-top: 5em;
      ul li {
        padding: 1rem 1rem 1rem 0;
      }

      ul {
        padding-left: 1rem;
        li::marker {
          color: $primaryColor;
        }
      }
      .aim_section_left {
        width: 100%;
        h1 {
          font-family: 'Merriweather';
          font-weight: 300;
          position: relative;
          display: flex;
          justify-content: flex-start;
        }
        h1:after {
          content: '';
          position: absolute;
          width: 10%;
          left: 0;
          height: 3px;
          background-color: $primaryColor;
          bottom: 0;
        }
        .ql-align-center img {
          height: 300px;
          width: 250px;
          object-fit: contain;
        }
        .left_title_wrapper {
          margin-top: 2rem;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .vision {
            width: 70px;
          }
          .aim_and_obj {
            width: 110px;
          }
          .aim_and_activities {
            width: 170px;
          }
          .director {
            width: 70px;
          }
          .personnel {
            width: 70px;
          }
          .left_heading {
            font-family: 'Merriweather';
            font-weight: 400;
          }
        }
        .director_section_wrapper {
          .director_content_wrapper {
            display: flex;
            flex-direction: column;
            margin-top: 2em;
            .director_image_wrapper {
              img {
                height: 400px;
                width: 300px;
                object-fit: cover;
              }
            }
            .director_text_wrapper {
              .name {
                padding: 0 0 1rem 0;
                font-weight: 500;
              }
              .description_mobile {
                padding: 0 0 1rem 0;
                font-weight: 300;
              }
              .description_email {
                padding: 0 0 1rem 0;
                font-weight: 300;
              }
              .description_address{
                font-weight: 300;
                padding: 0 0 1rem 0;
              }
            }
          }
        }
        .personnel_wrapper {
          // margin-top: 10em;
          margin-bottom: 3em;
          .personnel_image_wrapper {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            .personnel_image {
              display: flex;
              flex-direction: column;
              align-items: center;
              padding-right: 2em;
              margin-top: 2rem;
              img {
                width: 300px;
                height: 400px;
                object-fit: cover;
              }
              .personnel_name {
                padding-top: 1em;
                font-weight: 400;
              }
            }
          }
        }
      }
    }
  }
}
.department_card{
  height: 300px;
}
.personnel_image_wrapper .officer_card_container{
  height: 550px;
  padding: 0.3em;
}
.aim_screen_title {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 1024px) {
  .aim_screen .aim_screen_container .aim_screen_title_wrapper {
    margin-top: 0em;
  }
  .aim_screen .aim_screen_container .aim_screen_header_wrapper .aim_screen_header {
    height: 19px;
    font-family: 'Merriweather';
    font-weight: 400;
}
.aim_screen .aim_screen_container .aim_screen_header_wrapper .header_line {
  height: 3px;
  width: 150px;
  background-color: #B5316A;
  margin-top: 1em;
}
.aim_screen .aim_screen_container .aim_body_wrapper .aim_section_left {
  width: 100%;
  padding: 2em;
}
}
@media screen and (max-width: 600px) {
  .aim_screen .aim_screen_container {
    max-width: 100%;
  }
  .aim_screen
    .aim_screen_container
    .aim_screen_title_wrapper
    .aim_links_wrapper
    .aim_link {
    position: relative;
    font-weight: 400;
    font-size: 10px;
  }
  .aim_screen
    .aim_screen_container
    .aim_screen_title_wrapper
    .aim_links_wrapper:after {
    content: '';
    width: 88%;
    position: absolute;
    bottom: -2px;
    height: 2px;
    border-bottom: 3px solid #fff3fa;
  }
  .aim_screen
    .aim_screen_container
    .aim_screen_header_wrapper
    .aim_screen_header {
    height: 20px;
    font-family: 'Merriweather';
    font-weight: 400;
    font-size: 17px;
  }

  .aim_screen .aim_screen_container .aim_screen_title_wrapper {
    margin-top: 0em;
    width: 100%;
  }
  .aim_screen
    .aim_screen_container
    .aim_screen_title_wrapper
    .aim_links_wrapper
    .aim_link_1 {
    padding-right: 2em;
    position: relative;
    font-weight: 400;
    font-size: 10px;
  }
  .aim_screen .aim_screen_container .aim_body_wrapper {
    padding: 2em;
    width: 100%;
    margin-top: 0em;
  }
  .aim_screen
    .aim_screen_container
    .aim_body_wrapper
    .aim_section_left
    .left_title_wrapper {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
.dept_card_wrapper{
  // width: 450px;
  display: flex;
  justify-content: flex-start;
}

.department_card{
  width: 292px!important;
  height: 350px!important;
  object-fit: cover;
}
.aim_screen .officer_card_container {
  height: 650px;
}
.aim_screen .aim_screen_container .aim_body_wrapper .aim_section_left .left_title_wrapper .director {
  width: 70px;
  margin-bottom: 0em;
}
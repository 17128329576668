@import "../../../index.scss";

.select_box_component {
  width: 100%;
  .select_box_container {
    .select_box_wrapper {
      .select_box_content {
        display: flex;
        flex-direction: column-reverse;
        .select_box:hover ~ .select_box_label {
          color: $primaryColor;
          top: -1.5rem;
          -webkit-transition: all 0.125s ease;
          transition: all 0.125s ease;
        }
      }
    }
  }
}

@import '../../index.scss';

.add_campaign_screen {
  .add_campaign_container {
    .add_campaign_wrapper {
      .add_campaign_head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1em;
        border-bottom: 1px solid $primaryColor;
        top: 0;
        .diocese_view_header_wrapper {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          padding-top: 2em;
          .diocese_view_navigate_icon_wrapper {
            .diocese_view_navigate_icon {
              .navigate_icon {
                transform: rotate(90deg);
              }
            }
          }
        }
        .add_campaign_head_text {
          width: 60%;
        }

        .add_campaign_btn_container {
          width: 40%;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .add_campaign_select {
            width: 60%;
          }

          .add_campaign_btn {
            width: 100px;
            padding: 0 0 0 2em;
          }
        }
      }
    }
  }
}

.news_content {
  display: flex;
  flex-direction: column;
}
.input_wrapper {
  width: 72%;
  padding-left: 1em;
}

@import '../../index.scss';
.screen {
  height: 100vh;
  overflow: auto;
  background-color: $backgroundColor;
  display: flex;
  flex-direction: column;
  align-items: center;
  scroll-behavior: smooth;
  .file_link{
    text-decoration: none;
    color: black;
    font-weight: 600;
  }
  .header_wrapper {
    width: 100%;
  }
  .header_image_wrapper {
    width: 100%;
    height: 550px;
    .image_wrapper {
      width: 100%;
      height: 100%;
      .header_image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .body_wrapper {
    max-width: 1170px;
    .header_content_wrapper {
      background-color: $primaryColor;
      // height: 200px;
      margin-top: -60px;
      .header_content {
        padding: 3em 10em 3em 4em;
        color: $background;
        .header_title {
          font-weight: 600;
          margin-bottom: 0.5em;
        }
        .header_title_2 {
          font-weight: 300;
        }
      }
    }
    .news_header_description_wrapper {
      padding: 1em 4em;
      line-height: 25px;
      display: flex;
      font-family: 'Open Sans', sans-serif;
      flex-direction: column;
      h1 {
        position: relative;
        padding-bottom: 0.5em;
      }
      h1::after {
        content: '';
        border-bottom: 2px solid $primaryColor;
        position: absolute;
        height: 3px;
        width: 70%;
        bottom: 0;
        left: 0;
      }
      p {
        font-size: 16px;
      }

      img {
        max-width: 500px;
        max-height: 500px;
        display: flex;
        margin: 0 auto;
        // object-fit: contain;
      }
      .header_description {
      }
      .news_description_image_wrapper {
        display: flex;
        padding: 3em;
        justify-content: center;
        .news_image_wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 3em;
          img {
            width: 500px;
            height: 600px;
            object-fit: cover;
          }
          .image_description_text {
            padding-top: 2em;
            font-weight: 300;
          }
        }
      }
      .news_testimonial_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 3em;
        .testimonial_title {
          font-family: 'Merriweather';
          font-weight: 500;
        }
        .testimonial_author {
          padding-top: 2em;
        }
      }
    }
    .news_gallery_wrapper {
      padding-top: 4em;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 4em;
      .news_gallery_swiper {
        width: 100%;
        .swiper_image {
          padding: 1em;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .heading {
        font-family: 'Merriweather';
        font-weight: 400;
      }
      .head_line {
        width: 100px;
        height: 3px;
        background-color: $primaryColor;
        margin: 1em auto;
      }
    }
  }
}
.news_campaigns_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4em;
  background-color: $secondaryLightColor;
  width: 100%;
  position: relative;
  margin-bottom: 13em;
  .news_campaign_wrapper2 {
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .news_campaign_swiper_wrapper {
      padding: 4em;
      margin-top: 8em;
      position: absolute;
      width: 100%;
    }
    .news_swiper_image {
      height: 350px;
      width: 400px;
      object-fit: cover;
    }
    .heading {
      font-family: 'Merriweather';
      font-weight: 400;
    }
    .head_line {
      width: 200px;
      height: 3px;
      background-color: $primaryColor;
      margin: 1em auto;
    }
  }
}
.news_image_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  .description_image_wrapper {
    display: flex;
    padding: 3em;
    justify-content: center;
    .image_wrapper {
      display: flex;
      padding: 1em;
      flex-direction: column;
      align-items: center;
      img {
        width: 500px;
        height: 600px;
        object-fit: cover;
      }
      .image_description_text {
        padding-top: 2em;
        font-weight: 300;
      }
    }
  }
}
.synod_btn_wrapper {
  padding-bottom: 1em;
}
.related_news_content {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 1500px) {
  .news_campaigns_wrapper {
    .news_campaign_wrapper2 {
      .news_campaign_swiper_wrapper {
        width: 100%;
      }
    }
  }
  .news_swiper_image {
    height: 350px;
    width: 350px !important;
  }
}
@media screen and (max-width: 1024px) {
  .screen .body_wrapper .header_content_wrapper {
    height: 150px;
    display: flex;
    flex-direction: column;
  }
  .screen .body_wrapper .header_content_wrapper .header_content {
    padding: 3em;
  }
  .screen .body_wrapper .news_header_description_wrapper {
    padding: 2em;
  }
  .screen .body_wrapper .news_header_description_wrapper img {
    width: 50%;
    height: 400px;
    object-fit: cover;
    padding-right: 2em;
    padding-top: 1em;
  }
}
@media screen and (max-width: 600px) {
  .ql-video {
    width: 300px!important;
    height: 300px!important;
    object-fit: contain;
  }
  .screen {
    .body_wrapper {
      width: 100%;
      .header_content_wrapper {
        background-color: $primaryColor;
        height: 135px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        .header_content {
          color: $background;
          padding: 1em;
          .header_title {
            font-weight: 600;
            margin-bottom: 0.5em;
            font-size: 20px;
          }
        }
      }
      .news_header_description_wrapper {
        padding: 1.5em;
        h1 {
          position: relative;
          font-size: 19px;
          padding-bottom: 0.5em;
        }
        h1::after {
          width: 80%;
        }
        p {
          font-size: 16px;
        }
        ::marker {
          font-size: 30px;
          color: $primaryColor;
        }

        img {
          width: 50%;
          height: 230px;
          object-fit: cover;
          padding-right: 1em;
        }
        .news_description_image_wrapper {
          display: flex;
          padding: 3em;
          justify-content: center;
          .news_image_wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 3em;
            img {
              width: 100%;
              height: auto;
              object-fit: cover;
            }
            .image_description_text {
              padding-top: 2em;
              font-weight: 300;
            }
          }
        }
        .news_testimonial_wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 3em;
          .testimonial_title {
            font-family: 'Merriweather';
            font-weight: 500;
          }
          .testimonial_author {
            padding-top: 2em;
          }
        }
      }
      .news_gallery_wrapper {
        padding-top: 2em;
        margin-bottom: 2em;
        .news_gallery_swiper {
          width: 100%;
          .swiper_image {
            padding: 1em;
            width: 300px;
            height: 250px;
          }
        }
        .heading {
          font-family: 'Merriweather';
          font-weight: 400;
        }
        .head_line {
          width: 100px;
          height: 3px;
          background-color: $primaryColor;
          margin: 1em auto;
        }
      }
    }
  }
  .news_campaigns_wrapper .news_campaign_wrapper2 .news_swiper_image {
    height: 252px;
    width: 280px !important;
  }
  .news_campaigns_wrapper {
    margin-bottom: 2em;
  }
  .news_campaigns_wrapper
    .news_campaign_wrapper2
    .news_campaign_swiper_wrapper {
    padding: 0em;
    margin-top: 8em;
    position: absolute;
    width: 100%;
  }
}
.ql-video {
  width: 600px;
  height: 400px;
}

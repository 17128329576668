@import '../../index.scss';

.worship_resources_main_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-bottom: 15em;
  .worship_resources_main_content_1_wrapper {
    width: 100%;
    height: 400px;
    background-color: $primaryColor;
    .worship_resources_main_content_1_content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      .worship_resources_resources_card_wrapper {
        position: absolute;
        bottom: -420px;
        .worship_resources_resource_card {
          cursor: pointer;
          margin-top: 1em;
          width: 350px;
          height: 400px;
          padding-left: 1em;
          margin-right: 1em;
        }
      }
      .worship_resources_title_wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        padding-bottom: 2em;
        .worship_resources_title {
          font-family: 'Merriweather';
          color: $background;
          padding-top: 2em;
          padding-bottom: 0.5em;
        }
        .underline_wrapper {
          width: 100px;
          height: 2px;
          background-color: $background;
        }
        .worship_resources_subheading {
          padding-top: 1em;
          color: $background;
        }
      }
      .worship_resources_resource_card_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 2em;
        .worship_resources_resource_card {
          margin-top: 1em;
          width: 350px;
          height: 400px;
          padding-left: 1em;
          margin-right: 1em;
        }
      }
    }
  }
  .worship_resources_main_content_2 {
    width: 100%;
    height: 100%;
    background: $homeSiteBackground;
  }

  .worship_resources_subheading_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 20%;
  }
  .worship_resources_card_container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.88em;
    flex-direction: row;
    padding-bottom: 2em;
    .worship_resources_card {
      width: 280px;
      height: 320px;
      padding-right: 1.86em;
    }
  }
  @media only screen and (max-width: 1050px) {
    .worship_resources_main_content_1_wrapper {
      .worship_resources_main_content_1_content {
        .worship_resources_resources_card_wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          margin-top: 2em;
          .worship_resources_resource_card {
            margin-top: 1em;
            width: 500px;
            height: 345px;
            padding-left: 1em;
            margin-right: 1em;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 1500px) {
  .worship_resources_main_container {
    .worship_resources_main_content_1_wrapper {
      width: 100%;
      height: 400px;
      background-color: $primaryColor;
    }
    .worship_resources_card_container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      .worship_resources_card {
        width: 370px;
        height: 500px;
        padding-top: 1.86em;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .worship_resources_main_container .worship_resources_main_content_1_wrapper .worship_resources_main_content_1_content .worship_resources_resources_card_wrapper {
    position: absolute;
    bottom: -350px;
}
.worship_resources_main_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-bottom: 2em;
}
}
@media only screen and (max-width: 600px) {
  .worship_resources_main_container
    .worship_resources_main_content_1_wrapper
    .worship_resources_main_content_1_content
    .worship_resources_resources_card_wrapper
    .worship_resources_resource_card {
    margin-top: 1em;
    width: 332px;
    height: 265px;
    padding-left: 1em;
    margin-right: 1em;
  }
  .worship_resources_main_content_1_content .worship_resources_title_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding-bottom: 0em;
  }
  .worship_resources_main_container
    .worship_resources_main_content_1_wrapper
    .worship_resources_main_content_1_content
    .worship_resources_resources_card_wrapper {
    position: relative;
    bottom: 0px;
  }
  .worship_resources_main_container
    .worship_resources_main_content_1_wrapper
    .worship_resources_main_content_1_content
    .worship_resources_resources_card_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 0em;
  }
}
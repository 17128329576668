@import '../../index.scss';

.synod_event_card_container {
  display: flex;
  justify-content: center;
  align-items: center;
  .synod_event_card_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    background: $background;
    .synod_event_card_image_wrapper {
      .synod_event_card_image {
        width: 300px;
        height: 230px;
        object-fit: cover;
      }
    }

    .synod_event_card_content_wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding-left: 2em;
      padding-top: 1em;
      .synod_event_card_date_wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-weight: 400;

        .synod_event_card_date {
          color: $primaryColor;
          font-weight: 700;
        }
        .synod_event_card_month_time_wrapper {
          padding-left: 0.5em;

          .synod_event_card_month {
            color: $primaryTextColor;
            font-weight: 600;
          }
          .synod_event_card_time {
            color: $primaryTextColor;
            font-weight: 500;
          }
        }
      }
      .synod_event_card_name {
        color: $primaryColor;
        padding-top: 2em;
      }
      .synod_event_card_event_name {
        padding-top: 0.7em;
      }
      .synod_event_card_event_description {
        padding-top: 1em;
        width: 500px;
        text-align: justify;
        font-weight: 400;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        font-family: 'Open Sans', sans-serif;
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .synod_event_card_container
    .synod_event_card_wrapper
    .synod_event_card_content_wrapper
    .synod_event_card_event_description {
    padding-top: 1em;
    width: 288px;
    padding-bottom: 2em;
    text-align: justify;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .synod_event_card_container
    .synod_event_card_wrapper
    .synod_event_card_content_wrapper
    .synod_event_card_date_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-weight: 400;
  }
}

@media screen and (max-width: 600px) {
  .synod_event_card_container
    .synod_event_card_wrapper
    .synod_event_card_image_wrapper
    .synod_event_card_image {
    width: 100%;
    object-fit: cover;
    height: 100%;
  }
  .synod_event_card_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .synod_event_card_container .synod_event_card_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    background: #ffffff;
  }
  .synod_event_card_container
    .synod_event_card_wrapper
    .synod_event_card_content_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 0.5em;
  }
}

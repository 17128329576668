@import "../../index.scss";

.partnerandnetworkscreen{
  .partnerandnetworkcontainer{
    .partnerandnetworkwrapper{
      .partnerandnetworkheaderwrapper{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding-right: 5em;
      }
      .partnerandnetwork_search_bar_wrapper{
        padding: 2em;
      }
      .partnerandnetwork_select_box_wrapper{
        width: 250px;
      }
      .partnerandnetwork_button_wrapper{
        width: 170px;
        padding: 2em;
      }
    }
  }
}
.partner_title_wrapper{
  padding-left: 1em;
  .h2{
    font-size: 25px;
  }
}
.partner_action_wrapper{
  display: flex;
  align-items: center;
}
.partnerandnetwork_customModal {
  background: $background;
  width: 22%;
  height: 60vh;
  min-height: 400px;
  border-radius: 16px;
  box-shadow: 0 10 28px 0px $primaryColor;
  padding: 0;

  .partnerandnetwork_customModal::-webkit-scrollbar {
    display: none;
  }

  .partnerandnetwork_modal_title_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1em;

    .partnerandnetwork_modal_title {
      font-weight: bold;
      margin-left: 0.75em;
      color: $primaryTextColor;
    }
  }
  .partnerandnetwork_modal_line_wrapper {
    width: 100%;
    height: 1px;
    background-color: $primaryColor;
  }
  .partnerandnetwork_modal_input_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .partnerandnetwork_modal_input {
      padding-top: 1em;
      width: 80%;
      .upload_box_wrapper{
        width: 100%;
        padding-top: 1em;
      }
    }
  }
  .partnerandnetwork_button_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;

    .partnerandnetwork_button{
      padding-top: 1em;
      width: 50%;
    }
  }
}
@import "../../index.scss";

.sermon_outline_screen {
  .sermon_outline_container {
    .sermon_outline_wrapper {
      .sermon_outline_header_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 2em 2em 0em 2em;

        .sermon_outline_search_box_wrapper {
          width: 250px;
          padding-right: 1rem;
        }

        .sermon_outline_select_box_wrapper {
          width: 200px;
          padding-right: 1rem;
        }

        .sermon_outline_button_wrapper {
          width: 200px;
          padding-right: 1rem;
        }
      }
    }
  }
}
.sermon_outline_button{
  width: 200px;
}
.modal_container{
  height: none;
}
.sermon_outline_customModal {
  background: $background;
  width: 25%;
  height: 70vh;
  border-radius: 16px;
  box-shadow: 0 10 28px 0px $primaryColor;
  padding: 0;
  position: relative;

  .close_button {
    outline: none;
  }

  .sermon_outline_modal_title_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1em;

    .sermon_outline_modal_title {
      font-weight: bold;
      margin-left: 0.75em;
      color: $primaryTextColor;
    }
  }
  .sermon_outline_modal_line_wrapper {
    width: 100%;
    height: 0.5px;
    background-color: $primaryColor;
  }
  .sermon_outline_modal_input_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    // padding-top: 5em;
    .sermon_outline_modal_input {
      padding-top: 1em;
      width: 80%;
      .upload_box_wrapper {
        width: 100%;
        padding-top: 1em;
        .upload_box {
          padding-top: 1em;
        }
      }
    }

    .sermon_outline_file_upload_wrapper {
      display: flex;
      margin-top: 1.5em;
      justify-content: space-between;
      width: 90%;
      height: 100%;
      border-radius: 2%;
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      background-color: $secondaryLightColor;

      .file_upload_file_name {
        display: flex;
        padding-left: 0.5em;
        width: 85%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .file_upload_inactive_icon-wrapper {
        display: flex;
        justify-items: center;
        align-items: center;

        .file_upload_delete_icon {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .sermon_outline_modal_input_wrapper::-webkit-scrollbar {
    display: none;
  }

  .sermon_outline_modal_button_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .sermon_outline_modal_button {
      padding-top: 1em;
      width: 150px;
    }
  }
}

.sermon_outline_action_wrapper{
  display: flex;
}
@import '../../index.scss';

.csi_video_screen {
  height: 100vh;
  overflow: auto;
  .csi_video_container {
    .csi_video_user_wrapper {
      .csi_video_body_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 5em;
        .csi_video_body {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          max-width: 1170px;
          .csi_video_title_wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-top: 4em;
            .csi_video_title {
              font-weight: 500;
            }
            .csi_video_line_wrapper {
              margin-top: 0.5em;
              width: 120px;
              height: 2px;
              background: $primaryColor;
            }
          }
          .csi_video_content_wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            align-items: center;
            width: 90%;
            // height: 100px;
            padding-top: 5em;
            .csi_video_content {
              width: 28%;
              height: 450px;
              padding-top: 3em;
              .csi_video_image {
                width: 450px;
                height: 300px;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1500px) {
    .csi_video_container {
      .csi_video_user_wrapper {
        .csi_video_body_wrapper {
          width: 100%;
          .csi_video_body {
            .csi_video_content_wrapper {
              .csi_video_content {
                .csi_video_image {
                  width: 300px;
                  height: 300px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .csi_video_screen
    .csi_video_container
    .csi_video_user_wrapper
    .csi_video_body_wrapper
    .csi_video_body
    .csi_video_content_wrapper
    .csi_video_content {
    width: 30%;
    height: 300px;
    padding-top: 3em;
  }
  .csi_video_screen
    .csi_video_container
    .csi_video_user_wrapper
    .csi_video_body_wrapper
    .csi_video_body
    .csi_video_content_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    padding-top: 0em;
  }
}
@media screen and (max-width: 600px) {
  .csi_video_screen
    .csi_video_container
    .csi_video_user_wrapper
    .csi_video_body_wrapper
    .csi_video_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
  }
  .csi_video_screen
    .csi_video_container
    .csi_video_user_wrapper
    .csi_video_body_wrapper
    .csi_video_body
    .csi_video_title_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 2em;
  }
  .csi_video_screen
    .csi_video_container
    .csi_video_user_wrapper
    .csi_video_body_wrapper
    .csi_video_body
    .csi_video_content_wrapper
    .csi_video_content {
    width: 100%;
    height: 300px;
    padding: 2em;
  }
}

@import "../../index.scss";

.adswiper_component{
    .adswiper_container{
        cursor: pointer;
        .adswiper_wrapper{
            .adswiper_image{
                width: 100%;
                // object-fit: fill;
            }
        }
    }
    .carousel.carousel-slider .control-arrow {
        top: 192px;
        color: $background;
        font-size: 26px;
        /* bottom: 0; */
        margin-top: 0;
        padding: 5px;
    }
}


@import "../../index.scss";

.campaign_screen{
  .campaign_container{
    .campaign_wrapper{
      .campaign_header_wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding-right: 5em;

        .campaign_search_box_wrapper{
          width: 30%
        }

        .campaign_select_box_wrapper{
          width: 180px;
          padding: 2em;
        }

        .campaign_button_wrapper{
          width: 150px;
        }
      }
    }
  }
  .campaign_action_wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .campaign_title_wrapper{
    padding-left: 2em;
  }
}
@import '../../index.scss';

.custom_nav_component {
  width: 100%;
  .custom_nav_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // background-color: $white;
    // box-shadow: $secondaryLightColor 0px 0px 12px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    .custom_nav_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1em;
      .custom_nav_list {
        padding: 1em 1em 1em 1em;
        .custom_nav_link {
          font-weight: 500;
          cursor: pointer;
          padding-top: 0.125em;
          text-decoration: none;
          color: $primaryTextColor;
        }
        .selected_custom_nav_link {
          cursor: pointer;
          text-decoration: none;
          padding-bottom: 0.6em;
          border-bottom: 2px solid $primaryColor;
          color: $primaryTextColor;
        }
      }
    }
    .custom_nav_wrapper_flex{
      justify-content: flex-end;
      .custom_nav_search_wrapper{
        padding-right: 2em;
      }
    }
  }
}
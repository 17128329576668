@import '../../index.scss';

.screen {
  height: 100vh;
  overflow: auto;
  background-color: $backgroundColor;
  display: flex;
  flex-direction: column;
  align-items: center;
  .header_wrapper {
    width: 100%;
  }
  .partners_screen_container {
    max-width: 1170px;
    .partners_screen_header_wrapper {
      display: flex;
      justify-content: center;
      padding-top: 2em;
      .partners_screen_header {
        font-family: 'Merriweather';
        font-weight: 400;
        .header_line {
          height: 3px;
          width: 152.5px;
          background-color: $primaryColor;
          margin-top: 1rem;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
    .partners_screen_body_wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .card_wrapper {
        width: 350px
      }
    }
  }
  .footer_wrapper {
    width: 100%;
  }
}
@media screen and (max-width: 1024px) {
  .screen .partners_screen_container .partners_screen_body_wrapper .card_wrapper {
    width: 350px;
  }
.screen .partners_screen_container .partners_screen_header_wrapper .partners_screen_header .header_line {
  width: 90.5px;
}
.screen .partners_screen_container {
  width: 100vw;
}
}
@media screen and (max-width: 600px) {
  .screen {
    .partners_screen_container {
      width: 100vw;
      .partners_screen_header_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 2em;
        .partners_screen_header {
          .header_line {
            width: 90.5px;
          }
        }
      }
      .partners_screen_body_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        .card_wrapper {
          width: 100%;
          padding-bottom: 2em;
        }
      }
    }
  }
}
@import "../../index.scss";

.subscriber_screen{
  .subscriber_container{
    .subscriber_wrapper{
      .subscriber_header_wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 2em;
        padding-right: 2em;

        .subscriber_action_button_wrapper{
          padding-left: 1.5em;
          padding-right: 1em;
        }
      }
    }
  }
}
.subscriber_title_wrapper{
  padding-left: 1em;
}
.subscriber_action_wrapper{
  display: flex;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
.subscriber_customModal {
  background: $background;
  width: 22%;
  height: 60vh;
  overflow-x: auto;
  min-height: 400px;
  border-radius: 16px;
  box-shadow: 0 10 28px 0px $primaryColor;
  padding: 0;

  .close_button{
    outline: none;
  }

  .subscriber_modal_title_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1em;

    .subscriber_modal_title {
      font-weight: bold;
      margin-left: 0.75em;
      color: $primaryTextColor;
    }
  }
  .subscriber_modal_line_wrapper {
    width: 100%;
    height: 1px;
    background-color: $primaryColor;
  }
  .subscriber_modal_input_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .subscriber_modal_input {
      padding-top: 1em;
      width: 80%;
      .upload_box_wrapper{
        width: 100%;
        padding-top: 1em;
      }
    }
    .subscriber_modal_select_input{
      padding-top: 1em;
      width: 80%;
      height: 100%;
    }
  }
  .subscriber_modal_button_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;

    .subscriber_modal_button{
      padding-top: 1em;
      width: 50%;
    }
  }
}

.subscriber_customModal::-webkit-scrollbar {
  display: none;
}
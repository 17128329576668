@import '../../index.scss';

.sidebar_topbar_container {
  width: 100%;
  display: flex;
  position: fixed;
  background-image: linear-gradient(180deg,$primaryColor 0%, $primaryGradient 100%);
  .sidebar_component {
    width: 18%;
    .sidebar_container {
      height: 100vh;
      position: fixed;
      width: 18%;
      .sidebar_wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-bottom: 0.7em;
        .sidebar_logo_content {
          text-align: center;
          padding: 1.3em;
          border-bottom: 0.1px solid $secondaryDarkColor;
          .nav_link {
            text-decoration: none;
            .img_container {
              padding: 0 0 1em 0;
              .sidebar_logo {
                width: 30%;
              }
            }
            .sidebar_head {
              text-transform:uppercase;
              color: $secondaryLightColor;
              font-size: 20px;
            }
          }
        }

        .tab_icon_container {
          // padding: 25px;
          // width: 80%;
          // font-size: 2rem;
          .nav_link {
            display: flex;
            text-decoration: none;

            .tab_icon {
              // width: 30%;
              padding: 0.5em 0.8em 0.5em 2.5em;
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
              cursor: pointer;
            }

            .tab_name {
              cursor: pointer;
              width: 70%;
              padding: 0.5em 0.8em 0.5em 0.7em;
              color: $secondaryDarkColor;
              font-weight: 500;
            }
          }

          .active {
            .tab_name {
              color: $secondaryLightColor;
              font-weight:700;
            }
            .tab_icon {
              background-color: $secondaryLightColor;
              border-top-right-radius: 50px;
              padding: 0.5em 0.8em 0.5em 2.5em;
              border-bottom-right-radius: 50px;
            }
          }
        }
      }
    }
  }
  .top_bar_container {
    width: 82%;
    height: 100vh;
    background-color: $backgroundColor;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;

    .top_bar_content {
      display:flex;
      justify-content:space-between;
      padding: 2em 3em 1em 3em;
      .top_bar_wrapper {
        width: 30%;
        .top_bar_profile {
          display:flex;
          justify-content: flex-end;
          align-items:center;

          .profile_icon {
            .profile_pic {
              width: 40px;
              height: 40px;
              border-radius: 50%;
            }
          }
          .padding_icons {
            padding: 0 0.8em;
          }
        }
      }
    }
    .main_body {
      margin: 1em 2em 2em 2em;
      border-radius: 15px;
      background-color: $background;
      overflow-y:auto;
      height: 80vh;
      position:relative;
    }
    @media screen and (min-width: 1500px) {
      .main_body { height: 85vh; }
    }
    .main_body::-webkit-scrollbar {
      display: none;
    }
  }
}
.logout_wrapper{
  display: flex;
  justify-content: flex-end;
}
.logout_button{
width: 100px!important;   
}
@import '../../index.scss';

.popup_component {
  display: flex;
  justify-content: center;
  width: 100%;
  .popup_container {
    // padding: 1em;
    width: 80%;
    display: flex;
    justify-content: center;
    .popup_home {
      display: flex;
      width: 100%;
      justify-content: space-around;
      padding: 2.5em 1em 3em 1em;
      
      .popup_wrapper {
        .map_container {
          height: 200px;
        }
        .head_text {
          padding: 0em 0em 1em 0em;
          color: $secondaryLightColor;
        }
        .list_details {
          color: $secondaryLightColor;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          .pop_up_nav {
            text-decoration: none;
            color: $secondaryLightColor;
            line-height: 2;
          }
          .pop_up_nav:hover {
            cursor: pointer;
            color: $secondaryDarkColor;
          }
        }
      }
    }
    .department_nav_wrapper {
      width: 84%;
      padding: 2em;
      .tab_container_flex {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-end;
        .list_details {
          color: $secondaryLightColor;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          padding: 0.5em 1em;
          width: 230px;
          .pop_up_nav {
            text-decoration: none;
            color: $secondaryLightColor;
            line-height: 2;
          }
          .pop_up_nav:hover {
            text-decoration: none;
            cursor: pointer;
            color: $secondaryDarkColor;
            line-height: 2;
          }
        }
      }
    }
    .home_wrapper {
      width: 80%;
      .tab_link_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .tab_container {
        display: flex;
        justify-content: space-evenly;
        padding: 2em 1em;
        .tab_lists {
          .head_wrapper {
            color: $background;
            padding: 2em 0 1em 0;
          }
          .contact_list_wrapper {
            color: $background;
            padding: 2em 0 1em 0;
            text-decoration: none;
          }
          .topic_wrapper {
            .pop_up_nav {
              text-decoration: none;
              color: $secondaryLightColor;
              line-height: 2;
            }
            .pop_up_nav:hover {
              color: $secondaryDarkColor;
            }
          }
          .topic_wrapper_flex_container {
            display: flex;
            width: 100%;
            justify-content: space-between;
            .topic_wrapper_content {
              .pop_up_nav {
                text-decoration: none;
                color: $secondaryLightColor;
                line-height: 2;
              }
              .pop_up_nav:hover {
                color: $secondaryDarkColor;
              }
            }
          }
        }
      }
      .tab_container_flex {
        // display: flex;
        padding: 2em 1em;
        // justify-content: space-evenly;
        .tab_lists {
          .head_wrapper {
            color: $background;
            padding: 2em 0 1em 0;
          }
          .topic_wrapper {
            .pop_up_nav {
              text-decoration: none;
              color: $secondaryLightColor;
              line-height: 2;
            }
            .pop_up_nav:hover {
              color: $secondaryDarkColor;
            }
          }
          .topic_wrapper_flex_container {
            display: flex;
            width: 100%;
            justify-content: space-evenly;
            -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
            padding: 2em;
            .topic_wrapper_content {
              display: flex;
              flex-wrap: wrap;
              // height: 400px;
              // width: 30%;
              float: left;
              align-content: space-between;
              .pop_up_nav {
                text-decoration: none;
                color: $secondaryLightColor;
                line-height: 2;
              }
              .pop_up_nav:hover {
                color: $secondaryDarkColor;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .navbar_component .navbar_container .onhover_pop {
    position: absolute;
    transition: all 5s ease-out;
    top: 2.2em;
    left: 0;
    z-index: 200;
    width: 100%;
    background-color: #b5316a;
  }
  .popup_component .popup_container {
    width: 100%;
    display: flex;
    justify-content: center;
}
}
.last_diocese{
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
}
.diocese_text{
  text-decoration: none;
  color: white;
}
@media screen and (max-width: 1500px) {
  .popup_component .popup_container .department_nav_wrapper {
    width: 75%;
    display: flex;
    justify-content: flex-end;
    padding: 2em;
}
.popup_component .popup_container .department_nav_wrapper .tab_container_flex {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
}
}
// @media screen and  (width: 1440px){
//   .popup_component .popup_container {
//     width: 75%;
//     display: flex;
//     justify-content: center;
// }
// .popup_component .popup_container .department_nav_wrapper {
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   padding: 2em;
// }
// .tab_container_flex{
  
// }
// }
@import '../../index.scss';

.csita_screen {
  width: 100%;
  height: 100%;
  overflow: auto;
  .csita_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: $backgroundColor;
    .csita_wrapper {
      width: 100%;
      .csita_header_wrapper {
        width: 100%;
      }
      .csita_navbar_wrapper {
        width: 100%;
      }
      .csita_title_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 2em;

        .csita_title {
          font-weight: 400;
          font-family: 'Merriweather';
        }
        .csita_line_wrapper {
          width: 100px;
          margin-top: 0.5em;
          height: 2px;
          background: $primaryColor;
        }
      }
      .csita_nav_link_wrapper {
        display: flex;
        justify-content: center;
        padding: 0.5em 0; 
        position: sticky;
        top: 25px;
        background: $backgroundColor;
        z-index: 1;
        .title_links_wrapper {
          display: flex;
          padding: 1rem;
          justify-content: center;
          position: relative;
          &:after {
            content: '';
            width: 90%;
            position: absolute;
            bottom: 0px;
            height: 5px;
            border-bottom: 3px solid $secondaryLightColor;
          }
          .link_1 {
            padding-right: 2em;
            position: relative;
            font-weight: 400;
            .title_link {
              text-decoration: none;
              color: $subscribeButtonColor;
            }
          }
          .link {
            position: relative;
            font-weight: 400;
            padding-right: 2em;
            .title_link {
              text-decoration: none;
              color: $subscribeButtonColor;
            }
          }
        }
      }
      .csita_content_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 5em;
        width: 100%;
        .csita_content {
          width: 70%;
          h1 {
            position: relative;
            padding-bottom: 0.5em;
            font-family: 'Merriweather';
            font-weight: '400';
          }
          h1::after {
            content: '';
            border-bottom: 2px solid $primaryColor;
            position: absolute;
            height: 3px;
            width: 160px;
            bottom: 0;
            left: 0;
          }
          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            font-family: 'Merriweather';
            font-weight: '400';
          }
          img {
            padding: 3em;
            width: 50%;
            height: 400px;
          }
          p {
            padding-top: 1em;
          }
        }
        .csita_officer_content_wrapper {
          display: flex;
          justify-content: space-evenly;
          padding: 2em;
          width: 100%;
          .officer_image_wrapper {
            .off_card_wrapper {
              height: 400px;
              width: 450px;
              object-fit: cover;
            }
          }
        }
      }
      .csita_footer_wrapper {
        padding-top: 5em;
      }
    }
  }
  .selected {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      height: 2px;
      width: 100%;
      background-color: $primaryColor;
      bottom: -16px;
      z-index: 100;
      left: 0;
    }
  }
}
@media screen and (max-width: 1024px) {
  .csita_screen .csita_container .csita_wrapper .csita_nav_link_wrapper {
    padding-top: 1em;
  }
  .csita_screen
    .csita_container
    .csita_wrapper
    .csita_nav_link_wrapper
    .title_links_wrapper {
    width: 100%;
  }
  .csita_screen
    .csita_container
    .csita_wrapper
    .csita_nav_link_wrapper
    .title_links_wrapper
    .h3 {
    // font-size: 20px;
  }
  .csita_screen
    .csita_container
    .csita_wrapper
    .csita_nav_link_wrapper
    .title_links_wrapper {
    width: 100%;
  }
  .csita_screen
    .csita_container
    .csita_wrapper
    .csita_nav_link_wrapper
    .title_links_wrapper:after {
    content: '';
    width: 83%;
    position: absolute;
    bottom: 0px;
    height: 5px;
    left: 60px;
    border-bottom: 3px solid #fff3fa;
  }
  .csita_screen .csita_container .csita_wrapper .csita_content_container {
    padding-top: 2em;
  }
  .csita_screen
    .csita_container
    .csita_wrapper
    .csita_content_container
    .csita_officer_content_wrapper
    .officer_image_wrapper
    .off_card_wrapper {
    height: 400px;
    width: 280px;
    object-fit: cover;
  }
}
@media screen and (max-width: 600px) {
  .csita_screen
    .csita_container
    .csita_wrapper
    .csita_nav_link_wrapper
    .title_links_wrapper
    .h3 {
    font-size: 8px;
  }
  .csita_screen .csita_container .csita_wrapper .csita_title_wrapper {
    padding-top: 2em;
  }
  .csita_screen .csita_container .csita_wrapper .csita_nav_link_wrapper {
    padding-top: 1em;
    top: 10px;
  }
  .csita_screen
    .csita_container
    .csita_wrapper
    .csita_nav_link_wrapper
    .title_links_wrapper:after {
    content: '';
    width: 80%;
    position: absolute;
    bottom: 0px;
    height: 5px;
    left: 35px;
    border-bottom: 3px solid #fff3fa;
  }
  .csita_screen
    .csita_container
    .csita_wrapper
    .csita_content_container
    .csita_content {
    width: 100%;
    padding: 2em;
  }
  .csita_screen .csita_container .csita_wrapper .csita_content_container {
    padding-top: 1em;
  }
  .csita_screen
    .csita_container
    .csita_wrapper
    .csita_content_container
    .csita_content
    h1 {
    font-size: 15px;
  }
  .csita_screen
    .csita_container
    .csita_wrapper
    .csita_content_container
    .csita_officer_content_wrapper
    .officer_image_wrapper
    .off_card_wrapper {
    height: 320px;
    width: 280px;
    object-fit: cover;
  }
  .csita_screen
    .csita_container
    .csita_wrapper
    .csita_content_container
    .csita_officer_content_wrapper {
    display: flex;
    flex-direction: column;
    padding: 0em;
    width: 100%;
  }
  .officer_card_container .officer_card_wrapper {
    padding: 1em 0;
  }
}

@import "../../index.scss";

.diocese_description_screen {
  background-color: $background;
  width: 100%;
  .diocese_description_container {
    width: 100%;
    .diocese_description_wrapper {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      .diocese_description_header_wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding-top: 2em;
        .diocese_description_navigate_icon_wrapper {
          .diocese_description_navigate_icon {
            .navigate_icon {
              transform: rotate(90deg);
            }
          }
        }
        .diocese_description_header_name_container {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          .diocese_description_header_name {
            padding-left: 1em;
            font-weight: 700;
          }
          .diocese_description_header_button_wrapper{
            padding-right: 2.5em;
          }
        }
      }
      .diocese_description_navbar_wrapper {
        width: 100%;
      }
      .diocese_description_table_wrapper{
        width: 100%;
      }
    }
  }
}

.diocese_description_modal {
  background: $background;
  width: 22%;
  height: 60%;
  border-radius: 16px;
  box-shadow: 0 10 28px 0px $primaryColor;
  padding: 0;

  .diocese_description_modal::-webkit-scrollbar {
    display: none;
  }

  .diocese_description_modal_title_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1em;
    padding-right: 15px;

    .diocese_description_modal_title {
      font-weight: bold;
      margin-left: 0.75em;
      color: $primaryTextColor;
    }
  }
  .diocese_description_modal_line_wrapper {
    width: 100%;
    height: 1px;
    background-color: $primaryColor;
  }
  .diocese_description_modal_input_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5em;
    .diocese_description_modal_input {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding-left: 2em;
      .diocese_description_thumbnail_wrapper {
        padding-top: 1.5em;
      }
      .department-events_modal_uploadbox{
        padding-top: 1em;
        width: 100%;
      }
    }
  }
  .diocese_description_button_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
  }
}
.uploaded_diocese_description{
  display: flex;
  justify-content: center;
  align-items: center;
}
.diocese_description{
  width: 300px;
height: 300px;
margin-top: 1em;
}
.upload_box{
margin-top: 1em;
}
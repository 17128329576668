@import "../../index.scss";

.csi_life_screen {
  .csi_life_container {
    .csi_life_wrapper {
      .csi_life_header_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2em 2em 0em 2em;

        .csi_life_search_wrapper {
          width: 250px;
          padding-right: 1em;
        }

        .csi_life_filter_button_wrapper {
          width: 80px;
          padding-right: 1em;
        }

        .csi_life_action_button_wrapper {
          width: 150px;
          padding-right: 1em;
        }
      }
    }
  }
}
.csi_life_action_wrapper{
  display: flex;
}
.csi_life_action_button{
  width: 150px;
}
.csi_life_filter_modal {
  background: $background;
  width: 450px;
  min-height: 34vh;
  border-radius: 16px;
  overflow-x: auto;
  box-shadow: 0 10 28px 0px $primaryColor;
  padding: 0;
  .csi_life_filter_modal_container {
    .csi_life_filter_modal_wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      .csi_life_filter_modal_header_wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        .csi_life_filter_modal_header {
          padding-left: 1em;
          padding-top: 1em;
          font-weight: 700;
        }
        .csi_life_filter_modal_clear {
          padding-right: 1em;
          padding-top: 1em;
          font-weight: 300;
          cursor: pointer;
        }
        .csi_life_filter_modal_department_heading {
          padding-left: 1em;
          padding-top: 2em;
          font-weight: 700;
        }
        .csi_life_filter_modal_department_clear {
          padding-right: 1em;
          padding-top: 2em;
          font-weight: 300;
        }
      }
      .csi_life_filter_button_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 1em;
        padding-top: 1em;
        .button_inactive {
          padding-left: 1em;
        }
      }
      .csi_life_filter_apply_filter_button_wrapper {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        padding-top: 3em;
      }
      .csi_life_filter_modal_checklist {
        width: 100%;
        height: 300px;
        overflow-x: auto;
        padding-right: 2em;
      }
      .csi_life_filter_modal_checklist::-webkit-scrollbar {
        display: none;
      }
      .csi_life_filter_modal_date_wrapper {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        align-items: center;

        .csi_life_filter_modal_name {
          padding-left: 1em;
        }
        .csi_life_filter_modal_input_wrapper {
          padding-left: 1em;
          width: 150px;
        }
      }
      .csi_life_filter_modal_edition_wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 1.2em;
        padding-top: 2em;
        cursor: pointer;
        .csi_life_filter_modal_icon_wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: $primaryColor;

          .csi_life_filter_modal_icon {
            width: 10px;
            height: 10px;
          }
        }

        .csi_life_filter_modal_edition_icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: $secondaryLightColor;
        }

        .csi_life_filter_modal_edition_name {
          font-weight: 500;
          padding-left: 1em;
        }
      }
    }
  }
}

.csi_life_filter_modal::-webkit-scrollbar {
  display: none;
}

.csi_life_customModal {
  background: $background;
  // height: 450px;
  min-height: 45vh;
  width: 390px;
  overflow-y: auto;
  border-radius: 16px;
  box-shadow: 0 10 28px 0px $primaryColor;
  padding: 0;
  position: relative;

  .close_button {
    outline: none;
  }

  .csi_life_modal_header_wrapper {
    background-color: $background;
    position: sticky;
    top: 0;
    z-index: 1;
    .csi_life_modal_title_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1em;

      .csi_life_modal_title {
        font-weight: bold;
        margin-left: 0.75em;
        color: $primaryTextColor;
      }
      .csi_life_modal_icon_wrapper {
        cursor: pointer;
        .csi_life_modal_icon {
          width: 30px;
          height: 30px;
        }
      }
    }
  }

  .csi_life_modal_line_wrapper {
    width: 100%;
    height: 0.5px;
    background-color: $primaryColor;
  }
  .csi_life_modal_input_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    .csi_life_modal_input {
      padding-top: 1em;
      width: 80%;

      .csi_life_filter_modal_edition_wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 0em;
        padding-top: 1.5em;
        cursor: pointer;
        .csi_life_filter_modal_icon_wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: $primaryColor;

          .csi_life_filter_modal_icon {
            width: 10px;
            height: 10px;
          }
        }

        .csi_life_filter_modal_edition_icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: $secondaryLightColor;
        }

        .csi_life_filter_modal_edition_name {
          font-weight: 500;
          padding-left: 1em;
        }
      }
      .upload_box_wrapper {
        width: 100%;
        padding-top: 1em;
        .upload_box {
          padding-top: 1em;
        }
      }
    }

    .csi_life_file_upload_wrapper {
      display: flex;
      margin-top: 1.5em;
      justify-content: space-between;
      width: 90%;
      height: 100%;
      border-radius: 2%;
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      background-color: $secondaryLightColor;

      .file_upload_file_name {
        display: flex;
        padding-left: 0.5em;
        width: 85%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .file_upload_inactive_icon-wrapper {
        display: flex;
        justify-items: center;
        align-items: center;

        .file_upload_delete_icon {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .csi_life_modal_input_wrapper::-webkit-scrollbar {
    display: none;
  }

  .csi_life_modal_button_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 1em;
    padding-bottom: 2em;

    .csi_life_modal_button {
      padding-top: 2em;
      width: 120px;
    }
  }
}

.csi_life_customModal::-webkit-scrollbar {
  display: none;
}
@media screen and (max-width: 1500px) {

}
@import '../../index.scss';

.button_component {
  background: $primaryColor;
  width: 100%;
  padding: 1em;
  color: $secondaryLightColor;
  display: flex;
  justify-content: center;
  align-items: center;
  .icon {
    padding-right: 1em;
    img{
      height: 100%;
      width:35px
    }
  }
  &:hover{
    cursor: pointer;
  }
}

@import "../../index.scss";

.add_news_screen {
  .add_news_content {
    .add_news_head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1em;
      border-bottom: 1px solid $primaryColor;
      top: 0;

      .add_news_head_text {
        width: 60%;
      }
      
      .add_news_btn_container {
        width: 40%;
        display: flex;
        justify-content: flex-end;
        align-items:center;

        .add_news_select {
          width: 60%;
        }

        .add_news_btn {
          width: 100px;
          padding: 0 0 0 2em;
        }
      }
    }
  }
}
.diocese_view_navigate_icon_wrapper {
  .diocese_view_navigate_icon {
    .navigate_icon {
      transform: rotate(90deg);
      margin-right: 1em;
    }
  }
}
.diocese_news_content{
  display: flex;
  flex-direction: column;

  .diocese_news_content_title_wrapper{
    width: 72%;
    padding-left: 1em;
    padding-top: 1em;
  }
  .diocese_news_content_heading_wrapper{
    width: 72%;
    padding-left: 1em;
    padding-top: 1em;
  }
}
.modal_container_crop{
  width: 100vw!important;
  height: 100vh;
  max-width: 90vw!important;
}
.react_image_crop{
  padding: 2em 0;
}
.crop_wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em;
  height: 100%;
  width: 100%;
}
.canvas_crop{
  width: 100vw;
  margin-top: 300px;
  height: 100vh;
}
.react_image_crop{
  height: 100%;
  width: 100%;
}
.crop_button_wrapper{
  width: 100%;
  display: flex;
  justify-content: center;
}
.crop_btn{
  width: 200px;
  padding: 2em 0;
}
.ql-snow .ql-editor img {
  max-width: 500px;
  max-height: 500px;
  display: flex;
}
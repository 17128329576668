
@import "../../index.scss";

.magazine_card_container {
  width: 100%;
  height: 90%;
  position: relative;
  .magazine_card_image_container {
    width: 100%;
    height: 100%;
    position: relative;
    .magazine_card_image {
      background: linear-gradient(
        180deg,
        rgba(94, 24, 54, 0.71) 0%,
        rgba(111, 24, 62, 0.89) 55%,
        rgb(181, 49, 106) 100%
      );
      width: 100%;
      height: 99%;
      background-size: contain;
      opacity: 1;
      top: 0;
      z-index: 100;
      object-fit: cover;
    }
    .magazine_card_date_container {
      .date_wrapper {
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 100;
      }
      .magazine_card_date {
        padding-bottom: 2em;
        color: $background;
        text-align: center;
      }
    }
  }
  .button_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 15%;
    border-radius: 0px;
  }
  .button_wrapper {
    height: 62%;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Raleway";
    font-size: 10px;
    font-weight: 500;
    border-radius: 0px;
    text-align: center;
    color: $background;
  }

  .magazine_overlay{
    background: linear-gradient(
      180deg,
      rgba(94, 24, 54, 0.02) 0%,
      rgba(111, 24, 62, 0.02) 20%,
      rgba(181, 49, 106, 0.25) 60%,
      #100007 100%
    );
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  .on_hover_button {
    position: absolute;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    &:hover{
      opacity: 1;
      transition: all 0.3s ease-in-out;
    }
    .download_btn {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      .download_link {
        text-decoration: none;
        background-color: $primaryColor;
        padding: 0.5em 1em 0.5em 1em;
        color: $background;
      }
    }
  }

  @media only screen and (min-width: 1500px) {
    .button_container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 15%;
      border-radius: 0px;
      .button_wrapper {
        height: 50%;
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Raleway";
        font-size: 12px;
        font-weight: 500;
        border-radius: 0px;
        text-align: center;
        color: $background;
      }
    }
  }
  @media only screen and (max-width: 1050px) {
    .button_container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 15%;
      border-radius: 0px;
      .button_wrapper {
        height: 50%;
        width: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Raleway";
        font-size: 12px;
        font-weight: 500;
        border-radius: 0px;
        text-align: center;
        color: $background;
      }
    }
    .magazine_main_container .magazine_viewarchives_wrapper {
      width: 18%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      padding-bottom: 2em;
  }
  }
}
@import "../../index.scss";

.department_events_screen {
  background-color: $background;
  border-radius: 15px;
  box-shadow: 0px 0px 12px $secondaryLightColor;
}
.department_events_modal {
  background: $background;
  width: 500px;
  height: 60vh;
  border-radius: 16px;
  box-shadow: 0 10 28px 0px $primaryColor;
  padding: 0;

  .close_button{
    outline: none;
  }

  .department_events_modal_title_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1em;

    .department_events_modal_title {
      font-weight: bold;
      margin-left: 1em;
      color: $primaryTextColor;
    }
  }
  .department_events_modal_line_wrapper {
    width: 100%;
    height: 1px;
    background-color: $primaryColor;
  }
  .department_events_modal_input_wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 1em;
    .department_events_modal_input {
      display: flex;
      width: 80%;
      flex-direction: column;
      padding-left: 2em;
      .department_events_input_from_date{
        padding-top: 8px;
        width: 90%;
      }
      .department_events_input_to_date{
        padding-top: 0;
        width: 90%;
      }
      .department_events_input {
        padding-top: 1em;
        width: 90%;
      }
      .department_events_thumbnail_wrapper {
        padding-top: 2em;
      }
      .department-events_modal_uploadbox {
        width: 90%;
        padding-top: 1em;
      }
    }
  }
  .css-g1d714-ValueContainer {
    padding: 0;
  }
  .department_events_button_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
  }
}

.department_events_modal::-webkit-scrollbar {
  display: none;
}
@media only screen and (max-width: 1500px) {
  .department_events_modal{
    height: 65vh;
  }
}
@import '../../index.scss';
.icon {
  filter: brightness(0) invert(1);
  padding-right: 0.7em
}
.footer_component {
  .footer_container {
    background: no-repeat center center fixed;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    color: $secondaryLightColor;
    padding: 1em 13em;
    display: flex;
    justify-content: center;
    position: relative;
    .footer_wrapper {
      width: 20%;
      padding: 0.8em;
      .footer_content {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-between;

        .footer_head {
          padding: 0 0 1em 0;
        }

        .footer_about {
          padding: 0.8em 0 0 0;
          line-height: 18px;

          .footer_social_media {
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }

          .footer_name {
            padding: 0 0 0.8em 0;
            a {
              text-decoration: none;
              color: $secondaryLightColor;
            }
          }

          .footer_address {
            width: 60%;
            padding: 0 0 0.8em 0;
          }

          .footer_phone_content {
            padding: 0 0 1.5em 0;
          }

          .guest {
            text-transform: uppercase;
            font-weight: 500;
          }

          .footer_input_content {
            display: flex;
            padding: 0 0 0.8em 0;
            width: 100%;
            .footer_input {
              outline: none;
              width: 180px;
              padding: 0.8em;
              color: $primaryTextColor;
            }

            .footer_input::placeholder {
              color: $placeHolder;
            }

            .footer_btn {
              background-color: $subscribeButtonColor;
              padding: 0.8em 2em 0.8em 2em;
              cursor: pointer;
            }
          }
          .footer_subscribe_text {
            line-height: 25px;
            padding: 0 0 0.8em 0;
          }
          .footer_desc {
            line-height: 20px;
          }
        }
      }
    }
    .padding_left {
      padding-left: 2em;
    }
  }
  .copy_right_container {
    background-color: $secondaryDarkColor;
    text-align: center;
    padding: 2em;
    font-weight: 500;
    color: $primaryColor;
    .copy_right {
      a {
        text-decoration: none;
        color: $primaryColor;
      }
    }
  }
  @media screen and (max-width: 1050px) {
    .footer_container {
      padding: 2em;
      flex-direction: column;
      background: no-repeat center center;
      background-size: cover;
      .footer_wrapper {
        width: 100%;
      }
    }
    .footer_subscribeModal {
      width: 300px;
      height: 500px;
    }
  }
}
@media screen and (max-width: 1050px) {
  .footer_component {
    .footer_modal_subscribe_container {
      .footer_subscribeModal {
        width: 300px;
        height: 500px;
      }
    }
  }
}
.footer_subscribeModal {
  background: $background;
  width: 100%;
  // height: 500px;
  // border-radius: 16px;
  box-shadow: 0 10 28px 0px $primaryColor;
  // padding: 0;
  background: $primaryColor;
  position: relative;
  z-index: 0;
  margin: 0;
  padding: 1em;

  .footer_subscribeModal::-webkit-scrollbar {
    display: none;
  }

  .footer_subscribe_close_button {
    background: $subscribeButtonColor;
    position: absolute;
    top: 0px;
    right: 0px;
    border: none;
    padding: 0;
    cursor: pointer;
    display: flex;
    fill: white;
  }

  .footer_subscription_close_button_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    top: -1px;
    background: $subscribeButtonColor;
    height: 30px;
    width: 30px;
    z-index: 1;
  }

  .footer_subscription_modal_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 2em;
    .footer_subscription_title_wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;

      .footer_subscription_title {
        color: $background;
      }

      .footer_subscription_line_wrapper {
        margin-top: 1em;
        width: 20%;
        height: 2px;
        background: $background;
      }
    }
    .footer_subscription_description_wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 1em;
      .footer_subscription_description {
        padding-top: 1em;
        color: $background;
        width: 410px;
        text-align: center;
      }
    }
    .footer_subscription_input_wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding-top: 3em;

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      .footer_subscription_input {
        width: 80%;
        height: 40px;
        margin-bottom: 2em;
        outline: none;
        padding-left: 0.5em;
      }

      .footer_subscription_modal_input {
        width: 100%;
        padding-top: 1.5em;
      }
    }
    .footer_subscription_button_wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1em;
      .footer_subscription_button {
        background: $subscribeButtonColor;
        width: 170px;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .footer_wrapper {
    width: 100%;
    margin-top: 1em !important;
  }
}

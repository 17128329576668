@import '../../index.scss';

.about_csi_screen {
  height: 100vh;
  width: 100%;
  overflow: auto;
  background-color: $backgroundColor;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 0;
  .header_wrapper {
    width: 100%;
  }
  .footer_wrapper {
    width: 100%;
  }
  .about_csi_container {
    max-width: 1170px;
    .header_wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 2em;
      .header {
        font-family: 'Merriweather';
        font-weight: 400;
        position: relative;
      }
      .header_line {
        height: 3px;
        width: 172.5px;
        background-color: $primaryColor;
        margin-top: 1rem;
        margin-bottom: 0;
      }
    }
    .tab_wrapper {
      display: flex;
      justify-content: center;
      padding: 2em;
      position: relative;
      position: sticky;
      width: 100%;
      top: 0px;
      background: $backgroundColor;
      &:after {
        content: '';
        position: absolute;
        width: 55%;
        bottom: 0px;
        height: 2px;
        border-bottom: 3px solid $secondaryLightColor;
      }
      .link_active a {
        padding: 1em 2em;
        text-decoration: none;
        color: $subscribeButtonColor;
        font-weight: 500;
        position: relative;
        &:after {
          content: '';
          width: 100%;
          position: absolute;
          left: 0;
          bottom: -9px;
          z-index: 100;
          border-bottom: 2px solid $primaryColor;
        }
      }
      .link a {
        padding: 1em 2em;
        text-decoration: none;
        color: $subscribeButtonColor;
        font-weight: 500;
        position: relative;
      }
    }
    .body_wrapper {
      padding: 1em;
      .section_wrapper {
        padding: 4em 0;
        .section_body_wrapper {
          margin-bottom: 3em;
          .section_title {
            padding-bottom: 1em;
          }
          .section_heading {
            font-family: 'Merriweather';
            font-weight: 400;
          }
          .header_line {
            height: 3px;
            width: 57.5px;
            background-color: $primaryColor;
            margin-top: 1rem;
          }
          .description_wrapper {
            .description {
              line-height: 23px;
              font-family: 'Open Sans', sans-serif;
            }
          }
          .image_wrapper {
            display: flex;
            justify-content: center;
            margin-top: 2em;
            margin-bottom: 0em;
            img {
              width: 481px;
              height: 802px;
            }
          }
          .logo_wrapper {
            margin-bottom: 1em;
            img {
              width: 200px;
              height: 200px;
              object-fit: contain;
            }
          }
        }
      }
      .gallery_wrapper {
        display: flex;
        justify-content: space-around;
        width: 100%;
        padding: 1em 0;
        .gallery_image {
          padding: 1em;
          height: 300px;
          width: 450px;
          img {
            // height: 400px;
            width: 100%;
            height: 100%;
            object-fit: fill;
          }
        }
      }
    }
  }
}
.line_width {
  width: 7% !important;
}
.section_scroll_history {
  // padding-bottom: 1em;
}
.sec_scroll {
  padding-bottom: 1em;
}
.sec_scroll_mission {
  padding-bottom: 3em;
}
.sec_scroll_logo {
  padding-bottom: 2.3em;
}

@media only screen and (max-width: 1500px) {
  .about_csi_screen .about_csi_container .tab_wrapper:after {
    content: '';
    position: absolute;
    width: 50%;
    bottom: 0;
    border-bottom: 3px solid $secondaryLightColor;
  }
  .about_csi_screen .about_csi_container .tab_wrapper .link_1 a:after {
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -5px;
    z-index: 100;
    border-bottom: 2px solid $primaryColor;
  }
  .about_csi_screen
    .about_csi_container
    .body_wrapper
    .gallery_wrapper
    .gallery_image
    img {
    height: 300px;
    object-fit: contain;
  }
}
@media screen and (max-width: 1024px) {
  .about_csi_screen .about_csi_container {
    width: 100%;
  }
  .about_csi_screen .about_csi_container .header_wrapper .header_line {
    height: 3px;
    width: 112.5px;
    background-color: #b5316a;
    margin-top: 1rem;
    margin-bottom: 0;
  }
  .about_csi_screen .about_csi_container .tab_wrapper .link_active a:after {
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -12px;
    z-index: 100;
    border-bottom: 2px solid #b5316a;
  }
  .about_csi_screen .about_csi_container .tab_wrapper:after {
    content: '';
    position: absolute;
    width: 42%;
    bottom: 0;
    border-bottom: 3px solid #fff3fa;
  }
}
@media screen and (max-width: 600px) {
  .about_csi_screen .about_csi_container .header_wrapper .header_line {
    width: 102.5px;
  }
  .section_scroll_history {
    padding-bottom: 3em;
  }
  .line_width {
    width: 20% !important;
  }
  .about_csi_screen .about_csi_container .tab_wrapper {
    padding: 2em 0;
  }
  .about_csi_screen .about_csi_container .tab_wrapper:after {
    content: '';
    position: absolute;
    width: 78%;
    bottom: 0;
    border-bottom: 3px solid #fff3fa;
  }
  .about_csi_screen .about_csi_container .tab_wrapper .link_active a:after {
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -12px;
    z-index: 100;
    border-bottom: 2px solid #b5316a;
  }
  .about_csi_screen
    .about_csi_container
    .body_wrapper
    .section_wrapper
    .section_body_wrapper
    .image_wrapper
    img {
    width: 300px;
    height: 300px;
    object-fit: contain;
  }
  .about_csi_screen .about_csi_container .body_wrapper {
    padding: 0em;
  }
  .about_csi_screen .about_csi_container .body_wrapper .section_wrapper {
    padding: 2em;
  }
  .about_csi_screen .about_csi_container .body_wrapper .gallery_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .about_csi_screen
    .about_csi_container
    .body_wrapper
    .gallery_wrapper
    .gallery_image {
    padding-bottom: 2em;
  }
  .about_csi_screen
    .about_csi_container
    .body_wrapper
    .gallery_wrapper
    .gallery_image
    img {
    height: 200px;
    width: 200px;
  }
  .about_csi_screen
    .about_csi_container
    .body_wrapper
    .section_wrapper
    .section_body_wrapper
    .logo_wrapper
    img {
    width: 100px;
    height: 100px;
  }
  .about_csi_screen .about_csi_container .tab_wrapper .link_active a {
    padding: 1em;
    text-decoration: none;
    color: #000000;
    font-weight: 500;
    position: relative;
  }
  .about_csi_screen .about_csi_container .tab_wrapper .link a {
    padding: 1em;
    text-decoration: none;
    color: #000000;
    font-weight: 500;
    position: relative;
  }
}

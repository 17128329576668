@import "../../../index.scss";

.action_button_container {
  cursor: pointer;
  color: $background;
  background-color: transparent;
  border-radius: 50px;
  width: 180px;

  .action_button_wrapper {
    display: flex;
    justify-content: flex-start;
    background-color: $secondaryLightColor;
    border-radius: 50px;
    .action_button_icon_container {
      background-color: $primaryColor;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      display: flex;
      margin-left: -1px;
      justify-content: center;
      .action_icon {
        width: 15px;
      }
    }

    .action_button_name {
      justify-content: center;
      padding: 0.5em 0.6em 0.4em 0.5em;
      color: $primaryColor;
    }
  }

  .secoundary {
    background-color: $primaryColor;
    .secoundary_color_icon {
      background-color: $secondaryLightColor;
      margin-left: -1px;
      .action_icon_secoundary {
        width: 20px;
      }
    }
    .action_button_name_secoundary {
      padding: 0.5em 0.6em 0.4em 0.5em;
    }
  }
}

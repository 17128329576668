@import '../../index.scss';

.screen {
  height: 100vh;
  overflow: auto;
  background-color: $backgroundColor;
  display: flex;
  flex-direction: column;
  align-items: center;
  .csi_life{
    margin-bottom: 0em;
    width: 120px;
  }
  .header_wrapper {
    width: 100%;
  }
  .select_box_wrapper {
    display: flex;
    justify-content: flex-end;
    .select_box {
      width: 250px;
      padding: 1em;
    }
    .css-z7fgrc-control {
      background-color: $backgroundColor !important;
    }
    .css-1smg336-control {
      background-color: $backgroundColor !important;
    }
  }
  .csi_life_screen_header_wrapper {
    display: flex;
    justify-content: center;
    padding-top: 2em;
    .csi_life_screen_header {
      font-family: 'Merriweather';
      font-weight: 400;
      .header_line {
        height: 3px;
        width: 82.5px;
        background-color: $primaryColor;
        margin-top: 1rem;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0;
      }
    }
  }
  .csi_life_screen_container {
    max-width: 1170px;
    .csi_life_screen_body_wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;

      .card_wrapper {
        width: 33%;
        padding: 2em 2em 0 2em;
      }
    }
  }
  .footer_wrapper {
    width: 100%;
  }
}
.csi_life_pagination_wrapper {
  padding: 3em 0;
}
@media only screen and (max-width: 1024px) {
  .screen .csi_life_screen_container {
    width: 100%;
  }
  .screen
    .csi_life_screen_container
    .csi_life_screen_body_wrapper
    .card_wrapper {
    width: 45%;
    padding: 1em;
  }
  .screen .csi_life_screen_header_wrapper .csi_life_screen_header .header_line {
    width: 82.5px;
  }
}
@media only screen and (max-width: 600px) {
  .screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    .select_box_wrapper {
      display: flex;
      flex-direction: column;
    }
    .csi_life_screen_header_wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      .csi_life_screen_header {
        .header_line {
          width: 80.5px;
        }
      }
    }
    .screen
      .csi_life_screen_container
      .csi_life_screen_body_wrapper
      .card_wrapper {
      width: 100%;
    }
    .csi_life_screen_container {
      .csi_life_screen_body_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    .footer_wrapper {
      width: 100%;
    }
  }
}

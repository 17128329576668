@import "../../../index.scss";

.upload_box_container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: dashed;
  border-color: $placeHolder;
  border-width: 1px;
  border-radius: 15px;

  .upload_box_wrapper{
    padding: 1em;
    .upload_box_label_content {
      display: flex;
      align-items: center;
      color: $placeHolder;
  
      .upload_box_icon_content {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: $placeHolder;
      }
      .upload_box_icon_loading_content {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25px;
        height: 25px;
        border-radius: 50%;
      }
      .upload_box_icon {
        width: 10px;
        height: 10px;
        transform: rotate(270deg);
      }
      .upload_box_uploaded_icon {
        width: 10px;
        height: 10px;
      }
      .upload_box_loading_icon {
        width: 50px;
        height: 50px;
      }
      .upload_box_title {
        margin-left: 0.63em;
  
        .span_link {
          text-decoration: underline;
          text-decoration-color: $placeHolder;
          margin-left: 0.31em;
        }
      }
      .upload_box_input {
        display: none;
      }
    }

  }
}

.upload_box_container_hover {
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: dashed;
  border-color: $primaryColor;
  border-width: 1px;
  border-radius: 15px;
  background-color: $secondaryDarkColor;
}

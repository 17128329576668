@import '../../index.scss';

.add_news_component {
  .add_news_container {
    display: flex;
    .add_news_wrapper {
      // padding: 1.5em 0 3em 2em;
      padding: 1em;
      width: 100%;
      height: 50vh;

      .add_news_form_control {
        .add_news_label_container {
          display: flex;

          .add_news_lable_text {
            color: $primaryColor;
            padding: 0 0 0 1em;
          }
        }
        .add_news_thumbnail_input {
          width: 80%;
          padding-top: 1em;
        }
        .tumbnail_content {
          padding-top: 1em;
          width: 80px;
          .img_wrap {
            position: relative;
            width: 100%;
            .clear {
              position: absolute;
              top: 4px;
              right: 0;
              z-index: 100;
              border-radius: 50%;
              background-color: $secondaryLightColor;
              font-size: 10px;
              padding: 0.175em 0.4em;
              color: $primaryColor;
              outline: none;
              cursor: pointer;
              display: flex;
            }

            .news_thumbnail {
              width: 80px;
              height: 80px;
              object-fit: contain;
              border-radius: 5px;
              margin-left: 5px;
            }
          }
          .gallery_image_wrap {

            .clear {
              right: 4px;
            }
          }
        }
        .gallery_content {
          display: flex;
          float:left;
          width: 85px;
        }
      }
      .space_between {
        padding-top: 1em;
      }
    }

    .add_news_small_wrapper {
      width: 35%;
      padding: 1.5em 2em 3em 2em;
    }
  }
}
.ql-snow .ql-editor img {
  max-width: 500px;
  max-height: 500px;
  display: flex;
}
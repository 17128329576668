@import '../../index.scss';

.swiper_container {
  .news_card_small_overlay {
    background: linear-gradient(
180deg
, rgba(27, 6, 15, 0) 0%, rgba(77, 17, 43, 0) 0%, rgba(16, 0, 7, 0.69) 100%, #0FFFFE 100%);

    position: absolute;
    top: 0;
    z-index: 100;
    width: 100%;
    height: 99%;
    opacity: 0.7;
  }
  display: flex;
  .cukhLH {
    margin: 0;
  }
  .gallery_image_wrapper{
    width: 300px;
    height: 300px;
  }
  .swiper_image_wrapper {
    position: relative;
    outline: none;
    cursor: pointer;
    .swiper_slider_images {
      width: 250px;
      height: 300px;
      object-fit: contain;
    }

    .swiper_text {
      position: absolute;
      bottom: 45px;
      left: 20px;
      color: $background;
      font-weight: 600;
    }
    .swiper_campaigns_text {
      position: absolute;
      bottom: 110px;
      left: 28px;
      z-index: 1;
      color: $background;
      z-index: 101;

      font-weight: 600;
    }
    .swiper_news_text {
      position: absolute;
      bottom: 110px;
      left: 28px;
      z-index: 1;
      color: $background;
      z-index: 101;

      font-weight: 600;
    }
    .swiper_campaigns_order_wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 30px;
      left: 20px;
      height: 50px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      text-align: left;
      -webkit-box-orient: vertical;
      font-weight: 500;
      color: $background;
      z-index: 101;

      .swiper_campaigns_order {
        margin-left: 0.31em;
        width: 300px;
      }
    }
    .swiper_news_order_wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 30px;
      left: 20px;
      height: 50px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      font-weight: 500;
      color: $background;
      z-index: 101;

      .swiper_news_order {
        margin-left: 0.31em;
        width: 300px;
      }
    }
    .swiper_order_wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 20px;
      left: 20px;
      z-index: 101;
      font-weight: 500;
      color: $background;
      text-align: left;

      .swiper_icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 15px;
        height: 15px;
        // border-radius: 50%;
        border: 1px solid $background;

        .swiper_icon {
          width: 8px;
          height: 8px;
        }
      }

      .swiper_order {
        margin-left: 0.31em;
      }
    }

    .swiper_explore_more_wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: 50px;
      left: 20px;
      height: 100px;
      font-weight: 500;
      color: $background;

      .swiper_explore_more {
        width: 250px;
        text-align: center;
        padding-bottom: 5em;
        color: $primaryTextColor;
      }
    }

    .rec-carousel-item {
      padding: 0em 0.19em;
    }
  }
  .swiper_slider_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    .previous_button {
      transform: rotate(180deg);
      margin-left: 1em;
    }
    .next_button {
      margin-right: 1em;
    }
  }

  @media only screen and (min-width: 1500px) {
    .swiper_image_wrapper {
      .swiper_slider_images {
        width: 390px;
        height: 485px;
      }
      .swiper_explore_more_wrapper {
        .swiper_explore_more {
          width: 150px;
          padding-bottom: 5em;
          color: $primaryTextColor;
        }
      }
    }
  }

  @media only screen and (max-width: 1050px) {
    .swiper_image_wrapper {
      .swiper_slider_images {
        width: 270px;
        height: 400px;
      }
    }
  }
}

.swiperModalRoot{
  width: 100%;
  z-index: 100000000000;
  .swiperModal {
    background: rgba(0, 0, 0, 0.0);
    // border-radius: 10px;
    box-shadow: none;
    overflow-y: auto;
    // padding: 0;
    max-width: 100%;
    width: 100%;
    height: 70%;
    // padding-left: 5em;
    // padding-right: 5em;
    margin: 0;
    padding: 0;
    z-index: 10000000000000000000;

    .closeButton{
      display: none;
      position: absolute;
      right: 5px;
      // top: -5px;
      z-index: 10000000000000000;
    }
  
    .swiper_modal_gallery_container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      position: relative;
      .swiper_modal_left_button {
        display: flex;
        align-items: center;
        position: absolute;
        left: 10px;
        cursor: pointer;
        z-index: 1;
        height: 10%;
        width: 30px;
        // background-color: rgba(0, 0, 0, 0.5);
        .left_button {
          width: 23px;
          height: 23px;
          transform: rotate(90deg);
          filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(118deg) brightness(100%) contrast(105%);
        }
      }
      .swiper_modal_right_button {
        display: flex;
        align-items: center;
        position: absolute;
        right: 10px;
        cursor: pointer;
        // background-color: rgba(0, 0, 0, 0.5);
        height: 10%;
        width: 30px;
        padding-left: 0.7em;
        .right_button {
          width: 23px;
          height: 23px;
          transform: rotate(-90deg);
          filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(118deg) brightness(100%) contrast(105%);
        }
      }
      .swiper_modal_image_wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 50%;
        height: 90%;
        padding-top: 1em;
        padding-bottom: 2em;
       
        .swiper_modal_information{
          display: flex;
          justify-content: flex-end;
          position: absolute;
          bottom: 0px;
          right: 0px;
          align-items: center;
          color: $background;
          z-index: 10000000000;
          // background-color: whitesmoke;
        }

        .swiper_modal_close_icon{
          position: absolute;
          top: -30px;
          right: -5px;
          width: 30px;
          height: 30px;
          z-index: 1;
          cursor: pointer;
          filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(118deg) brightness(100%) contrast(105%);

        }
        .swiper_banner {
          width: 100%;
          height: 100%;
          object-fit: contain;
          // padding: 0.3em;
          // border-radius: 10px;
        }
      }
      .advertisement_button_wrapper {
        position: absolute;
        bottom: 20px;
      }
    }
  }
}

.swiperModal::-webkit-scrollbar {
  display: none;
}

.swiperOverlay{
  // background: transparent;
  width: 100%;
}

.publication_overlay{
  background: linear-gradient(180deg, rgba(27, 6, 15, 0.22) 0%, rgba(77, 17, 43, 0.22) 20%, rgba(16, 0, 7, 0.0) 60%, #100007 100%);
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  top: 0;
}

@media screen and (max-width: 600px) {
  .swiper_container .swiper_image_wrapper .swiper_campaigns_text {
    left: 20px;
    z-index: 101;
    bottom: 90px;
  }
  .swiper_container .swiper_image_wrapper .swiper_campaigns_order_wrapper {
    bottom: 30px;
    left: 2px;
    z-index: 101;
  }
  .swiper_container
    .swiper_image_wrapper
    .swiper_campaigns_order_wrapper
    .swiper_campaigns_order {
    font-size: 20px;
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .swiper_container .swiper_image_wrapper .swiper_news_text {
    left: 35px;
    z-index: 101;
    bottom: 90px;
  }

  .swiper_container .swiper_image_wrapper .swiper_news_order_wrapper {
    bottom: 30px;
    left: 29px;
    z-index: 101;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .swiper_container
    .swiper_image_wrapper
    .swiper_news_order_wrapper
    .swiper_news_order {
    font-size: 17px;
  }
  .swiper_container .swiper_slider_wrapper {
    margin-right: 1em;
    // display: none;
}
.swiper_container .swiper_slider_wrapper {
  margin-right: 1em;
  // display: none;
}
.swiperModalRoot .swiperModal .swiper_modal_gallery_container .swiper_modal_image_wrapper .swiper_banner {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
}
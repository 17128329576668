@import '../../index.scss';

.screen {
  height: 100vh;
  overflow: auto;
  background-color: $backgroundColor;
  display: flex;
  flex-direction: column;
  align-items: center;
  .header_wrapper {
    width: 100%;
  }
  .publication_screen_container {
    max-width: 1170px;
    .publication_screen_header_wrapper {
      display: flex;
      justify-content: center;
      padding-top: 2em;
      .publication_screen_header {
        font-family: 'Merriweather';
        font-weight: 400;
        .header_line {
          height: 3px;
          width: 152.5px;
          background-color: $primaryColor;
          margin-top: 1rem;
          margin-bottom: 0;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
    .publication_screen_body_wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      .publication_card_wrapper {
        width: 33%;
        padding: 2em 2em 0 2em;
      }
    }
  }
  .footer_wrapper {
    width: 100%;
  }
  .publications_pagination_wrapper {
    padding: 3em 0;
  }
}
@media only screen and (max-width: 1024px) {
  .screen .publication_screen_container {
    width: 100%;
  }
  .screen
    .publication_screen_container
    .publication_screen_body_wrapper
    .publication_card_wrapper {
    width: 45%;
    padding: 1em;
  }
  .screen
    .publication_screen_container
    .publication_screen_header_wrapper
    .publication_screen_header
    .header_line {
    height: 3px;
    width: 128.5px;
    background-color: #b5316a;
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
  }
}
@media only screen and (max-width: 600px) {
  .screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    .header_wrapper {
      width: 100%;
    }
    .select_box_wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      .select_box {
        width: 150px;
        padding: 1em;
      }
      .css-z7fgrc-control {
        background-color: $backgroundColor !important;
      }
      .css-1smg336-control {
        background-color: $backgroundColor !important;
      }
    }
    .publication_screen_header_wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 2em;
    }
    .publication_screen_container {
      max-width: 1170px;
      .publication_screen_body_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        .publication_card_wrapper {
          width: 100% !important;
          padding-bottom: 2em;
        }
      }
    }
    .footer_wrapper {
      width: 100%;
    }
  }
}

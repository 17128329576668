@import '../../index.scss';

.department_screen {
  height: 100vh;
  width: 100%;
  overflow: auto;
  background-color: $backgroundColor;
  display: flex;
  flex-direction: column;
  align-items: center;
  .department_screen_footer_wrapper {
    width: 100%;
  }
  .header_wrapper {
    width: 100%;
  }
  .department_screen_container {
    display: flex;
    flex-direction: column;
    max-width: 1170px;
    align-items: center;
    justify-content: flex-start;
    .department_screen_header_wrapper {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-top: 2em;
      .department_screen_header {
        height: 44px;
        font-family: 'Merriweather';
        font-weight: 400;
      }
      .header_line {
        height: 3px;
        width: 170px;
        background-color: $primaryColor;
        margin-top: 1em;
      }
    }
    .department_screen_title_wrapper {
      margin-top: 1em;
      .department_screen_title {
        display: flex;
        justify-content: center;
        .title_links_wrapper {
          display: flex;
          padding: 1rem;
          justify-content: center;
          position: relative;
          &:after {
            content: '';
            width: 95%;
            position: absolute;
            bottom: 0px;
            left: 15px;
            height: 2px;
            border-bottom: 3px solid $secondaryLightColor;
          }
          .link_1 {
            padding-right: 2em;
            position: relative;
            font-weight: 400;
            .title_link {
              text-decoration: none;
              color: $subscribeButtonColor;
            }
          }
          .link {
            position: relative;
            font-weight: 400;
            .title_link {
              text-decoration: none;
              color: $subscribeButtonColor;
            }
          }
        }
      }
    }
    .department_body_wrapper {
      display: flex;
      margin-top: 5em;
      .department_left {
        width: 65%;
        padding-right: 2em;
        display: flex;
        flex-direction: column;
        .left_title_wrapper {
          display: flex;
          flex-direction: column;
          .left_heading {
            font-family: 'Merriweather' !important;
            margin-bottom: 1em;
            font-weight: 400;
          }
          .header_line_left {
            height: 3px;
            width: 70px;
            background-color: $primaryColor;
            margin-bottom: 1.4em;
          }
        }
        .content_left {
          height: 100%;
          .left_card1_wrapper {
            height: 315px;
            width: 100%;
            margin-bottom: 40px;
          }
          .events_card_wrapper_left {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .card_left {
              width: 364px;
              // padding: 1em;
              margin-bottom: 2em;
            }
          }
        }
      }
      .department_right {
        width: 35%;
        padding-right: 2em;
        display: flex;
        flex-direction: column;
        .right_title_wrapper {
          display: flex;
          flex-direction: column;
          .left_heading {
            font-family: 'Merriweather' !important;
            margin-bottom: 1em;
            font-weight: 400;
          }
        }
        .card_wrapper_right {
          height: 100%;
          width: 100%;
        }

        .header_line_right {
          height: 3px;
          width: 60px;
          background-color: $primaryColor;
          margin-bottom: 1.4em;
        }
        .card_right {
          margin-bottom: 2em;
        }
        .news_card_1_container .news_card_1_wrapper .overlay_news_card {
          background: none;
          position: absolute;
          top: 0;
          z-index: 100;
          width: 100%;
          height: 100%;
          opacity: 0.7;
        }
        .card {
          margin-bottom: 1em;
        }
      }
    }
  }
}

.button_wrapper {
  padding: 1em 0;
  display: flex;
  justify-content: center;
  .button_component {
    background-color: $primaryColor;
  }
}
.color {
  width: 180px !important;
}

.header_line {
  height: 3px;
  width: 100px;
  background-color: $primaryColor;
  margin-top: 1em;
  margin-bottom: 2em;
}
.left_description {
  padding: 0;
}

.selected {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: $primaryColor;
    bottom: -16px;
    z-index: 1;
    left: 0;
  }
}
@media screen and (max-width: 1024px) {
  .department_screen .department_screen_container {
    display: flex;
    flex-direction: column;
    width: 93%;
    align-items: center;
    justify-content: flex-start;
  }
  .department_screen
    .department_screen_container
    .department_screen_title_wrapper {
    margin-top: 0em;
  }
  .department_screen
    .department_screen_container
    .department_body_wrapper
    .department_left
    .content_left
    .left_card1_wrapper {
    height: 249px;
    width: 100%;
    margin-bottom: 11px;
  }
  .news_card_3_component
    .news_card_3_container
    .news_card_3_wrapper
    .news_card_3_media
    .news_card_3_img {
    height: 251px;
  }
  .department_screen
    .department_screen_container
    .department_screen_header_wrapper
    .department_screen_header {
    height: 20px;
    font-family: 'Merriweather';
    font-weight: 400;
  }
  .department_screen
    .department_screen_container
    .department_screen_header_wrapper
    .header_line {
    height: 3px;
    width: 150px;
    background-color: #b5316a;
    margin-top: 1em;
  }
}
@media screen and(max-width: 600px) {
  .department_screen .department_screen_container {
    display: flex;
    flex-direction: column;
    width: 100vw;
    align-items: center;
    justify-content: flex-start;
  }
  .department_screen
    .department_screen_container
    .department_screen_title_wrapper
    .department_screen_title
    .title_links_wrapper
    .link {
    position: relative;
    font-weight: 400;
    font-size: 10px;
  }
  .department_screen
    .department_screen_container
    .department_screen_header_wrapper
    .department_screen_header {
    height: 20px;
    font-family: 'Merriweather';
    font-weight: 400;
    font-size: 17px;
  }
  .department_screen
    .department_screen_container
    .department_screen_title_wrapper {
    margin-top: 0em;
    width: 100%;
  }
  .department_screen
    .department_screen_container
    .department_screen_title_wrapper
    .department_screen_title
    .title_links_wrapper
    .link_1 {
    padding-right: 2em;
    position: relative;
    font-weight: 400;
    font-size: 10px;
  }
  .department_screen .department_screen_container .department_body_wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 2em;
    padding: 1em;
  }
  .department_screen
    .department_screen_container
    .department_body_wrapper
    .department_left {
    width: 100%;
    padding-right: 0em;
    display: flex;
    flex-direction: column;
  }
  .department_screen
    .department_screen_container
    .department_body_wrapper
    .department_left
    .content_left
    .left_card1_wrapper {
    height: 160px;
    width: 100%;
    margin-bottom: 10px;
  }
  .department_screen
    .department_screen_container
    .department_body_wrapper
    .department_left
    .content_left
    .events_card_wrapper_left
    .card_left {
    width: 100;
    margin-bottom: 2em;
  }
  .department_screen
    .department_screen_container
    .department_body_wrapper
    .department_left
    .content_left
    .events_card_wrapper_left
    .card_left {
    width: 100%;
    margin-bottom: 2em;
  }
  .department_screen
    .department_screen_container
    .department_body_wrapper
    .department_right {
    width: 100%;
    padding-right: 0em;
    display: flex;
    flex-direction: column;
  }
}
.news_card_3_component
  .news_card_3_container
  .news_card_3_wrapper
  .news_card_3_media
  .news_card_3_img {
  width: 100%;
  height: 288px !important;
  object-fit: cover;
}

@import '../../index.scss';
.officer_card_container .officer_card_wrapper .officer_image_wrapper img {
  width: 280px !important;
  height: 420px !important;
}
.office_info_wrapper {
  width: 350px;
  display: flex;
  flex-direction: column;
}
.diocese_screen .officer_card_container{
  height: 700px;
}
.diocese_screen {
  width: 100%;
  scroll-behavior: smooth;
  height: 100vh;
  overflow: auto;
  background: $dioceseBackground;
  .diocese_container {
    .diocese_wrapper {
      .diocese_header_wrapper {
        display: flex;
        justify-content: space-around;
        align-items: center;
        background: $secondaryLightColor;
        height: 270px;
        .diocese_header_left_icon_wrapper {
          .diocese_header_left_icon {
            width: 150px;
            height: 150px;
            cursor: pointer;
          }
        }
        .diocese_header_title_wrapper {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          .diocese_header_title {
            padding-bottom: 0.3em;
            color: $dioceseBackground;
          }
          .diocese_header_description {
            font-weight: 300;
          }
        }
        .diocese_header_right_icon_wrapper {
          .diocese_header_right_icon {
            width: 150px;
            height: 150px;
            cursor: pointer;
          }
        }
      }
      .diocese_navbar_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 1em;
        padding-bottom: 1em;
        position: sticky;
        top: 0;
        z-index: 100000000;
        background: $dioceseBackground;
        a {
          overflow: hidden;
          text-decoration: none;
          padding-left: 5em;
          font-size: 17px;
          color: $background;
        }
      }
      .diocese_home_image_wrapper {
        width: 100%;
        padding-bottom: 0;
        .diocese_home_image {
          width: 100%;
          height: 550px;
          object-fit: cover;
        }
      }
      .diocese_home_body_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: $backgroundColor;
        margin-top: -4px;
        .diocese_home_body {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          // background: cornsilk;
          max-width: 1170px;

          .diocese_nandyal_dioceses_message {
            padding-top: 3em;
          }

          .diocese_nandayal_dioceses_wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-top: 5em;
            .diocese_nandayal_dioceses_title {
              font-family: 'Merriweather';
              font-weight: 400;
            }
            .diocese_nandayal_diocese_image_wrapper {
              padding-top: 3em;
              .diocese_nandayal_diocese_image {
                // width: 320px;
                // height: 450px;
                // object-fit: cover;
                width: 374px;
                height: 499px;
                object-fit: cover;
              }
            }
            .diocese_nandayal_dioceses_line_wrapper {
              height: 2px;
              width: 95px;
              background: $dioceseBackground;
              margin-top: 0.5em;
              margin-bottom: 1em;
            }
            .diocese_nandayal_diocese_name {
              // font-weight: 900;
              padding-top: 1em;
            }
            .diocese_nandayal_diocese_info {
              font-weight: 400;
              padding-top: 1em;
            }
            .diocese_nandayal_diocese_description {
              font-weight: 300;
              padding-top: 1em;
            }
          }
          .diocese_officers_wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 80%;
            padding-top: 5em;
            .diocese_officers_title {
              font-family: 'Merriweather';
              font-weight: 400;
            }
            .diocese_officers_line_wrapper {
              height: 2px;
              width: 95px;
              background: $dioceseBackground;
              margin-top: 0.5em;
            }
            .diocese_officers_information_wrapper {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-evenly;
              align-items: center;
              padding-top: 2em;
              width: 100%;
              .diocese_officers_information {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                padding: 1em;

                .diocese_officers_information_image_wrapper {
                  .diocese_officers_information_image {
                    width: 250px;
                    height: 320px;
                    object-fit: cover;
                  }
                }

                .diocese_officers_information_name_wrapper {
                  padding-top: 1.5em;
                  .diocese_officers_information_name {
                  }
                  .diocese_officers_information_mobile {
                    padding-top: 1em;
                    font-weight: 400;
                  }
                  .diocese_officers_information_email {
                    padding-top: 0.3em;
                    font-weight: 400;
                  }
                }
              }
            }
          }
          .diocese_about_us_wrapper {
            padding-top: 5em;
            .diocese_about_us_description {
              font-weight: 400;
              line-height: 1.5;
            }
            .diocese_about_us_jurisdiction_wrapper {
              padding-top: 3em;
              .diocese_about_us_title {
                font-family: 'Merriweather';
                font-weight: 400;
              }
              .diocese_about_us_line_wrapper {
                height: 2px;
                width: 95px;
                background: $dioceseBackground;
                margin-top: 0.1em;
              }
              .diocese_about_us_description {
                padding-top: 2em;
              }
            }
            .diocese_about_us_committees_wrapper {
              padding-top: 3em;
              .diocese_about_us_title {
                font-family: 'Merriweather';
                font-weight: 400;
              }
              .diocese_about_us_line_wrapper {
                height: 2px;
                width: 95px;
                background: $dioceseBackground;
                margin-top: 0.1em;
              }
              .diocese_about_us_description {
                padding-top: 2em;
              }
            }
            .diocese_about_us_boards_wrapper {
              padding-top: 3em;
              .diocese_about_us_title {
                font-family: 'Merriweather';
                font-weight: 400;
              }
              .diocese_about_us_line_wrapper {
                height: 2px;
                width: 60px;
                background: $dioceseBackground;
                margin-top: 0.1em;
              }
              .diocese_about_us_description {
                padding-top: 2em;
              }
            }
            .diocese_about_us_bishop_wrapper {
              padding-top: 3em;
              .diocese_about_us_title {
                font-family: 'Merriweather';
                font-weight: 400;
              }
              .diocese_about_us_line_wrapper {
                height: 2px;
                width: 95px;
                background: $dioceseBackground;
                margin-top: 0.1em;
              }
              .diocese_about_us_description_wrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                .diocese_about_us_description {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  line-height: 4;
                  img {
                    height: 350px;
                    width: 350px;
                    object-fit: cover;
                  }
                  .bishop_worked_separation {
                    padding-left: 1em;
                  }
                  .bishop_worked_date {
                    padding-left: 1em;
                  }
                }
              }
            }
          }
          .diocese_ministerial_role_wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-top: 5em;
            .diocese_ministerial_role_heading {
              font-family: 'Merriweather';
              font-weight: 400;
            }
            .diocese_ministerial_role_line_wrapper {
              height: 2px;
              width: 120px;
              background: $dioceseBackground;
              margin-top: 0.1em;
            }
            .diocese_ministerial_role_pdf_wrapper {
              display: flex;
              justify-content: center;
              align-items: center;
              padding-top: 3em;
              width: 100%;
              .diocese_about_us_ministerial_role_pdf {
                background: $background;
              }
            }
            .diocese_ministerial_role_message {
              padding-top: 1em;
            }
          }
          .diocese_news_events_wrapper {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            width: 100% !important;
            padding-top: 5em;
            height: 100%;

            .diocese_news_events_title_wrapper {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              .diocese_news_events_title {
                font-family: 'Merriweather';
                font-weight: 400;
              }
              .diocese_news_events_line_wrapper {
                height: 2px;
                width: 95px;
                background: $dioceseBackground;
                margin-top: 0.1em;
              }
            }
            .diocese_news_events_content_wrapper {
              display: flex;
              justify-content: center;
              align-items: flex-start;
              width: 100%;
              height: 100%;
              padding-top: 3em;
              .dioceses_news_events_content_news {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                height: 100%;
                padding-right: 0.5em;
                .dioceses_news_events_up_news_card {
                  // height: 480px;
                  // max-height: 70vh;
                  width: 100%;
                  .diocese_news_card_image {
                    width: 100%;
                  }
                }
                .dioceses_news_events_down_news_card {
                  padding-top: 1em;
                  width: 100%;
                }
                .diocese_news_events_load_more_news_button_wrapper {
                  width: 180px;
                  height: 100%;
                  padding-top: 1.5em;
                  .diocese_button {
                    background: $dioceseBackground;
                  }
                }
              }
              .dioceses_news_events_content_events {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                width: 45%;
                padding-left: 0.5em;

                .dioceses_news_events_card {
                  width: 100%;
                  padding-bottom: 2em;
                  height: 100%;
                }
                .diocese_news_events_load_more_event_button_wrapper {
                  width: 180px;
                  height: 100%;
                  padding-top: 0em;
                  .diocese_button {
                    background: $dioceseBackground;
                  }
                }
                .dioceses_news_events_content_message {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
              }
            }
          }
        }
        .diocese_home_gallery_wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 80%;
          padding-top: 5em;
          // background-color: chartreuse;
          .diocese_home_gallery_title {
            font-family: 'Merriweather';
            font-weight: 400;
          }
          .diocese_home_gallery_line_wrapper {
            height: 2px;
            width: 70px;
            background: $dioceseBackground;
            margin-top: 0.1em;
          }
          .diocese_home_gallery_content {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 2em;
            width: 100%;
            height: 20%;
            .diocese_home_gallery_swiper {
              width: 350px;
              height: 200px;
            }
          }
        }
        .diocese_get_in_touch_wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding-top: 5em;
          width: 100%;
          .diocese_get_in_touch_title {
            font-family: 'Merriweather';
            font-weight: 400;
          }
          .diocese_get_in_touch_line_wrapper {
            height: 2px;
            width: 90px;
            background: $dioceseBackground;
            margin-top: 0.1em;
          }
          .diocese_get_in_touch_content {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 95vw;
            padding-top: 2em;
            .diocese_get_in_touch_description_wrapper {
              display: flex;
              justify-content: flex-start;
              padding: 0em 5em;
              align-items: center;
              width: 33%;
              .diocese_get_in_touch_description_content {
                display: flex;
                height: 450px;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                .diocese_get_in_touch_office_address {
                  line-height: 1.5;
                }
                .diocese_get_in_touch_office_mobile_contact {
                  padding-top: 1em;
                  line-height: 2;
                }
                .diocese_get_in_touch_office_mobile_contact_email {
                  padding-top: 1em;
                  line-height: 1.5;
                }
              }
            }
            .diocese_get_in_touch_map_wrapper {
              width: 33%;
              padding-right: 2em;
              align-items: flex-start;
              justify-content: flex-start;
            }
          }
        }
        .diocese_question_wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          .diocese_question_title_wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding-top: 5em;
            .diocese_question_title {
              font-family: 'Merriweather';
              font-weight: 400;
            }
            .diocese_question_line_wrapper {
              height: 2px;
              width: 116px;
              background: $dioceseBackground;
              margin-top: 0.5em;
            }
            .diocese_question_content_wrapper {
              // background-color: $secondaryDarkColor;
              width: 100%;
              .diocese_question_input {
                padding-top: 2em;
                width: 100%;
              }
              .diocese_question_submit_button {
                width: 150px;
                padding-top: 15em;

                .diocese_question_button {
                  background: $dioceseBackground;
                }

                .diocese_question_submit_button_wrapper {
                  padding-top: 4em;
                }
              }
            }
          }
        }
        .diocese_footer_wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 7em;
          width: 100%;
          height: 100px;
          background: $dioceseBackground;

          .diocese_footer_content {
            color: $background;
            font-weight: 400;
          }
        }
      }
    }
  }
}
.diocese_about_us_description {
  img {
    width: 350px !important;
    height: 350px !important;
    object-fit: cover;
  }
}
@media only screen and (min-width: 1500px) {
  .diocese_container {
    .diocese_wrapper {
      .diocese_news_events_wrapper {
        width: 100%;
        .diocese_news_events_content_wrapper {
          .dioceses_news_events_content_news {
            .dioceses_news_events_up_news_card {
              height: 44.6vh;
            }
          }
          .dioceses_news_events_content_events {
            .dioceses_news_events_card {
              height: 44vh;
              .diocese_event_card {
                background: $dioceseBackground;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1500px) {
  .diocese_container {
    .diocese_wrapper {
      .diocese_news_events_wrapper {
        width: 100%;
        .diocese_news_events_content_wrapper {
          .dioceses_news_events_content_news {
            .dioceses_news_events_up_news_card {
              height: 44.6vh;
            }
          }
          .dioceses_news_events_content_events {
            .dioceses_news_events_card {
              height: 44;
              .diocese_event_card {
                background: $dioceseBackground;
              }
            }
          }
        }
      }
    }
  }
  .diocese_screen
    .diocese_container
    .diocese_wrapper
    .diocese_home_body_wrapper
    .diocese_home_gallery_wrapper {
    -webkit-flex-direction: column;
    flex-direction: column;
    width: 100%;
    padding-top: 5em;
  }
}
.mobile_diocese_navbar_container {
  display: none;
}

@media screen and (max-width: 1024px) {
  .diocese_screen
    .diocese_container
    .diocese_wrapper
    .diocese_home_body_wrapper
    .diocese_home_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .diocese_screen
    .diocese_container
    .diocese_wrapper
    .diocese_home_body_wrapper
    .diocese_home_body
    .diocese_about_us_wrapper {
    width: 100%;
    padding: 5em;
  }
  .diocese_screen
    .diocese_container
    .diocese_wrapper
    .diocese_home_body_wrapper
    .diocese_home_body
    .diocese_news_events_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 2em;
    height: 100%;
    width: 100%;
  }
  .diocese_screen
    .diocese_container
    .diocese_wrapper
    .diocese_news_events_wrapper
    .diocese_news_events_content_wrapper
    .dioceses_news_events_content_news
    .dioceses_news_events_up_news_card {
    height: 30.6vh;
  }
}
@media screen and (max-width: 1024px) {
  .diocese_screen
    .diocese_container
    .diocese_wrapper
    .diocese_home_body_wrapper
    .diocese_home_gallery_wrapper
    .diocese_home_gallery_content
    .diocese_home_gallery_swiper {
    width: 150px;
    height: 163px;
  }
  .diocese_screen
    .diocese_container
    .diocese_wrapper
    .diocese_home_body_wrapper
    .diocese_get_in_touch_wrapper
    .diocese_get_in_touch_content
    .diocese_get_in_touch_map_wrapper {
    width: 100%;
    padding-right: 0em;
  }
  .diocese_screen
    .diocese_container
    .diocese_wrapper
    .diocese_navbar_wrapper
    a {
    overflow: hidden;
    text-decoration: none;
    padding: 1em;
    font-size: 17px;
    color: #fff;
  }
}
@media screen and (max-width: 768px) {
  .diocese_screen
    .diocese_container
    .diocese_wrapper
    .diocese_home_body_wrapper
    .diocese_get_in_touch_wrapper
    .diocese_get_in_touch_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 95vw;
    padding-top: 2em;
  }
  .diocese_screen
    .diocese_container
    .diocese_wrapper
    .diocese_home_body_wrapper
    .diocese_get_in_touch_wrapper
    .diocese_get_in_touch_content
    .diocese_get_in_touch_map_wrapper {
    width: 80%;
    padding-right: 0em;
  }
  .diocese_screen
    .diocese_container
    .diocese_wrapper
    .diocese_home_body_wrapper
    .diocese_get_in_touch_wrapper
    .diocese_get_in_touch_content
    .diocese_get_in_touch_description_wrapper
    .diocese_get_in_touch_description_content {
    display: flex;
    height: auto;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .diocese_screen .diocese_container .diocese_wrapper .diocese_home_body_wrapper .diocese_get_in_touch_wrapper .diocese_get_in_touch_content .diocese_get_in_touch_description_wrapper {
    display: flex;
    justify-content: flex-start;
    padding: 2em 0;
    align-items: center;
    width: 33%;
}
}
.swiper_container .swiper_image_wrapper {
  align-items: center;
  position: relative;
  display: flex;
  justify-content: center;
  outline: none;
  cursor: pointer;
}
@media screen and (max-width: 600px) {
  .diocese_screen
    .diocese_container
    .diocese_wrapper
    .diocese_home_image_wrapper
    .diocese_home_image {
    width: 100%;
    height: 193px;
    object-fit: cover;
  }
  .diocese_screen .diocese_container .diocese_wrapper .diocese_home_body_wrapper .diocese_get_in_touch_wrapper .diocese_get_in_touch_content .diocese_get_in_touch_description_wrapper .diocese_get_in_touch_description_content {
    display: flex;
    height: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
  .diocese_screen
    .diocese_container
    .diocese_wrapper
    .diocese_home_body_wrapper
    .diocese_home_body
    .diocese_officers_wrapper
    .diocese_officers_information_wrapper {
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: center;
    padding-top: 2em;
    padding: 2em;
    width: 100%;
  }
  .diocese_screen
    .diocese_container
    .diocese_wrapper
    .diocese_home_body_wrapper
    .diocese_home_body
    .diocese_nandayal_dioceses_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 2em;
  }
  .diocese_screen
    .diocese_container
    .diocese_wrapper
    .diocese_home_body_wrapper
    .diocese_home_body
    .diocese_nandayal_dioceses_wrapper
    .diocese_nandayal_diocese_image_wrapper
    .diocese_nandayal_diocese_image {
    width: 250px;
    height: 320px;
    object-fit: cover;
  }
  .diocese_screen
    .diocese_container
    .diocese_wrapper
    .diocese_home_body_wrapper
    .diocese_home_body
    .diocese_about_us_wrapper {
    width: 100%;
    padding: 2em;
  }
  .diocese_screen {
    .diocese_container .diocese_wrapper .diocese_navbar_wrapper {
      display: none;
    }
    .mobile_diocese_navbar_container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 40px;
      position: relative;
      position: sticky;
      top: 0;
      z-index: 100000000;
      background: $dioceseBackground;
      .mobile_diocese_navbar_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        .mobile_diocese_navbar_icon_container {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          width: 80%;
        }
      }
    }
    .mobile_diocese_navbar_content_wrapper {
      position: absolute;
      width: 100%;
      top: 1px;
      z-index: 10000000000000000000;
      background-color: $dioceseBackground;
      height: 500px;
      .mobile_diocese_navbar_content_close_icon_wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        width: 100%;
        position: relative;
        padding-top: 2em;
        .mobile_diocese_navbar_content_close_icon {
          width: 30px;
          height: 30px;
          position: absolute;
          top: 25px;
          right: 25px;
          filter: invert(100%) sepia(0%) saturate(7494%) hue-rotate(345deg)
            brightness(99%) contrast(105%);
        }
      }
      .mobile_diocese_navbar_content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        height: 485px;
        padding-top: 4em;
        a {
          overflow: hidden;
          text-decoration: none;
          padding-top: 1em;
          font-size: 20px;
          color: $background;
        }
        .mobile_diocese_navbar_content_title {
          line-height: 2;
          color: $background;
          font-weight: 450;
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .diocese_screen
    .diocese_container
    .diocese_wrapper
    .diocese_home_body_wrapper
    .diocese_home_body
    .diocese_news_events_wrapper
    .diocese_news_events_content_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    padding-top: 3em;
  }
  .diocese_screen
    .diocese_container
    .diocese_wrapper
    .diocese_home_body_wrapper
    .diocese_home_body
    .diocese_news_events_wrapper
    .diocese_news_events_content_wrapper
    .dioceses_news_events_content_news {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-right: 0.5em;
  }
  .diocese_screen
    .diocese_container
    .diocese_wrapper
    .diocese_home_body_wrapper
    .diocese_home_body
    .diocese_news_events_wrapper
    .diocese_news_events_content_wrapper
    .dioceses_news_events_content_events {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding-left: 0.5em;
  }
  .diocese_screen
    .diocese_container
    .diocese_wrapper
    .diocese_home_body_wrapper
    .diocese_get_in_touch_wrapper
    .diocese_get_in_touch_content {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 2em;
  }
  .diocese_screen
    .diocese_container
    .diocese_wrapper
    .diocese_home_body_wrapper
    .diocese_get_in_touch_wrapper
    .diocese_get_in_touch_content
    .diocese_get_in_touch_description_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
    width: 100%;
  }
  .diocese_screen
    .diocese_container
    .diocese_wrapper
    .diocese_home_body_wrapper
    .diocese_home_body
    .diocese_about_us_wrapper
    .diocese_about_us_jurisdiction_wrapper
    .diocese_about_us_line_wrapper {
    height: 2px;
    width: 60px;
    background: #590006;
    margin-top: 0.1em;
  }
  .diocese_screen
    .diocese_container
    .diocese_wrapper
    .diocese_home_body_wrapper
    .diocese_home_body
    .diocese_officers_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 5em;
  }
  .diocese_header_left_icon_wrapper {
    width: 88px;
  }
  .diocese_screen
    .diocese_container
    .diocese_wrapper
    .diocese_header_wrapper
    .diocese_header_left_icon_wrapper
    .diocese_header_left_icon {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  .diocese_header_right_icon_wrapper {
    width: 88px;
  }
  .diocese_screen
    .diocese_container
    .diocese_wrapper
    .diocese_header_wrapper
    .diocese_header_right_icon_wrapper
    .diocese_header_right_icon {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  .diocese_screen
    .diocese_container
    .diocese_wrapper
    .diocese_header_wrapper
    .diocese_header_title_wrapper
    .diocese_header_description {
    font-weight: 400;
    font-size: 12px;
  }
  .diocese_screen .diocese_container .diocese_wrapper .diocese_header_wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: #fff3fa;
    height: 118px;
  }
}
.officer_card_container .officer_card_wrapper .officer_image_wrapper img {
  width: 280px;
  height: 400px;
  object-fit: cover;
}
.diocese_get_in_touch_description_content .diocese_question_input {
  padding-top: 2em;
  width: 100%;
}
.diocese_get_in_touch_description_content .diocese_question_submit_button {
  width: 150px;
  padding-top: 5em;
}
.diocese_get_in_touch_description_content
  .diocese_question_submit_button
  .diocese_question_button {
  background: #590006;
}
.diocese_question_title {
  padding: 8px 0;
}
.diocese_question_line_wrapper {
  height: 2px;
  width: 180px;
  background: $dioceseBackground;
  margin-top: 0.5em;
}
.diocese_screen
  .diocese_container
  .diocese_wrapper
  .diocese_home_body_wrapper
  .diocese_get_in_touch_wrapper
  .diocese_get_in_touch_content
  .diocese_get_in_touch_description_wrapper
  .diocese_get_in_touch_description_content
  .diocese_get_in_touch_office_address {
  line-height: 1.5;
  margin-top: 4em;
}
.diocese_question_title {
  font-size: 28px;
}

@import '../../index.scss';

.resources_card_wrapper {
  // width: 50%;
  .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1em 0;
    transition: transform  0.3s ease-in-out;
    &:hover{
      transform: scale(1.1);
      transition: transform 0.3s ease-in-out;
    }
    .resources_card {
      background-color: $backgroundColor;
      display: flex;
      text-align: center;
      justify-content: center;
      position: relative;
      align-items: center;

      .partners_img_wrapper {
        img {
          object-fit: contain;
          height: 300px;
          width: 350px;
          // padding: 2em;
        }
      }
    }

    .title_wrapper {
      padding: 1em;
      width: 300px;
      text-align: center;
    }
    .title {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }

.resource_image {
  .text {
    background-color: $primaryColor;
    padding: 1em 3em;
    z-index: 100;
    left: -20px;
    transform: translateY(300%);
    position: absolute;
    color: $background;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    display: block;
  }
  .wrapper {
    display: flex;
    position: absolute;
    margin-right: auto;
    margin-left: auto;
    z-index: 99999;
    width: 38%;
    top: 175px;
    .icon {
      padding-right: 1em;
    }
    a {
      text-decoration: none;
      color: $background;
    }
  }
  &:after {
    content: '';
    position: absolute;
    height: 385px;
    width: 300px;
    background-color: rgb(93, 93, 95);
    opacity: 0;
    display: block;
    transition: opacity 0.3s ease-in-out;
  }
  &:hover .text {
    opacity: 1;
    transform: translateY(0%);
    transition: all 0.3s ease-in-out;
  }

  &:hover:after {
    opacity: 0.7;
    transition: opacity 0.3s ease-in-out;
  }
}
.publication_card {
  height: 380px !important;
  width: 300px !important;
}
}

@media only screen and (max-width: 1500px) {
  .img_wrapper {
    img {
      object-fit: cover;
      width: 250px !important;
      height: 250px !important;
    }
  }
  .wrapper {
    display: flex;
    .icon {
      padding-right: 1em;
    }
  }
  // .resource_image:after {
  //   content: '';
  //   position: absolute;
  //   width: 246px !important;
  //   height: 253px !important;
  //   background-color: rgb(93, 93, 95);
  //   opacity: 0.8;
  //   display: none;
  // }
}
@media screen and (max-width: 1500px){
  .resource_image .wrapper {
    display: flex;
    position: absolute;
    margin-right: auto;
    margin-left: auto;
    z-index: 99999;
    width: 47%;
    top: 108px;
  }
  .resource_image .text {
    background-color: #B5316A;
    padding: 1em 1em;
    z-index: 100;
    left: 3px;
    position: absolute;
    color: #FFFFFF;
    display: none;
}
}
@media screen and (max-width: 1024px) {
  .resources_card_wrapper .card .title_wrapper {
    padding: 2em;
    width: 300px;
    text-align: center;
  }
  .resource_image .wrapper {
    display: flex;
    position: absolute;
    margin-right: auto;
    margin-left: auto;
    z-index: 99999;
    width: 43%;
    top: 110px;
  }
  .card{
    &:hover{
      transform: scale(1.1);
      transition: transform 0.3s ease-in-out;
    }
  }
  .resource_image:hover:after {
    opacity: 0.7;
    transition: opacity 0.3s ease-in-out;
}
  .resource_image.text{
    &:hover.text {
      opacity: 1;
      transform: translateY(0%);
      transition: all 0.3s ease-in-out;
    }
  }
.resource_image:after {
    content: '';
    position: absolute;
    height: 385px;
    width: 300px;
    background-color: #5d5d5f;
    opacity: 0;
    display: block;
    transition: opacity 0.3s ease-in-out;
}
  &:hover:after {
    opacity: 0.7;
    transition: opacity 0.3s ease-in-out;
  }
}
@media screen and (max-width: 600px) {
  .card{
    &:hover{
      transform: scale(1.1);
      transition: transform 0.3s ease-in-out;
    }
  }
  .resource_image:hover:after {
    opacity: 0.7;
    transition: opacity 0.3s ease-in-out;
}
  .resource_image.text{
    &:hover.text {
      opacity: 1;
      transform: translateY(0%);
      transition: all 0.3s ease-in-out;
    }
  }
.resource_image:after {
    content: '';
    position: absolute;
    height: 385px;
    width: 300px;
    background-color: #5d5d5f;
    opacity: 0;
    display: block;
    transition: opacity 0.3s ease-in-out;
}
  &:hover:after {
    opacity: 0.7;
    transition: opacity 0.3s ease-in-out;
  }
  .resources_card_wrapper .card .title_wrapper {
    padding: 2em;
    width: 300px;
    text-align: center;
  }
  .resource_image .wrapper {
    display: flex;
    position: absolute;
    margin-right: auto;
    margin-left: auto;
    z-index: 99999;
    width: 43%;
    top: 110px;
  }
}
.wrapper_icon{
  display: flex;
  justify-content: center;
  align-items: center;
}

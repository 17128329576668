@import "../../index.scss";

.prayer_calendar_screen{
  .prayer_calendar_container{
    .prayer_calendar_wrapper{
      .prayer_calendar_header_wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 2em 2em 0em 2em;
        .prayer_calendar_search_box_wrapper{
          width: 250px;
          padding-right: 1em;
        }

        .prayer_calendar_button_wrapper{
          padding-right: 1em;
        }
      }
    }
  }
}
.prayer_calendar_action_wrapper{
  display: flex;
}
.prayer_calendar_customModal {
  background: $background;
  width: 22%;
  min-height: 350px;
  border-radius: 16px;
  box-shadow: 0 10 28px 0px $primaryColor;
  padding: 0;
  position: relative;

  .close_button{
    outline: none;
  }

  .prayer_calendar_customModal::-webkit-scrollbar {
    display: none;
  }

  .prayer_calendar_modal_title_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1em;

    .prayer_calendar_modal_title {
      font-weight: bold;
      margin-left: 0.75em;
      color: $primaryTextColor;
    }
  }
  .prayer_calendar_modal_line_wrapper {
    width: 100%;
    height: 1px;
    background-color: $primaryColor;
  }
  .prayer_calendar_modal_input_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .prayer_calendar_modal_input {
      padding-top: 1em;
      width: 80%;
      .upload_box_wrapper{
        width: 100%;
        padding-top: 1em;
      }
    }
  }
  .prayer_calendar_button_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;

    .prayer_calendar_button{
      padding-top: 1em;
      width: 50%;
    }
  }
}
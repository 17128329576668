@import '../../index.scss';
.working_committee_desc_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2em;
  .working_committee_description{
    max-width: 1170px;
    padding-top: 2em;
  }
}
.officer_screen {
  width: 100%;
  height: 100%;
  overflow: auto;
  .working_committee_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: $backgroundColor;
    .working_committee_wrapper {
      width: 100%;
      .working_committee_header_wrapper {
        width: 100%;
      }
      .working_committee_navbar_wrapper {
        width: 100%;
      }
      .working_committee_title_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 2em;

        .working_committee_title {
          font-weight: 400;
          font-family: 'Merriweather';
        }
        .working_committee_line_wrapper {
          width: 100px;
          margin-top: 0.5em;
          height: 2px;
          background: $primaryColor;
        }
      }
      .working_committee_nav_link_wrapper {
        display: flex;
        justify-content: center;
        padding: 0.5em 0;
        position: sticky;
        top: 25px;
        background: $backgroundColor;
        z-index: 1;
        .title_links_wrapper {
          display: flex;
          padding: 1rem;
          justify-content: center;
          position: relative;
          &:after {
            content: '';
            width: 100%;
            position: absolute;
            bottom: 0px;
            height: 5px;
            border-bottom: 3px solid $secondaryLightColor;
          }
          .link_1 {
            position: relative;
            font-weight: 400;
            padding-left: 2em;

            .title_link {
              text-decoration: none;
              color: $subscribeButtonColor;
            }
          }
          .link {
            position: relative;
            font-weight: 400;
            padding-left: 2em;
            .title_link {
              text-decoration: none;
              color: $subscribeButtonColor;
            }
          }
        }
      }
      .working_committee_officers_synod_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 3em;
        .working_committee_officers_synod_title_wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .working_committee_officers_synod_title {
            font-weight: 400;
            font-family: 'Merriweather';
          }
          .working_committee_officers_line_wrapper {
            width: 100px;
            margin-top: 0.5em;
            height: 2px;
            background: $primaryColor;
          }
        }
      }
      .working_committee_content_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 5em;
        width: 100%;
        .working_committee_content {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          width: 65%;
          .working_committee_officer_wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: flex-start;
            width: 100%;
            .working_committee_card {
              width: 45%;
              padding-bottom: 5em;
              .officer_card {
                width: 280px;
                height: 400px;
              }
            }
          }
        }
        .working_committee_officers_synod_wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding-top: 5em;
          .working_committee_officers_synod_title {
            font-weight: 400;
            font-family: 'Merriweather';
          }
          .working_committee_officers_line_wrapper {
            margin-top: 1em;
            height: 2px;
            width: 150px;
            background: $primaryColor;
          }
        }
        .working_committee_load_more_button_wrapper {
          padding-top: 3em;
          .working_committee_load_more_button {
            width: 150px;
          }
        }
      }
      .working_committee_members_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 5em;
        .working_committee_members_title_wrapper {
          display: flex;
          flex-direction: column;
          width: 100%;
          justify-content: center;
          align-items: center;
          .working_committee_members_title {
            font-weight: 400;
            font-family: 'Merriweather';
          }
          .working_committee_members_line_wrapper {
            width: 100px;
            margin-top: 0.5em;
            height: 2px;
            background: #b5316a;
          }
        }
        .working_committee_members_content_wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-top: 5em;
          width: 100%;
          .working_committee_members_content {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            width: 100%;
            .working_committee_member_card {
              width: 20%;
              .member_card {
                width: 250px;
                height: 300px;
              }
              .member_card_image {
                height: 300px;
                width: 270px;
                object-fit: cover;
              }
            }
          }
        }
      }
      .working_committee_footer_wrapper {
        padding-top: 5em;
      }
    }
  }
  .selected {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      height: 2px;
      width: 100%;
      background-color: $primaryColor;
      bottom: -16px;
      z-index: 100;
      left: 0;
    }
  }
}
@media screen and (max-width: 1024px) {
  .officer_screen
    .working_committee_container
    .working_committee_wrapper
    .working_committee_nav_link_wrapper
    .title_links_wrapper
    .h3 {
    // font-size: 20px;
  }

  .officer_screen
    .working_committee_container
    .working_committee_wrapper
    .working_committee_content_wrapper
    .working_committee_content {
    width: 75%;
  }
  .officer_screen
    .working_committee_container
    .working_committee_wrapper
    .working_committee_members_wrapper
    .working_committee_members_content_wrapper
    .working_committee_members_content
    .working_committee_member_card {
    width: 45%;
  }
  .officer_screen
    .working_committee_container
    .working_committee_wrapper
    .working_committee_members_wrapper {
    padding-top: 2em;
  }
}
@media screen and (max-width: 600px) {
  .officer_screen
    .working_committee_container
    .working_committee_wrapper
    .working_committee_title_wrapper {
    padding-top: 2em;
  }
  .officer_screen
    .working_committee_container
    .working_committee_wrapper
    .working_committee_nav_link_wrapper
    .title_links_wrapper {
    width: 100%;
  }
  .officer_screen
    .working_committee_container
    .working_committee_wrapper
    .working_committee_nav_link_wrapper {
    padding-top: 1em;
    top: 10px;
  }
  .officer_screen
    .working_committee_container
    .working_committee_wrapper
    .working_committee_nav_link_wrapper
    .title_links_wrapper
    .h3 {
    font-size: 8px;
  }
  .officer_screen
    .working_committee_container
    .working_committee_wrapper
    .working_committee_nav_link_wrapper
    .title_links_wrapper:after {
    content: '';
    width: 75%;
    position: absolute;
    bottom: 0px;
    height: 5px;
    border-bottom: 3px solid #fff3fa;
  }
  .officer_screen
    .working_committee_container
    .working_committee_wrapper
    .working_committee_content_wrapper {
    padding-top: 3em;
  }
  .officer_screen
    .working_committee_container
    .working_committee_wrapper
    .working_committee_nav_link_wrapper
    .title_links_wrapper
    .link {
    padding-left: 2em;
  }
  .officer_screen
    .working_committee_container
    .working_committee_wrapper
    .working_committee_nav_link_wrapper
    .title_links_wrapper
    .link_1 {
    padding-left: 2em;
  }
  .officer_screen
    .working_committee_container
    .working_committee_wrapper
    .working_committee_content_wrapper
    .working_committee_content
    .working_committee_officer_wrapper
    .working_committee_card
    .officer_card {
    width: 280px !important;
    height: 320px !important;
    object-fit: cover;
  }
  .officer_screen
    .working_committee_container
    .working_committee_wrapper
    .working_committee_members_wrapper
    .working_committee_members_content_wrapper {
    padding-top: 2em;
    display: flex;
    flex-direction: column;
  }
  .officer_screen
    .working_committee_container
    .working_committee_wrapper
    .working_committee_members_wrapper
    .working_committee_members_content_wrapper
    .working_committee_members_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .officer_screen
    .working_committee_container
    .working_committee_wrapper
    .working_committee_members_wrapper
    .working_committee_members_content_wrapper
    .working_committee_members_content
    .working_committee_member_card {
    width: 100%;
    padding: 1em 0;
  }
  .officer_screen
    .working_committee_container
    .working_committee_wrapper
    .working_committee_content_wrapper
    .working_committee_content
    .working_committee_officer_wrapper
    .working_committee_card {
    width: 100%;
    padding-bottom: 5em;
  }
}

@import '../../index.scss';
.screen {
  height: 100vh;
  overflow: auto;
  background-color: $backgroundColor;
  display: flex;
  flex-direction: column;
  align-items: center;
  scroll-behavior: smooth;
  .header_wrapper {
    width: 100%;
  }
  .header_image_wrapper {
    width: 100%;
    height: 650px;
    .image_wrapper {
      width: 100%;
      height: 100%;
      .header_image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .body_wrapper {
    max-width: 1170px;
    .header_content_wrapper {
      background-color: $primaryColor;
      // height: 200px;
      margin-top: -60px;
      .header_content {
        padding: 3em 10em 3em 4em;
        color: $background;
        .header_title {
          font-weight: 600;
          margin-bottom: 0.5em;
        }
        .header_title_2 {
          font-weight: 300;
        }
      }
    }
    .header_description_wrapper {
      padding: 2em 4em;
      line-height: 25px;
      font-family: 'Open Sans', sans-serif;
      .header_description {
      }
      .description_image_wrapper {
        display: flex;
        padding: 3em;
        flex-direction: column;
        align-items: center;
        .image_wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            width: 500px;
            height: 600px;
            object-fit: cover;
          }
          .image_description_text {
            padding-top: 2em;
            font-weight: 300;
          }
        }
      }
    }

    .aim_and_obj_wrapper {
      padding: 2em 4em;
      display: flex;
      flex-direction: column;
      .aim_title_wrapper {
      }
      .aim_content_wrapper {
        ul {
          padding-left: 0;
          li {
            list-style-type: '-';
            padding: 1em 0 1em 0;
          }
        }
      }
    }
    .action_button_wrapper {
      display: flex;
      justify-content: space-between;
      width: 450px;
      .button_wrapper {
        width: 150px;
        .btn {
          background-color: $primaryColor !important;
        }
      }
    }
    .gallery_wrapper {
      text-align: center;
      padding-top: 4em;
      .swiper_image {
        padding: 1em;
        width: 350px;
        height: 250px;
      }
      .heading {
        font-family: 'Merriweather';
        font-weight: 400;
      }
      .head_line {
        width: 100px;
        height: 3px;
        background-color: $primaryColor;
        margin: 1em auto;
      }
    }
  }
}
.campaigns_wrapper {
  text-align: center;
  margin-top: 4em;
  margin-bottom: 20em;
  background-color: $secondaryDarkColor;
  position: relative;
  width: 100%;
  .campaigns_wrapper2 {
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .heading {
      font-family: 'Merriweather';
      font-weight: 400;
      padding-top: 2em;
    }
    .head_line {
      width: 200px;
      height: 3px;
      background-color: $primaryColor;
      margin: 1em auto;
    }
    .campaign_swiper_wrapper {
      padding: 4em;
      margin-top: 8em;
      position: absolute;
      width: 80%;
    }
    .campaign_swiper_image {
      height: 350px;
      width: 350px;
      object-fit: cover;
    }
  }
}
.header_description img{
  width: 50%;
    height: 400px;
    object-fit: contain;
}
.campaign_footer_wrapper {
  width: 100%;
  margin-top: 5em;
}

@media screen and (max-width: 1024px) {
  .campaign_swiper_image {
    height: 350px;
    width: 350px !important;
  }
  .campaigns_wrapper .campaigns_wrapper2 .campaign_swiper_wrapper {
    width: 100%;
  }
  .screen .body_wrapper .header_content_wrapper {
    height: 150px;
    display: flex;
    flex-direction: column;
  }
  .screen .body_wrapper .header_description_wrapper {
    padding: 2em;
  }
  .screen .body_wrapper .header_description_wrapper img {
    width: 50%;
    height: 400px;
    object-fit: cover;
    padding-right: 2em;
    padding-top: 1em;
  }
}
@media screen and (max-width: 1500px) {
  .campaigns_wrapper .campaigns_wrapper2 .campaign_swiper_wrapper {
    padding: 4em;
    margin-top: 8em;
    position: absolute;
    width: 100%;
}
}
@media screen and (max-width: 600px) {
  .screen .body_wrapper .header_content_wrapper {
    background-color: #b5316a;
    height: 135px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .screen .body_wrapper .header_description_wrapper {
    padding: 2em;
    h1 {
      position: relative;
      font-size: 19px;
      padding-bottom: 0.5em;
    }
    h1::after {
      width: 80%;
    }
    p {
      font-size: 16px;
    }
    h2 {
      font-size: 15px;
    }
    ::marker {
      font-size: 30px;
      color: $primaryColor;
    }

    img {
      width: 50%;
      height: 230px;
      object-fit: cover;
      padding-right: 1em;
    }
  }
  .screen .body_wrapper .action_button_wrapper {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }
  .screen .body_wrapper .action_button_wrapper .button_wrapper {
    height: 68px;
    width: 120px;
  }
  .gallery_wrapper {
    padding-top: 2em;
    margin-bottom: 2em;
    .swiper_slider_wrapper {
      .swiper_image {
        padding: 1em;
      }
    }
  
    .heading {
      font-family: 'Merriweather';
      font-weight: 400;
    }
    .head_line {
      width: 100px;
      height: 3px;
      background-color: $primaryColor;
      margin: 1em auto;
    }
  }
  .campaigns_wrapper .campaigns_wrapper2 .campaign_swiper_wrapper {
    padding: 0em;
    margin-top: 8em;
    position: absolute;
    width: 100%;
  }
  .gallery_content_wrapper {
    width: 100%;
  }
  .screen .body_wrapper .gallery_wrapper .swiper_image {
    padding: 1em;
    height: 225px!important;
    width: 216px !important;
}
  .campaigns_wrapper .campaigns_wrapper2 .campaign_swiper_image {
    height: 225px;
    width: 216px !important;
}
  .screen .body_wrapper .gallery_wrapper .swiper_image {
    padding: 1em;
    width: 282px;
    height: 252px;
}
.campaigns_wrapper {
  margin-bottom: 2em;
}
}

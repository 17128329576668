@import '../../index.scss';

.screen {
  height: 100vh;
  overflow: auto;
  background-color: $backgroundColor;
  display: flex;
  flex-direction: column;
  align-items: center;
  .header_wrapper {
    width: 100%;
  }
  .campaign_list_screen_container {
    width: 100%;
    .campaign_list_screen_header_wrapper {
      display: flex;
      justify-content: center;
      padding-top: 2em;
      .campaign_list_screen_header {
        font-family: 'Merriweather';
        font-weight: 400;
        .header_line {
          height: 3px;
          width: 152.5px;
          background-color: $primaryColor;
          margin-top: 1rem;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
    .campaign_list_screen_body_wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      .campaign_card_wrapper {
        width: 50%;
        padding-bottom: 2.5em;
        .news_card_2_component
          .news_card_2_container
          .news_card_2_wrapper
          .news_card_2_content {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          padding: 0 0 0 4em;
        }
        .news_card_2_component
          .news_card_2_container
          .news_card_2_wrapper
          .news_card_2_media
          .news_card_2_img {
          width: 330px;
          height: 280px;
          object-fit: cover;
        }
      }
    }
    .footer_wrapper {
      width: 100%;
    }
  }
}
.campaign_list_pagination {
  padding: 3em 0;
}
@media screen and (max-width: 1500px) {
  .screen .campaign_list_screen_container .campaign_list_screen_body_wrapper .campaign_card_wrapper .news_card_2_component .news_card_2_container .news_card_2_wrapper .news_card_2_media .news_card_2_img {
    width: 279px;
    height: 241px;
    object-fit: cover;
}
}
@media screen and (max-width: 768px) {
  .screen
    .campaign_list_screen_container
    .campaign_list_screen_body_wrapper
    .campaign_card_wrapper {
    width: 100%;
  }
}
@media screen and (max-width: 1024px) {
  .screen
    .campaign_list_screen_container
    .campaign_list_screen_body_wrapper
    .campaign_card_wrapper {
    width: 78%;
  }
}

@media screen and (max-width: 600px) {
  .screen {
    .campaign_list_screen_container {
      width: 100%;
      .campaign_list_screen_header_wrapper {
        .campaign_list_screen_header {
          .header_line {
            height: 3px;
            width: 102.5px;
          }
        }
      }
      .campaign_list_screen_body_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        .campaign_card_wrapper {
          width: 100%;
          padding: 1.5em;
          .news_card_2_component
            .news_card_2_container
            .news_card_2_wrapper
            .news_card_2_content {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            padding: 0 0 0 4em;
          }
          .news_card_2_component
            .news_card_2_container
            .news_card_2_wrapper
            .news_card_2_media
            .news_card_2_img {
            width: 330px;
            height: 280px;
            object-fit: cover;
          }
        }
      }
      .footer_wrapper {
        width: 100%;
      }
    }
  }
  .screen
    .campaign_list_screen_container
    .campaign_list_screen_body_wrapper
    .campaign_card_wrapper
    .news_card_2_component
    .news_card_2_container
    .news_card_2_wrapper
    .news_card_2_content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-left: 0.3em;
    padding-top: 1em;
  }
  .screen
    .campaign_list_screen_container
    .campaign_list_screen_body_wrapper
    .campaign_card_wrapper
    .news_card_2_component
    .news_card_2_container
    .news_card_2_wrapper
    .news_card_2_media
    .news_card_2_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .screen .campaign_list_screen_container .campaign_list_screen_body_wrapper .campaign_card_wrapper {
    width: 100%;
  }
}

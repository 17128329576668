@import '../../../index.scss';

.pdf_file_container {
  .pdf_file_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    .pdf_file_image_wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 100px;
      background: $primaryColor;
      .pdf_file_image {
        width: 50px;
        height: 50px;
      }
    }
    .pdf_file_name_button_wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
      padding-left: 3em;
      cursor: pointer;
      .pdf_file_name {
        font-weight: 600;
        overflow: hidden;
        width: 100%;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
      }
      .pdf_file_button_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 1em;
        .pdf_file_button_icon {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-left: 0.5em;
          .button_icon {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}

@import "../../../index.scss";

.search_box_container {
  background-color: transparent;
  position: relative;
  width: 100%;
  height: 100%;

  .search_box_wrapper {
    display: flex;
    background-color: $selectBoxColor;
    border-radius: 50px;
    // padding: 0.8em 0.9em;
    padding: 0.5em;
    justify-content: space-evenly;
    .search_box_icon_container {
      img {
        width: 20px;
        padding-top: 0.2em;
      }
    }

    .search_box_input {
      width: 80%;
      .search_input {
        width: 100%;
        outline: none;
        background-color: $selectBoxColor;
        border: none;
        height: 24px;
        color: $primaryTextColor;
        // padding-top: 0.2em;
      }

      .search_input::placeholder {
        color: $placeHolder;
      }
    }
  }

  .search_box_secondary {
    background-color: transparent !important;
    border: 1px solid $primaryColor;

    .search_box_input {
      .search_input {
        background-color: transparent;
      }
    }
  }
  .border_radius {
    border-radius: 0;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    // border-bottom: 0.05px solid $white;
  }

  .search_list_container {
    position: absolute;
    width: 100%;
    margin: 0 auto;
    height: 150px;
    overflow: auto;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    background-color: $selectBoxColor;
    .search_list_wrapper {
      width: 100%;

      .search_list_item {
        padding: 0.8em;
        display: flex;
        align-items: center;
        .search_img_container {
          width: 40px;
          height: 40px;
          .search_img {
            width: 100%;
            height: 100%;
          }
        }
        .search_list_content {
          padding-left: 1.5em;
          color: $primaryColor;
        }
      }
    }
  }

  .search_list_container::-webkit-scrollbar {
    width: 2px;
    height: 90%;
  }
  
  /* Track */
  .search_list_container::-webkit-scrollbar-track {
    background: $secondaryLightColor; 
  }
   
  /* Handle */
  .search_list_container::-webkit-scrollbar-thumb {
    background: $primaryColor; 
  }
  
  /* Handle on hover */
  .search_list_container::-webkit-scrollbar-thumb:hover {
    background: $primaryColor; 
  }
}

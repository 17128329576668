@import "../../../index.scss";

.info_card_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $background;
  box-shadow: 0px 0px 25px -7px $secondaryDarkColor;
  border-radius: 15px;
  padding: 1.2em;
  padding-left: 1em;

  .info_card_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .info_card_content {
      display: flex;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: $secondaryDarkColor;
      justify-content: center;
      align-items: center;

      .info_card_icon {
        width: 35px;
        height: 18px;
      }
    }

    .info_card_title {
      display: flex;
      flex: 0.9;
      justify-content: space-evenly;
      align-items: center;
      font-weight: 500;
      padding-left: 0.5em;
      color: $primaryColor;
    }
  }

  .info_card_text_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .info_card_text {
      font-weight: 800;
    }
  }
}

@import "../../index.scss";

.notification_schemes_screen{
  .notification_schemes_container{
    .notification_schemes_wrapper{
      .notification_schemes_header_wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2em;

        .notification_schemes_search_bar_wrapper{

        }

        .notification_schemes_button_wrapper{
          padding-left: 1em;
        }
      }
    }
  }
}
.notification_action_wrapper{
  display: flex;
}
.notification_schemes_customModal {
  background: $background;
  width: 25%;
  height: 60vh;
  // min-height: 500px;
  border-radius: 16px;
  box-shadow: 0 10 28px 0px $primaryColor;
  padding: 0;
  position: relative;

  .close_button {
    outline: none;
  }

  .notification_schemes_modal_title_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1em;

    .notification_schemes_modal_title {
      font-weight: bold;
      margin-left: 0.75em;
      color: $primaryTextColor;
    }
  }
  .notification_schemes_modal_line_wrapper {
    width: 100%;
    height: 0.5px;
    background-color: $primaryColor;
  }
  .notification_schemes_modal_input_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    .notification_schemes_modal_input {
      padding-top: 1em;
      width: 80%;
      .upload_box_wrapper {
        width: 100%;
        padding-top: 1em;
        .upload_box {
          padding-top: 1em;
        }
      }
    }

    .notification_schemes_file_upload_wrapper {
      display: flex;
      margin-top: 1.5em;
      justify-content: space-between;
      width: 90%;
      height: 100%;
      border-radius: 2%;
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      background-color: $secondaryLightColor;

      .file_upload_file_name {
        display: flex;
        padding-left: 0.5em;
        width: 85%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .file_upload_inactive_icon-wrapper {
        display: flex;
        justify-items: center;
        align-items: center;

        .file_upload_delete_icon {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .notification_schemes_modal_input_wrapper::-webkit-scrollbar {
    display: none;
  }

  .notification_schemes_modal_button_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 10px;
    width: 100%;

    .notification_schemes_modal_button {
      padding-top: 2em;
      width: 100px;
    }
  }
}
@import "../../index.scss";

.diocese_view_screen {
  background-color: $background;
  width: 100%;
  .diocese_view_container {
    width: 100%;
    .diocese_view_wrapper {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      .diocese_view_header_wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding-top: 2em;
        .diocese_view_navigate_icon_wrapper {
          .diocese_view_navigate_icon {
            .navigate_icon {
              transform: rotate(90deg);
            }
          }
        }
        .diocese_view_header_name_container {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          .diocese_view_header_name {
            padding-left: 1em;
            font-weight: 700;
          }
          .diocese_view_header_button_wrapper{
            padding-right: 2.5em;
          }
        }
      }
      .diocese_view_navbar_wrapper {
        width: 100%;
      }
      .diocese_view_table_wrapper{
        width: 100%;
      }
    }
  }
}
.modal_container {
  background: $background;
  width: 300px;
  height: 570px;
  border-radius: 16px;
  box-shadow: 0 10 28px 0px $primaryColor;
  padding: 0;

  .modal_container::-webkit-scrollbar {
    display: none;
  }

  .modal_title_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1em;

    .modal_title {
      font-weight: bold;
      margin-left: 0.75em;
      color: $primaryTextColor;
    }
  }
  .modal_line_wrapper {
    width: 100%;
    height: 1px;
    background-color: $primaryColor;
  }
  .modal_input_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 0;
    .modal_input {
      width: 75%;
    }
  }
  .csi_user_button_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
  }
}

.upload_box_wrapper{
  width: 75%;
}
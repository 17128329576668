@import "../../index.scss";

.secretarial_staff_screen {
  .secretarial_staff_container {
    .secretarial_staff_wrapper {
      .secretarial_staff_header_wrapper {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding-top: 2em;
        padding-bottom: 1em;

        .secretarial_staff_name_wrapper{
          color: $primaryTextColor;
          font-weight: 600;
          padding-left: 2em;
        }

        .secretarial_staff_action_wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50%;

          .secretarial_staff_search_box_wrapper {
            width: 50%;
          }

          .secretarial_staff_button_wrapper {
            width: 160px;
            padding-right: 2em;
            padding-left: 2em;
          }
        }
      }
    }
  }
  .secretarial_staff_button{
    width: 130px;
  }
}
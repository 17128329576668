@import "../../index.scss";
.diocese_news_screen {
  background-color: $background;
  border-radius: 15px;
  box-shadow: 0px 0px 12px $secondaryLightColor;
  .diocese_news_container {
    .diocese_news_info_card_wrapper {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      margin-left: 0.06em;
      padding: 1em;
      width: 85%;

      .diocese_news_info_card {
        width: 24%;
        padding-left: 1em;
      }

      .diocese_news_action_button_wrapper {
        display: flex;
        width: 25%;
        height: 100%;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        padding-left: 2em;

        .diocese_news_action_button {
          width: 90%;
          padding-top: 0.5em;
        }
      }
    }
  }
}

.diocese_news_modal {
  background: $background;
  width: 23%;
  height: 70vh;
  border-radius: 16px;
  box-shadow: 0 10 28px 0px $primaryColor;
  padding: 0;
  .diocese_news_modal_container {
    .diocese_news_modal_wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      .diocese_news_modal_header_wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        .diocese_news_modal_header {
          padding-left: 1em;
          padding-top: 1em;
          font-weight: 700;
        }
        .diocese_news_modal_clear {
          padding-right: 1em;
          padding-top: 1em;
          font-weight: 300;
        }
        .diocese_news_modal_diocese_heading {
          padding-left: 1em;
          padding-top: 2em;
          font-weight: 700;
        }
        .diocese_news_modal_diocese_clear {
          padding-right: 1em;
          padding-top: 2em;
          font-weight: 300;
        }
      }
      .diocese_news_button_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 1em;
        padding-top: 1em;
        .button_inactive {
          padding-left: 1em;
        }
      }
      .diocese_news_apply_filter_button_wrapper {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        padding: 1em;
      }
      .diocese_news_modal_checklist {
        width: 100%;
        height: auto;
        overflow-x: auto;
        padding-right: 2em;
      }
      .diocese_news_modal_checklist::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
.diocese_filter{
  width: 100%;
}
@import "../../index.scss";
.downloadapp_container {
    background-color: $secondaryDarkColor;
    width: 100%;
    height: 100%;

    .downloadapp_wrapper {
        display: flex;
        padding-left: 1.88em;
        padding-right: 1.88em;
        padding-top: 1.25em;
        padding-bottom: 1.25em;

        .downloadapp_image_wrapper {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;

            .downloadapp_image {
                width: 130px;
                height: 230px;
            }
        }

        .downloadapp_text_wrapper {
            display: flex;
            width: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .downloadapp_description {
                margin-bottom: 0.63em;
                font-weight: 400;
            }

            .downloadapp_title {
                font-weight: 600;
                margin-bottom: 0.94em;
            }

            .downloadapp_line {
                width: 100px;
                height: 2px;
                background-color: $primaryColor;
                margin-bottom: 1.56em;
            }

            .downloadapp_icon_wrapper {
                display: flex;
                justify-content: flex-start;
                .google_icon {
                    margin-left: 1em;
                }
            }
        }
    }
    @media only screen and (min-width: 1500px) {
        .downloadapp_wrapper {
            .downloadapp_image_wrapper{
                .downloadapp_image{
                    width: 130px;
                    height: 250px;
                }
            }
        }
    }

    @media only screen and (max-width: 600px){
        .downloadapp_wrapper{
            padding: 2em 1em 2em 1.5em;
            .downloadapp_image_wrapper{
                .downloadapp_image{
                    width: 85px;
                    height: 150px;
                }
            }
            .downloadapp_text_wrapper{
                .downloadapp_title{
                    margin-bottom: 0.40em;
                }
                .downloadapp_line{
                    width: 70px;
                }
                .downloadapp_icon_wrapper{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;

                    .google_icon{
                        margin-left: 0em;
                        margin-top: 1em;
                    }
                }
            }
            
        }
    }
}

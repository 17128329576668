@import '../../index.scss';

.screen {
  height: 100vh;
  overflow: auto;
  background-color: $backgroundColor;
  display: flex;
  flex-direction: column;
  align-items: center;
  .header_wrapper {
    width: 100%;
  }
  .diocese_news_user_screen_container {
    width: 80vw;
    .diocese_news_user_screen_header_wrapper {
      display: flex;
      justify-content: center;
      padding-top: 1em;
      .diocese_news_user_screen_header {
        font-family: 'Merriweather';
        font-weight: 400;
        .header_line {
          height: 3px;
          width: 152.5px;
          background-color: $primaryColor;
          margin-top: 1rem;
          margin-left: auto;
          margin-bottom: 0;
          margin-right: auto;
        }
      }
    }
    .select_box_wrapper {
      display: flex;
      justify-content: flex-end;
      .select_box {
        width: 250px;
      }
    }
    .diocese_news_user_screen_body_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      .card_wrapper {
        width: 30%;
        padding: 1em;
        height: 600px;
      }
    }
    .footer_wrapper {
      width: 100%;
    }
  }
  .pagination_wrapper {
    padding: 3em 0;
  }
}
@media screen and (max-width: 1024px) {
  .screen .diocese_news_user_screen_container {
    width: 100%;
  }
  .screen
    .diocese_news_user_screen_container
    .diocese_news_user_screen_body_wrapper
    .card_wrapper {
    height: 550px;
  }
}
@media screen and (max-width: 600px) {
  .screen {
    .diocese_news_user_screen_container {
      width: 100vw;
      .diocese_news_user_screen_header_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 2em;
        .diocese_news_user_screen_header {
          font-family: 'Merriweather';
          font-weight: 400;
          .header_line {
            height: 3px;
            width: 152.5px;
            background-color: $primaryColor;
            margin-top: 1rem;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
      .select_box_wrapper {
        display: flex;
        .select_box {
          width: 250px;
        }
      }
      .diocese_news_user_screen_body_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        .card_wrapper {
          width: 100%;
          padding: 1em;
          height: 500px;
        }
      }
      .footer_wrapper {
        width: 100%;
      }
    }
    .news_pagination_wrapper {
      padding: 3em 0;
      margin-top: 1em;
    }
  }
}
.pagination_wrapper {
  padding: 3em 0;
  margin-top: 2em;
}
@media screen and (min-width: 1024px) and (max-width: 1500px) {
  .screen
    .diocese_news_user_screen_container
    .diocese_news_user_screen_body_wrapper
    .card_wrapper {
    width: 30%;
    padding: 1em;
    height: 700px;
  }
}

@import '../../index.scss';

.event_card_container {
  cursor: pointer;
  .event_card_wrapper {
    position: relative;
    .event_card_image_wrapper {
      width: 100%;
      height: 100%;
      .event_card_background_image {
        width: 100%;
        height: 350px;
        background-size: cover;
        object-fit: cover;
      }
    }
    .event_card_information_wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 30px;
      width: 100%;
      padding-left: 3em;

      .event_card_date_wrapper {
        background: $primaryColor;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 130px;
        height: 100px;

        .event_card_date {
          color: $background;
          font-weight: 800;
        }
        .event_card_month {
          padding-top: 0.2em;
          font-weight: 600;
          color: $background;
        }
      }

      .event_card_title_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        width: 100%;
        padding-left: 2.5em;

        .event_card_title {
          height: 30%;
          color: $background;
        }
        .event_card_description {
          color: $background;
          width: 100%;
          overflow: hidden;
          font-size: 20px;
          padding-top: 0.3em;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
  .event_card_overlay {
    background: linear-gradient(
      180deg,
      rgba(27, 6, 15, 0.22) 0%,
      rgba(85, 85, 85, 0.22) 20%,
      rgba(87, 85, 85, 0.05) 60%,
      #100007 100%
    );
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    top: 0;
  }
  
  @media only screen and (max-width: 1050px) {
    .event_card_wrapper {
      .event_card_information_wrapper {
        width: 95%;
        .event_card_date_wrapper {
          width: 100%;
          height: 70px;
        }
        .event_card_title_wrapper {
          width: 100%;
          padding-left: 0.5em;
          .event_card_description {
            width: 210px;
          }
        }
      }
    }
  }
  @media only screen and (min-width: 1900px) {
    .event_card_wrapper {
      .event_card_image_wrapper {
        .event_card_background_image {
          height: 310px;
        }
        .event_card_information_wrapper {
          .event_card_date_wrapper {
            width: 130px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1500px) {
    .event_card_wrapper {
      .event_card_image_wrapper {
        .event_card_background_image {
          height: 286px;
        }
        .event_card_information_wrapper {
          .event_card_date_wrapper {
            width: 130px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1050px) {
  .event_card_container .event_card_wrapper .event_card_information_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 30px;
    width: 100%;
    height: 30%;
    padding-left: 2em;
  }
  .event_card_container
    .event_card_wrapper
    .event_card_information_wrapper
    .event_card_date_wrapper {
    width: 80px;
    height: 70px;
  }
  .event_card_container
    .event_card_wrapper
    .event_card_information_wrapper
    .event_card_title_wrapper {
    width: 100%;
    padding-left: 3.5em;
  }
  .event_card_container
    .event_card_wrapper
    .event_card_image_wrapper
    .event_card_background_image {
    height: 242px;
  }
}
@media screen and (max-width: 1500px) {
  .event_card_container
    .event_card_wrapper
    .event_card_information_wrapper
    .event_card_title_wrapper
    .event_card_description {
    color: #ffffff;
    width: 100%;
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .event_card_container .event_card_wrapper .event_card_information_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 30px;
    width: 100%;
    height: 30%;
    padding-left: 1em;
  }
}
@media screen and (max-width: 600px) {
  .event_card_container .event_card_wrapper .event_card_information_wrapper .event_card_title_wrapper .event_card_description {
    color: #ffffff;
    width: 100%;
    font-size: 17px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
}
@import '../../index.scss';

.news_card_2_component {
  width: 100%;
  font-family: 'Merriweather';
  .news_card_2_container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    .news_card_2_wrapper {
      .news_card_2_media {
        width: 100%;
        height: 100%;
        .news_card_2_img {
          width: 190px;
          height: 180px;
          object-fit: cover;
        }
        .news_card_2_img_border_right {
          border-right: 3px solid $primaryColor;
        }
        .news_card_2_img_border_left {
          border: 0;
          border-left: 3px solid $primaryColor;
        }
      }
      .news_card_2_content {
        width: 95%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 0 0 0 1.2em;
        .news_card_2_head {
          padding-bottom: 0.4em;
        }
        .news_card_2_time_stamp {
          padding-bottom: 0.5em;
        }
        .news_card_2_text {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 22px;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          font-family: 'Open Sans', sans-serif;
        }
        .news_card_2_view {
          display: flex;
          width: 30%;
          padding-top: 0.6em;
          cursor: pointer;
          .news_card_2_view_text {
            color: $primaryColor;
            font-weight: 400;
          }
          .arrow_icon {
            width: 30px;
            padding-left: 0.5em;
            .arrow_right_fill {
              width: 18px;
            }
          }
        }
      }
      .news_card_2_reverse_content {
        padding: 0 0 1.2em 0;
      }
    }
  }
  .news_card_2_flex_reverse {
    flex-direction: row-reverse;
  }
  .text {
    width: 100%;
  }

  // @media screen and (min-width: 1500px) {
  //   .news_card_2_container {
  //     .news_card_2_wrapper {
  //       .news_card_2_media {
  //         .news_card_2_img {
  //           width: 230px;
  //           height: 240px;
  //         }
  //       }
  //       .news_card_2_content {
  //         // padding: 2em 1.5em 2em 1.2em;
  //         .news_card_2_view {
  //           width: 70%;
  //           cursor: pointer;
  //         }
  //       }
  //     }
  //   }
  // }
}

@media screen and (max-width: 1050px) {
  .news_card_2_container {
    // height: 170px;
    .news_card_2_wrapper {
      width: 60%;
      .news_card_2_media {
        padding: 0.3em;
      }
      .news_card_2_content {
        padding: 0.3em;
        .news_card_2_view {
          width: 70%;
          cursor: pointer;
        }
      }
    }
    .wrapper_small {
      width: 44%;
    }
  }
  .news_card_2_component .news_card_2_container .news_card_2_wrapper .news_card_2_media .news_card_2_img {
    width: 175px;
    height: 180px;
    object-fit: cover;
}
.news_card_2_component .news_card_2_container .news_card_2_wrapper .news_card_2_content .news_card_2_view {
  display: flex;
  width: 100%;
  padding-top: 0.6em;
  cursor: pointer;
}
.home_screen .home_container .home_wrapper .campaigns_and_ads .content .add_campaign_content .add_wrapper .advertisement_platinum_swiper .advertisement_platinum_swiper_image {
  height: 423px;
  object-fit: cover;
  padding-bottom: 3em;
}
}
@media screen and (max-width: 600px) {
  .news_card_2_component .news_card_2_container {
    width: 100%;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
  }
  .news_card_2_component .news_card_2_container .news_card_2_wrapper .news_card_2_content .news_card_2_head {
    padding-bottom: 0.4em;
    padding-top: 0.4em;
}
.news_card_2_component .news_card_2_container .news_card_2_wrapper .news_card_2_content .news_card_2_text {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-family: 'Open Sans', sans-serif;
  padding-top: 0.5em;
}
  .news_card_2_component .news_card_2_container .wrapper_small {
    width: 100%;
  }
  .news_card_2_component .news_card_2_container .news_card_2_wrapper {
    width: 100%;
    padding-top: 1em;
  }
  .news_card_2_component
    .news_card_2_container
    .news_card_2_wrapper
    .news_card_2_media {
    padding: 0em;
  }
  .news_card_2_component .news_card_2_container .news_card_2_wrapper .news_card_2_media .news_card_2_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.home_screen .home_container .home_wrapper .campaigns_and_ads .content .add_campaign_content .home_campaigns_wrapper .advertisement_button_wrapper {
  width: 110px;
  margin: auto;
  padding-bottom: 1.9em !important;
}
.news_card_2_component .news_card_2_container .news_card_2_wrapper .news_card_2_content {
  width: 95%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0; 
}
}

@import '../../index.scss';
.officer_card_container{
  height: 500px;
}
.officer_screen {
  width: 100%;
  height: 100%;
  overflow: auto;
  .secretarial_staff_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: $backgroundColor;
    .secretarial_staff_wrapper {
      width: 100%;
      .secretarial_staff_header_wrapper {
        width: 100%;
      }
      .secretarial_staff_navbar_wrapper {
        width: 100%;
      }
      .secretarial_staff_title_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 2em;

        .secretarial_staff_title {
          font-weight: 400;
          font-family: 'Merriweather';
        }
        .secretarial_staff_line_wrapper {
          width: 100px;
          margin-top: 0.5em;
          height: 2px;
          background: $primaryColor;
        }
      }
      .secretarial_staff_nav_link_wrapper {
        display: flex;
        justify-content: center;
        padding-top: 1em;
        padding-bottom: 1em;
        position: sticky;
        top: 10px;
        background: $backgroundColor;
        z-index: 1;
        .title_links_wrapper {
          display: flex;
          padding: 1rem;
          justify-content: center;
          position: relative;
          &:after {
            content: '';
            width: 90%;
            position: absolute;
            bottom: 0px;
            left: 0;
            height: 5px;
            border-bottom: 3px solid $secondaryLightColor;
          }
          .link_1 {
            padding-right: 2em;
            position: relative;
            font-weight: 400;
            .title_link {
              text-decoration: none;
              color: $subscribeButtonColor;
              position: relative;
            }
            .content_icon {
              padding-left: 0.3em;
              width: 30px;
            }
          }
          .link {
            position: relative;
            font-weight: 400;
            padding-right: 2em;
            // padding-left: 2em;
            a {
              padding: 0;
            }
            .title_link {
              position: relative;
              text-decoration: none;
              color: $subscribeButtonColor;
            }
          }
        }
      }
      .secretarial_staff_content_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 0em;
        // max-width: 1170px;

        .secretarial_staff_content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;

          .secretarial_staff_title_wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            .secretarial_staff_title {
              font-weight: 400;
            }
            .secretarial_staff_title_line_wrapper {
              width: 230px;
              margin-top: 0.5em;
              height: 2px;
              background: $primaryColor;
            }
          }
          .secretarial_staff_card {
            width: 50%;
            padding-bottom: 5em;
            .secretarial_staffs_card {
              width: 280px;
              height: 400px;
            }
          }
          .secretarial_staff_header_content_wrapper {
            padding-top: 5em;
          }
          .secretarial_staff_head_profile_wrapper {
            padding-top: 3em;
            .secretarial_staff_head_profile {
              width: 250px;
              height: 350px;
            }
          }
          .secretarial_staff_staffs_content_wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-top: 5em;
            width: 100%;

            .secretarial_staff_staffs_image_wrapper {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-evenly;
              align-items: center;
              width: 100%;
              padding-top: 2em;
              .secretarial_staff_staffs_image {
                padding-bottom: 5em;
                padding-left: 0.5em;
                .staffs_image {
                  width: 250px;
                  height: 350px;
                  object-fit: contain;
                }
              }
            }
            .staffs_image {
              width: 200px;
              height: 300px;
              object-fit: contain;
            }
          }
        }
        .secretarial_staff_load_more_button_wrapper {
          padding-top: 3em;
          .secretarial_staff_load_more_button {
            width: 150px;
          }
        }
      }
      .secretarial_staff_footer_wrapper {
        padding-top: 5em;
      }
    }
  }
  .staff_filter_wrapper {
    width: 74%;
    display: flex;
    justify-content: flex-end;
    position: sticky;
    top: 130px;
    padding-top: 1em;
  }
  .secretarial_staff_content_dropdown_wrapper {
    width: 250px;
    height: 105px;
    background-color: $backgroundColor;
    margin-left: auto;
    box-shadow: 0 4px 6px -6px black;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .dropdown {
    font-size: 15px;
    font-weight: 400;
    text-decoration: none;
    color: #000 !important;
    padding: 0.3em;
  }
}
@media screen and (max-width: 1024px) {
  .officer_screen
    .secretarial_staff_container
    .secretarial_staff_wrapper
    .secretarial_staff_nav_link_wrapper
    .title_links_wrapper {
    width: 100%;
  }
  .officer_screen
    .secretarial_staff_container
    .secretarial_staff_wrapper
    .secretarial_staff_nav_link_wrapper
    .title_links_wrapper
    .h3 {
    // font-size: 20px;
  }
  .officer_screen
    .secretarial_staff_container
    .secretarial_staff_wrapper
    .secretarial_staff_nav_link_wrapper
    .title_links_wrapper:after {
    content: '';
    width: 80%;
    position: absolute;
    bottom: -1px;
    height: 5px;
    left: 60px;
    border-bottom: 3px solid #fff3fa;
  }
  .officer_screen
    .secretarial_staff_container
    .secretarial_staff_wrapper
    .secretarial_staff_content_wrapper {
    padding-top: 2em;
  }

  .secretarial_staff_content_dropdown_wrapper {
    bottom: -179px;
  }
}

@media screen and( max-width: 600px) {
  .officer_screen
    .secretarial_staff_container
    .secretarial_staff_wrapper
    .secretarial_staff_title_wrapper {
    padding-top: 2em;
  }
  .officer_screen
    .secretarial_staff_container
    .secretarial_staff_wrapper
    .secretarial_staff_nav_link_wrapper {
    padding-top: 1em;
    padding-bottom: 0em;
  }
  .officer_screen
    .secretarial_staff_container
    .secretarial_staff_wrapper
    .secretarial_staff_nav_link_wrapper
    .title_links_wrapper
    .h3 {
    font-size: 7px;
  }
  .officer_screen
    .secretarial_staff_container
    .secretarial_staff_wrapper
    .secretarial_staff_nav_link_wrapper
    .title_links_wrapper:after {
    content: '';
    width: 80%;
    position: absolute;
    bottom: -1px;
    height: 5px;
    left: 29px;
    border-bottom: 3px solid #fff3fa;
  }
  .officer_screen
    .secretarial_staff_container
    .secretarial_staff_wrapper
    .secretarial_staff_content_wrapper
    .secretarial_staff_content {
    width: 100%;
  }
  .officer_screen
    .secretarial_staff_container
    .secretarial_staff_wrapper
    .secretarial_staff_content_wrapper
    .secretarial_staff_content
    .secretarial_staff_title_wrapper
    .secretarial_staff_title {
    font-weight: 400;
  }
  .officer_screen
    .secretarial_staff_container
    .secretarial_staff_wrapper
    .secretarial_staff_content_wrapper
    .secretarial_staff_content
    .secretarial_staff_head_profile_wrapper
    .secretarial_staff_head_profile {
    width: 280px;
    height: 320px;
    object-fit: cover;
  }
  .officer_screen
    .secretarial_staff_container
    .secretarial_staff_wrapper
    .secretarial_staff_content_wrapper
    .secretarial_staff_content
    .secretarial_staff_staffs_content_wrapper
    .secretarial_staff_staffs_image_wrapper
    .secretarial_staff_staffs_image
    .staffs_image {
    width: 280px;
    height: 320px;
    object-fit: cover;
  }
  .officer_screen
    .secretarial_staff_container
    .secretarial_staff_wrapper
    .secretarial_staff_nav_link_wrapper
    .title_links_wrapper
    .link_1
    .content_icon {
    padding-left: 0.5em;
    width: 14px;
  }
  .staff_filter_wrapper {
    width: 87% !important;
    display: flex;
    justify-content: flex-end;
    position: sticky;
    top: 55px !important;
  }
  .officer_screen .secretarial_staff_content_dropdown_wrapper {
    width: 124px;
    height: 105px;
    margin-left: auto;
    box-shadow: 0 4px 6px -6px black;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .secretarial_staff_content_dropdown_wrapper {
    width: 130px;
    height: 69px !important;
    margin-left: auto;
    overflow-x: scroll;
    box-shadow: 0 4px 6px -6px black;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .dropdown {
    font-size: 8px !important;
    font-weight: 200;
    text-decoration: none;
    padding: 0.3em 0;
  }
}
@media screen and (max-width: 1500px) {
  .officer_screen .staff_filter_wrapper {
    width: 84%;
    display: flex;
    justify-content: flex-end;
    position: sticky;
    top: 130px;
    padding-top: 1em;
  }
}
.officer_screen .secretarial_staff_container .secretarial_staff_wrapper .secretarial_staff_content_wrapper .secretarial_staff_content .secretarial_staff_staffs_content_wrapper .secretarial_staff_staffs_image_wrapper .secretarial_staff_staffs_image .staffs_image {
  width: 170px;
  height: 250px;
  object-fit: cover;
}
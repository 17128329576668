@import '../../index.scss';

.pagination_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  .pagination ul{
      display: flex;
      padding-left: 0;
    }
    ul li{
        padding: 1em;
        list-style: none;
    }
    ul li a{
        text-decoration: none;
        color: $subscribeButtonColor;
    }
    .active{
        background-color: $primaryColor;
    }
}


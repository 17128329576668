@import '../../index.scss';

.filter_component {
  width: 100%;
  user-select: none;
  display: flex;
  justify-content: center;
  background: $secondaryLightColor;
  ::selection {
    background-color: none;
  }
  .filter_container {
    // width: 40%;
    display: flex;
    text-align: center;
    justify-content: space-between;
    // padding: 0 0 1.5em 0;
    border-bottom: 2px solid $secondaryDarkColor;
    .filter_wrapper {
      // border-bottom: 2.5px solid $secondaryDarkColor;
      padding: 1em 2em;
      display: flex;
      position: relative;
      flex-direction: column;
      text-align: center;
      align-items: center;
      font-weight: 400;
      cursor: pointer;
      .filter_img {
        width: 40px;
      }
    }

    .selected_filter_wrapper {
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      cursor: pointer;
      padding: 1em 2em;
      border-bottom: 2.5px solid $primaryColor;
      background-color: $secondaryDarkColor;
      .filter_img {
        width: 40px;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .filter_component .filter_container {
      width: 100%;
      display: flex;
      text-align: center;
      justify-content: space-between;
      border-bottom: 2px solid #f8eaf0;
    }
    .home_screen .home_container .filter_box {
      display: flex;
      justify-content: center;
      position: sticky;
      top: 40px;
      z-index: 110;
      left: 0;
      padding: 0 0 2em 0;
    }
  }
}
@media only screen and (max-width: 600px) {
  .filter_component .filter_container .selected_filter_wrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    cursor: pointer;
    padding: 0.6em;
    border-bottom: 2.5px solid #b5316a;
    background-color: #f8eaf0;
  }
  .filter_component .filter_container .filter_wrapper {
    padding: 1em 0.5em;
    display: flex;
    position: relative;
    flex-direction: column;
    text-align: center;
    align-items: center;
    font-weight: 400;
    cursor: pointer;
}
.filter_component .filter_container .filter_wrapper .filter_img {
  width: 25px;
}
.filter_component .filter_container .selected_filter_wrapper .filter_img {
  width: 25px;
}
.filter_text{
  font-size: 10px;
}
.filter_component .filter_container {
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: space-between;
  border-bottom: 2px solid #F8EAF0;
}
}

@import "../../index.scss";

.department_view_screen {
  background-color: $background;
  width: 100%;
  .department_view_container {
    width: 100%;
    .department_view_wrapper {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      .department_view_header_wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding-top: 2em;
        .department_view_navigate_icon_wrapper {
          .department_view_navigate_icon {
            .navigate_icon {
              cursor: pointer;
              transform: rotate(90deg);
            }
          }
        }
        .department_view_header_name_container {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          .department_view_header_name {
            padding-left: 1em;
            font-weight: 700;
          }
          .department_view_header_button_wrapper{
            padding-right: 2.5em;
          }
        }
      }
      .department_view_navbar_wrapper {
        width: 100%;
      }
      .department_view_table_wrapper{
        width: 100%;
      }
    }
  }
}

.department_view_customModal {
  background: $background;
  width: 50%;
  height: 400px;
  min-height: 475px;
  border-radius: 16px;
  overflow-x: auto;
  box-shadow: 0 10 28px 0px $primaryColor;
  padding: 0;

  .close_button{
    outline: none;
  }

  .department_view_modal_title_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1em;

    .department_view_modal_title {
      font-weight: bold;
      margin-left: 0.75em;
      color: $primaryTextColor;
    }
  }
  .department_view_modal_line_wrapper {
    width: 100%;
    height: 1px;
    background-color: $primaryColor;
  }
  .department_view_modal_text_area_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 2em;
    height: 300px;
  }

  .department_view_modal_input_wrapper::-webkit-scrollbar {
    display: none;
  }
  .department_view_button_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5em;
    cursor: pointer;

    .department_view_button{
      padding-top: 4em;
      width: 80px;
    }
  }
}

.department_view_customModal::-webkit-scrollbar {
  display: none;
}

@import '../../index.scss';

.sermon_outlines_screen {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $backgroundColor;
  overflow: auto;
  .sermon_outlines_select_box_wrapper {
    display: flex;
    justify-content: flex-end;
    .sermon_outlines_select_box {
      padding-bottom: 2em;
      width: 300px;
      .select_box {
        width: 250px;
        .css-z7fgrc-control {
          background-color: $backgroundColor !important;
        }
        .css-1smg336-control {
          background-color: $backgroundColor !important;
        }
      }
    }
  }
  .header_wrapper {
    width: 100%;
  }
  .footer_wrapper {
    width: 100%;
  }
  .error_text{
    width: 100%;
    text-align: center;
  }
  .sermon_outlines_screen_container {
    width: 1170px;
    .sermon_outlines_header_wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 2em 0;
      .sermon_outlines_heading {
        font-family: 'Merriweather';
        font-weight: 400;
      }
      .sermon_outlines_header_line {
        height: 3px;
        width: 170px;
        margin-top: 1em;
        background-color: $primaryColor;
      }
    }
    .sermon_outlines_screen_title_wrapper {
      margin-top: 3em;
      margin-bottom: 3em;
      .sermon_outlines_screen_title {
        display: flex;
        justify-content: center;
        .title_links_wrapper {
          display: flex;
          padding: 1rem;
          justify-content: center;
          position: relative;
          &:after {
            content: '';
            width: 90%;
            position: absolute;
            bottom: 0;
            left: 15px;
            height: 2px;
            border-bottom: 3px solid $secondaryLightColor;
          }
          .link_1 {
            padding-right: 2em;
            position: relative;
            font-weight: 400;
            .title_link {
              text-decoration: none;
              color: $subscribeButtonColor;
            }
          }
          .link {
            position: relative;
            font-weight: 400;
            padding-right: 2em;
            .title_link {
              text-decoration: none;
              color: $subscribeButtonColor;
            }
          }
        }
      }
    }
    .sermon_outlines_body_wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      .sermon_outlines_card_wrapper {
        width: 33%;
        padding: 2em 2em 0 2em;
      }
    }
  }
}
@media only screen and (max-width: 1024px) {
  .sermon_outlines_screen .sermon_outlines_screen_container {
    width: 100%;
  }
  .sermon_outlines_screen
    .sermon_outlines_screen_container
    .sermon_outlines_body_wrapper
    .card_wrapper {
    width: 33%;
  }
}
@media only screen and (max-width: 600px) {
  .sermon_outlines_screen {
    .h3 {
      font-size: 11px;
      font-weight: 700;
    }
    .sermon_outlines_select_box_wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 2em;
      .sermon_outlines_select_box {
        width: 50%;
        .select_box {
          width: 100%;
        }
      }
    }
    .header_wrapper {
      width: 100%;
    }
    .footer_wrapper {
      width: 100%;
    }
    .sermon_outlines_screen_container {
      width: 87vw;
      .sermon_outlines_header_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 2em 0;
        .sermon_outlines_heading {
          font-family: 'Merriweather';
          font-weight: 400;
        }
        .sermon_outlines_header_line {
          height: 3px;
          width: 170px;
          margin-top: 1em;
          background-color: $primaryColor;
        }
      }
      .sermon_outlines_body_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        .sermon_outlines_card_wrapper {
          width: 25%;
          padding-bottom: 2em;
        }
      }
    }
  }
  .sermon_outlines_screen
    .sermon_outlines_screen_container
    .sermon_outlines_screen_title_wrapper
    .sermon_outlines_screen_title
    .title_links_wrapper {
    display: flex;
    padding: 0rem;
    justify-content: center;
    position: relative;
  }
  .sermon_outlines_screen
    .sermon_outlines_screen_container
    .sermon_outlines_screen_title_wrapper
    .sermon_outlines_screen_title
    .title_links_wrapper:after {
    content: '';
    width: 90%;
    position: absolute;
    bottom: -17px;
    left: 0px;
    height: 2px;
    border-bottom: 3px solid #fff3fa;
  }
}

@import "../../index.scss";
.books_screen{
  .books_container{
    .books_wrapper{
      .books_header_wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2em 2em 0em 2em;

        .books_search_wrapper{
          width: 250px;
          padding-right: 1em;
        }

        .books_filter_select_box_wrapper{
          width: 150px;
          padding-right: 1em;
        }

        .books_action_button_wrapper{
          width: 150px;
          padding-right: 1em;
        }
      }
    }
  }
}
.books_action_wrapper{
  display: flex;
}
.books_customModal {
  background: $background;
  width: 25%;
  max-height: 90vh;
  border-radius: 16px;
  box-shadow: 0 10 28px 0px $primaryColor;
  padding: 0;
  position: relative;

  .close_button {
    outline: none;
  }
  .books_modal_header_wrapper{
    background: $background;
    position: sticky;
    top: 0;
    z-index: 1;
    .books_modal_title_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1em;
      position: sticky;
      top: 0;
      background: $background;
  
      .books_modal_title {
        font-weight: bold;
        margin-left: 0.75em;
        color: $primaryTextColor;
      }
      .books_modal_icon_wrapper {
        cursor: pointer;
        .books_modal_icon {
          width: 30px;
          height: 30px;
        }
      }
    }
    .books_modal_line_wrapper {
      width: 100%;
      height: 0.5px;
      background-color: $primaryColor;
    }
  }
  .books_modal_input_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    .books_modal_input {
      padding-top: 1em;
      width: 80%;
      .upload_box_wrapper {
        width: 100%;
        padding-top: 1em;
        .upload_box {
          padding-top: 1em;
        }
      }
    }

    .books_file_upload_wrapper {
      display: flex;
      margin-top: 1.5em;
      justify-content: space-between;
      width: 90%;
      height: 100%;
      border-radius: 2%;
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      background-color: $secondaryLightColor;

      .file_upload_file_name {
        display: flex;
        padding-left: 0.5em;
        width: 85%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .file_upload_inactive_icon-wrapper {
        display: flex;
        justify-items: center;
        align-items: center;

        .file_upload_delete_icon {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .books_modal_input_wrapper::-webkit-scrollbar {
    display: none;
  }

  .books_modal_button_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 1em;
    padding-bottom: 1em;

    .books_modal_button {
      padding-top: 2em;
      width: 122px;
    }
  }
}

.books_customModal::-webkit-scrollbar{
  display: none;
}
@import '../../index.scss';

.contact_screen {
  height: 100vh;
  overflow: auto;
  background-color: $backgroundColor;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  align-items: center;
  .header_wrapper {
    width: 100%;
  }
  .contact_screen_container {
    width: 80vw;
    .contact_screen_header_wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 2em;
      .contact_screen_header {
        font-family: 'Merriweather';
        font-weight: 400;
      }
      .contact_header_line {
        height: 3px;
        width: 15%;
        background-color: $primaryColor;
        margin: 1rem 0 2em 0;
      }
    }
  }
  .contact_body_wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 5em;
    padding: 2em 0;
    .contact_wrapper_left {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      width: 25%;
      // text-align: center;
      height: 450px;
      .contact_details_wrapper {
        display: flex;
        flex-direction: column;
        // padding: 1em;
        .contact_title {
          padding: 1em 0;
          font-size: 18px;
        }
      }
    }
    .wrapper_one{
      width: 25%;
      align-items: flex-start;
      justify-content: flex-start;
    }
    .contact_wrapper_right {
      height: 100%;
      width: 33%;
    }
    .map_wrapper {
      img {
        width: 100%;
        height: 450px;
        object-fit: cover;
      }
    }
  }
  .contact_wrapper_left .contact_screen_header_wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .contact_wrapper_left.contact_screen_header{
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
  .contact_screen_container_body {
    width: 40vw;
    display: flex;
    flex-direction: column;
    padding: 6em;
    .contact_screen_header_wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .contact_screen_header {
        font-family: 'Merriweather';
        font-weight: 400;
      }
      .contact_header_line {
        height: 3px;
        width: 20% !important ;
        background-color: $primaryColor;
        margin: 1em 0 2em 0;
      }
    }
    .contact_questions_wrapper {
      padding-bottom: 2em;
      display: flex;
      flex-direction: column;
      .contact_button_wrapper {
        width: 150px;
        padding-top: 2em;
      }
      .button {
        background-color: $primaryColor;
      }
    }
  }
  .footer_wrapper {
    width: 100%;
  }
}
.contact_wrapper_left {
  .contact_questions_wrapper {
    padding-bottom: 2em;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 75%;
    .contact_button_wrapper {
      width: 150px;
      padding-top: 2em;
    }
    .button {
      background-color: $primaryColor;
    }
  }
}
.contact_wrapper_left .contact_screen_header_wrapper .contact_header_line {
  height: 3px;
  width: 100px;
  background-color: #b5316a;
  margin: 1em 0 2em 0;
}
.contact_wrapper_left .contact_input_wrapper {
  width: 280px;
 overflow: hidden;
}
.contact_wrapper_left .input_box_container .input_box_wrapper input,
.input_box_container .input_box_wrapper textarea {
  padding: 0 0 0.5rem 0;
  border: 0;
  width: 100%;
  background: none;
}
.contact_wrapper_left .text_area_container .text_area_wrapper textarea {
  width: 100%;
  // height: 80px;
  resize: none;
  background: none;
  overflow-x: hidden;
}
@media screen and (max-width: 1024px) {
  .contact_screen
    .contact_screen_container
    .contact_screen_header_wrapper
    .contact_header_line {
    width: 15%;
  }
  .contact_screen .contact_body_wrapper .contact_wrapper_left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 33%;
    height: 450px;
}

  .contact_screen
    .contact_screen_container_body
    .contact_screen_header_wrapper
    .contact_header_line {
    width: 50% !important;
  }
  .contact_screen .contact_screen_container_body {
    width: 40vw;
    display: flex;
    flex-direction: column;
    padding: 1em;
  }
}
@media screen and (max-width:768px) {
  .contact_screen .contact_body_wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 5em;
    padding: 2em 0;
}
.contact_screen .contact_body_wrapper .contact_wrapper_left {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 75%; 
  padding-bottom: 3em;
}
.contact_screen .contact_body_wrapper .contact_wrapper_right {
  height: 100%;
  width: 75%;
}
}
@media screen and (max-width: 600px) {
  .contact_wrapper_left .contact_questions_wrapper {
    padding-bottom: 2em;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
}
.contact_screen .contact_body_wrapper .contact_wrapper_left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 25%;
  height: auto;
}
.contact_screen .contact_body_wrapper .contact_wrapper_left .contact_details_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0em;
}
.contact_screen .contact_body_wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin-bottom: 0;
}
.contact_screen .contact_body_wrapper .contact_wrapper_left .contact_details_wrapper .contact_title {
  padding: 1em;
  text-align: center;
  font-size: 18px;
}
.contact_screen .contact_body_wrapper .contact_wrapper_right {
  height: 100%;
  width: 100%;
  padding: 2em;
}
  .contact_screen .contact_body_wrapper .contact_wrapper_left {
    width: 100%;
    justify-content: center;
    align-items: center;
}
  .contact_screen .contact_body_wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 0;
  }
  .contact_screen .contact_body_wrapper .contact_wrapper_left {
    width: 100%;
  }
  .contact_screen .contact_screen_container_body {
    width: 100vw;
    padding: 2em;
    display: flex;
    flex-direction: column;
  }
  .contact_screen
    .contact_screen_container
    .contact_screen_header_wrapper
    .contact_header_line {
    width: 40% !important;
  }
}

@import "../../index.scss";

.diocese_ministerial_roll_screen {
  background-color: $background;
  width: 100%;
  .diocese_ministerial_roll_container {
    width: 100%;
    .diocese_ministerial_roll_wrapper {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      .diocese_ministerial_roll_header_wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding-top: 2em;
        .diocese_ministerial_roll_navigate_icon_wrapper {
          .diocese_ministerial_roll_navigate_icon {
            .navigate_icon {
              transform: rotate(90deg);
            }
          }
        }
        .diocese_ministerial_roll_header_name_container {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          .diocese_ministerial_roll_header_name {
            padding-left: 1em;
            font-weight: 700;
          }
          .diocese_ministerial_roll_header_button_wrapper{
            padding-right: 2.5em;
          }
        }
      }
      .diocese_ministerial_roll_navbar_wrapper {
        width: 100%;
      }
      .diocese_ministerial_roll_table_wrapper{
        width: 100%;
      }
    }
  }
}

.diocese_ministerial_roll_modal {
  background: $background;
  width: 22%;
  height: 45%;
  border-radius: 16px;
  box-shadow: 0 10 28px 0px $primaryColor;
  padding: 0;

  .diocese_ministerial_roll_modal::-webkit-scrollbar {
    display: none;
  }

  .diocese_ministerial_roll_modal_title_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1em;
    padding-right: 15px;

    .diocese_ministerial_roll_modal_title {
      font-weight: bold;
      margin-left: 0.75em;
      color: $primaryTextColor;
    }
  }
  .diocese_ministerial_roll_modal_line_wrapper {
    width: 100%;
    height: 1px;
    background-color: $primaryColor;
  }
  .diocese_ministerial_roll_modal_input_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5em;
    .diocese_ministerial_roll_modal_input {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding-left: 2em;
      .diocese_ministerial_roll_thumbnail_wrapper {
        padding-top: 1.5em;
      }
      .department-events_modal_uploadbox{
        padding-top: 1em;
        width: 100%;
      }
    }
  }
  .diocese_ministerial_roll_button_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
  }
}
.uploaded_diocese_ministerial_roll{
  display: flex;
  justify-content: center;
  align-items: center;
}
.diocese_ministerial_roll{
  width: 300px;
height: 300px;
margin-top: 1em;
}
.upload_box{
margin-top: 1em;
}
.lectionary_file_upload_wrapper {
  display: flex;
  margin-top: 1.5em;
  justify-content: space-between;
  width: 50%;
  height: 100%;
  border-radius: 2%;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  background-color: $secondaryLightColor;

  .file_upload_file_name {
    display: flex;
    padding-left: 0.5em;
    width: 55%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .file_upload_inactive_icon-wrapper {
    display: flex;
    justify-items: center;
    align-items: center;

    .file_upload_delete_icon {
      width: 20px;
      height: 20px;
    }
  }
}

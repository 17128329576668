@import '../../index.scss';

.lectionary_screen {
  .lectionary_container {
    width: 100%;
    .lectionary_wrapper {
      .lectionary_header_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 2em 2em 0em 2em;

        .lectionary_search_box_wrapper {
          width: 250px;
          padding-right: 1em;
        }

        .lectionary_button_wrapper {
          padding-right: 1em;
        }
      }
    }
  }
}
.lectionary_action_wrapper{
  display: flex;
}
.lectionary_customModal {
  background: $background;
  width: 22%;
  min-height: 400px;
  border-radius: 16px;
  box-shadow: 0 10 28px 0px $primaryColor;
  padding: 0;

  .close_button {
    outline: none;
  }

  .lectionary_customModal::-webkit-scrollbar {
    display: none;
  }

  .lectionary_modal_title_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1em;

    .lectionary_modal_title {
      font-weight: bold;
      margin-left: 0.75em;
      color: $primaryTextColor;
    }
  }
  .lectionary_modal_line_wrapper {
    width: 100%;
    height: 0.5px;
    background-color: $primaryColor;
  }
  .lectionary_modal_input_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .lectionary_modal_input {
      padding-top: 1em;
      width: 80%;
      .upload_box_wrapper {
        width: 100%;
        padding-top: 1em;
        .upload_box {
          padding-top: 1em;
        }
      }
    }
  }
  .lectionary_button_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;

    .lectionary_button {
      padding-top: 1em;
      width: 50%;
    }
  }
}
.lectionary_file_upload_wrapper {
  display: flex;
  margin-top: 1.5em;
  justify-content: space-between;
  width: 90%;
  height: 100%;
  border-radius: 2%;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  background-color: $secondaryLightColor;

  .file_upload_file_name {
    display: flex;
    padding-left: 0.5em;
    width: 85%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .file_upload_inactive_icon-wrapper {
    display: flex;
    justify-items: center;
    align-items: center;

    .file_upload_delete_icon {
      width: 20px;
      height: 20px;
    }
  }
}
